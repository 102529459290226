import React, { createContext, Component } from 'react'
import {
  removeToken,
  saveToken,
  isLogged,
  getToken,
  removeUser,
  getUser,
  getSketchFabToken,
  saveSketchfabToken,
  isScketchfabLogged,
} from '../../api/lib/auth'
import {
  successNotification,
  networkError,
  dangerNotification,
  warningNotification,
} from '../../api/lib/notifications'
import {
  userProfile,
  getUserNonce,
  signUpPublicAddress,
  signIn,
  getUserBalanceAndAllowance,
} from '../../api/lib/api'
import config, { camelCaseKeys } from '../../api/lib/config'
import { useTranslation } from 'react-i18next'

export const UserContext = createContext()

export class UserProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasLoaded: false,
      isLoggedIn: false,
      subscribedToLiveSockets: false,
      showNotificationCenter: false,
      token: null,
      user: {
        uuid: null,
        allowance: 0,
        balance: 0,
        email: null,
        firstName: null,
        lastName: null,
        country: null,
        balanceProjection: 0,
        pendingOnBalance: 0,
        kycReviewAnswer: -1,
        isConfirmedEmail: false,
        isProfileCompleted: false,
        ibcoAcceptedTerms: false,
        betaTester: 0,
      },
      sketchfab: {
        token: null,
        expires_in: null,
        inLoggedIn: false,
      },
    }
  }

  componentDidMount() {
    if (isLogged()) {
      // Check
      userProfile()
        .then((response) => {
          if (response.data.result === true) {
            this.setState({ hasLoaded: true, user: response.data.user })
          } else {
            dangerNotification(
              this.props.t('Danger.session.expired.title'),
              this.props.t('Danger.session.expired.desc')
            )
            this.logoutUser()
          }
        })
        .catch(() => {
          // Notify user if network error
          console.log('isLogged')
          networkError()
        })

      this.loginUser(getToken('userToken'), getToken('userUuid'))
    }

    // SAVING SKETCHFAB TOKEN
    if (isLogged()) {
      const path = window.location.hash

      const urlParameters = path
        .split('&')
        .map((v) => v.split('='))
        .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {})

      const {
        ['#access_token']: token = null,
        expires_in = null,
      } = urlParameters

      console.debug('SKETCHFAB TOKEN', {
        path,
        urlParameters,
      })

      if (token !== null && expires_in !== null) {
        this.setSketchfabTokenToContext(token, expires_in)
        saveSketchfabToken('sketchfabToken', token)
        const isLogged = isScketchfabLogged()
        console.debug('isScketchfabLogged', isLogged)
      }
    }
  }

  // write user uuid on local storage every time it changes
  componentDidUpdate() {
    if (this.state.user?.uuid !== null) {
      localStorage.setItem('__userUuid', this.state.user.uuid)
    } else {
      localStorage.removeItem('__userUuid')
    }
  }

  setSketchfabTokenToContext = (token, expires_in) => {
    this.setState({
      sketchfab: {
        token,
        expires_in,
        isLoggedIn: true,
      },
    })
  }

  setUserState = (user) => {
    this.setState({ user: user })
  }

  setUserBalance = (balance) => {
    this.setState({
      user: {
        ...this.state.user,
        balance: balance,
      },
    })
  }

  setUserEmail = (email) => {
    this.setState({
      user: {
        ...this.state.user,
        email: email,
      },
    })
  }

  setUserFirstName = (firstName) => {
    this.setState({
      user: {
        ...this.state.user,
        firstName: firstName,
      },
    })
  }

  setUserLastName = (lastName) => {
    this.setState({
      user: {
        ...this.state.user,
        lastName: lastName,
      },
    })
  }

  setUserCountry = (country) => {
    // this.setState({ user: {
    // 	...this.state.user,
    // 	country: country
    // } });
    userProfile().then((response) => {
      if (response.data.result === true) {
        this.setState({ user: response.data.user })
      }
    })
  }

  setIsConfirmedEmail = (boole) => {
    // console.log('setIsConfirmedEmail in UserContext: ', boole)
    // this.setState({ user: {
    // 	...this.state.user,
    // 	isConfirmedEmail: boole
    // } });
    // console.log('setIsConfirmedEmail in UserContext - State: ', this.state.user.isConfirmedEmail)
    // console.log('USER in UserContext - State: ', this.state.user)
    // Check
    userProfile().then((response) => {
      if (response.data.result === true) {
        this.setState({ user: response.data.user })
      }
    })
  }

  setIsProfileCompleted = (isProfileCompleted) => {
    this.setState({
      user: {
        ...this.state.user,
        isProfileCompleted: isProfileCompleted,
      },
    })
  }

  setUserActivity = (activities) => {
    this.setState({
      user: {
        ...this.state.user,
        activities: camelCaseKeys(activities),
      },
    })
  }

  loginUser = (token, user, method) => {
    console.log('loginUser', user)
    // Cookie management
    saveToken('userToken', token)
    saveToken('userUuid', user)
    saveToken('loginMethod', method)
    this.setState({ isLoggedIn: true, token: token, user: user })
    this.props.onAuthenticationChanged(true)
  }

  logoutUser = () => {
    this.setState({ isLoggedIn: false, token: null, user: { uuid: null } })
    // Cookie management
    removeToken('userToken')
    removeToken('userUuid')
    removeToken('loginMethod')
  }

  refreshBalanceAndAllowance = () => {
    console.log('refreshBalanceAndAllowance')
    getUserBalanceAndAllowance()
      .then((response) => {
        if (response.data.result === true) {
          console.log('refreshBalanceAndAllowance', response.data)
          this.setState({
            user: {
              ...this.state.user,
              balance: response.data.balance,
              allowance: response.data.allowance,
              balanceProjection: response.data.balanceProjection,
              pendingOnBalance: response.data.pendingOnBalance,
            },
          })
        }
      })
      .catch((err) => {
        // Notify user if network error
        console.log('NetworkErrorCode: 1')
        networkError()
      })
  }

  // Centralized Notifications

  toggleShowNotificationCenter = () => {
    this.setState({
      showNotificationCenter: !this.state.showNotificationCenter,
    })
  }

  acceptIbcoTermsAndConditions = () => {
    this.setState({
      user: {
        ...this.state.user,
        ibcoAcceptedTerms: true,
      },
    })
  }

  closeNotificationCenter = () => {
    this.setState({ showNotificationCenter: false })
  }

  setNotificationAsReaded = (notification_uuid) => {
    let notifications_content = this.state.user.notifications.content
    let unreaded_count = this.state.user.notifications.unreadedCount

    notifications_content.readNotification(notification_uuid)
    unreaded_count = unreaded_count - 1

    this.setState({
      user: {
        ...this.state.user,
        notifications: {
          ...this.state.user.notifications,
          unreadedCount: unreaded_count,
          content: notifications_content,
        },
      },
    })
  }

  setAllNotificationsAsReaded = () => {
    let notifications_content = this.state.user.notifications.content
    notifications_content.readAllNotifications()
    this.setState({
      user: {
        ...this.state.user,
        notifications: {
          ...this.state.user.notifications,
          unreadedCount: 0,
          content: notifications_content,
        },
      },
    })
  }

  render() {
    return (
      <UserContext.Provider
        value={{
          state: this.state,
          actions: {
            loginUser: this.loginUser,
            logoutUser: this.logoutUser,
            setUserState: this.setUserState,
            setUserActivity: this.setUserActivity,
            setUserEmail: this.setUserEmail,
            setUserFirstName: this.setUserFirstName,
            setUserLastName: this.setUserLastName,
            setUserCountry: this.setUserCountry,
            setIsConfirmedEmail: this.setIsConfirmedEmail,
            setIsProfileCompleted: this.setIsProfileCompleted,
            toggleShowNotificationCenter: this.toggleShowNotificationCenter,
            closeNotificationCenter: this.closeNotificationCenter,
            refreshBalanceAndAllowance: this.refreshBalanceAndAllowance,
            acceptIbcoTermsAndConditions: this.acceptIbcoTermsAndConditions,
            setUserBalance: this.setUserBalance,
            notification: {
              setAsReaded: this.setNotificationAsReaded,
              setAllAsReaded: this.setAllNotificationsAsReaded,
            },
          },
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export function withUserContext(Component) {
  class ComponentWithContext extends React.Component {
    render() {
      return (
        <UserContext.Consumer>
          {(value) => <Component {...this.props} userProvider={{ ...value }} />}
        </UserContext.Consumer>
      )
    }
  }

  return ComponentWithContext
}
