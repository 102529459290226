import React, { useState, useEffect } from "react";

// API
import { getUserPublishedLands } from "../../api/lib/api";

// Material UI
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { unpublishLand } from "../../api/lib/api";
import Pagination from "@material-ui/lab/Pagination";

import LandCard from "../ovr/LandCard/LandCard";

import { warningNotification } from "../../api/lib/notifications";

const LiveLandsDialog = ({ show, onClose, loadProjects }) => {
  // const [open, setOpen] = useState(show)

  const [lands, setLands] = useState([]);

  const [selectedLandIds, setSelectedLandIds] = useState([]);
  const [selectedLandUuid, setSelectedLandUuid] = useState([]);
  const [numberOfLandPages, setNumberOfLandPages] = useState(0);
  const [currentLandPage, setCurrentLandPage] = useState(1);

  useEffect(() => {}, []);

  React.useEffect(() => {
    if (show) loadLands(currentLandPage, 6);
  }, [show]);

  function handleLandPageClick(event, number) {
    setCurrentLandPage(number);
    // if there is no search value load page
    loadLands(number, 6);
  }

  const handleSubmit = () => {
    unpublishLand(selectedLandUuid)
      .then(res => {
        onClose();
        setLands([]);
        loadProjects();
      })
      .catch(error => warningNotification("Warning", error.toString()));
  };

  function loadLands(page, perPage) {
    getUserPublishedLands(page, perPage).then(res => {
      const num_of_pages = res?.data?.numberOfPages;
      const lands = res?.data?.lands;

      if (lands.length > 0) {
        // Load user data in context store
        setLands(lands);
        setNumberOfLandPages(num_of_pages);
      } else {
        setLands(
          <div className="c-dialog --centered">
            <div className="c-dialog-main-title">Currently you don't have any live land</div>
          </div>
        );
      }
    });
  }

  const handleChangeSelectedLand = (hexId, uuid) => {
    if (!selectedLandIds.includes(hexId)) {
      setSelectedLandIds(s => {
        return [...s, hexId];
      });

      setSelectedLandUuid(s => {
        return [...s, uuid];
      });
    } else {
      setSelectedLandIds(s => selectedLandIds.filter(single => hexId != single));
      setSelectedLandUuid(s => selectedLandUuid.filter(single => uuid != single));
    }
  };
  return (
    <Dialog fullWidth={true} maxWidth={"md"} open={show} onClose={onClose} aria-labelledby="max-width-dialog-title">
      <DialogTitle id="max-width-dialog-title">Select Lands to Unpublish</DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <div className="d-select-land">
          <div className="o-land-list">
            {lands instanceof Array
              ? lands.map(obj => (
                  <div
                    key={obj.uuid}
                    onClick={() => handleChangeSelectedLand(obj.hexId, obj.uuid)}
                    className="o-land-list-item"
                  >
                    <LandCard
                      key={obj.hexId}
                      className={selectedLandIds.includes(obj?.hexId) ? "--selected" : ""}
                      background_image={`url(${obj.mapTileUrl}`}
                      name={{ sentence: obj.sentenceId, hex: obj.hexId }}
                      landUuid={obj.uuid}
                      hexId={obj.hexId}
                      intId={obj.intId}
                      location={obj.address.full}
                    />
                  </div>
                ))
              : lands}
          </div>
          <div className="o-pagination">
            {numberOfLandPages > 1 && (
              <Pagination count={numberOfLandPages} page={currentLandPage} onChange={handleLandPageClick} />
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSubmit} color="secondary">
          Unpublish
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LiveLandsDialog;
