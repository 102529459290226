// Possible values are STAGING, DEVELOPMENT, PRODUCTION
// If you have connection error set as STAGING
const environment = 'PRODUCTION'

let apis
if (environment === 'PRODUCTION') {
  apis = {
    hostname: 'https://pws-staging.ovr.ai/assets',
    railsHostname: 'https://mws.ovr.ai/api/v1',
    socket: 'ws://localhost:3000/cable',
    cookieDomain: 'ovr.ai',
    OVRContract: '0x21BfBDa47A0B4B5b1248c767Ee49F7caA9B23697',
    walletApproved: '0x8ABa7FBaE92012c8A86C5182538A5dE11039Dad1',

    // Tokens
    OVREthereumToken: '0x21BfBDa47A0B4B5b1248c767Ee49F7caA9B23697',
    OVRBSCToken: '0x7E35D0e9180bF3A1fc47b0d110bE7a21A10B41Fe',
    OVRPolygonToken: '0x1631244689ec1fecbdd22fb5916e920dfc9b8d30',
  }
} else if (environment === 'STAGING') {
  apis = {
    hostname: 'https://pws-staging.ovr.ai/assets',
    railsHostname: 'https://mws-staging.ovr.ai/api/v1',
    socket: 'ws://localhost:3000/cable',
    cookieDomain: 'ovr.ai',
    OVRContract: '0x4CbE615C151aeb453aab0516Ad7207C641Fe70dC',
    walletApproved: '0xda0b2EC0E8eE995e3257Bf99EA678e26651DbeEc',
  }
} else {
  apis = {
    hostname: 'https://pws-staging.ovr.ai/assets',
    railsHostname: 'http://localhost:3000/api/v1',
    socket: 'ws://localhost:3000/cable',
    cookieDomain: 'localhost',
  }
}

const map = {
  lat: 46.0922495,
  lng: 13.2312417,
  zoom: 2,
  fillOpacity: 0.3,
  colorScale: ['#5F39BE', '#ffffff', '#1a0731', '#EC663C', '#0081DD'],
}
let config = {
  web3network: environment === 'PRODUCTION' ? [1, 56, 137] : [4, 56, 80001],
  environment: environment,
  apis: apis,
  map: map,
}

export default config

export function camelCaseKeys(object) {
  let camelCaseObject = _.cloneDeep(object)

  if (_.isArray(camelCaseObject)) {
    return _.map(camelCaseObject, camelCaseKeys)
  }
  if (_.isString(camelCaseObject)) {
    return camelCaseObject
  }
  camelCaseObject = _.mapKeys(camelCaseObject, (value, key) => _.camelCase(key))

  // Recursively apply throughout object
  return _.mapValues(camelCaseObject, (value) => {
    if (_.isPlainObject(value)) {
      return camelCaseKeys(value)
    } else if (_.isArray(value)) {
      return _.map(value, camelCaseKeys)
    }
    return value
  })
}
