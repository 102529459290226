import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import styled, { ThemeContext } from "styled-components";
import Dialog from "./Dialog";
import { bytesToSize } from "../utils";
import { ReactSelectize, SimpleSelect, MultiSelect } from "react-selectize";
import list_of_words_v1 from "../../list_of_words_v1.json";
import _ from "lodash";
import Map from "../assets/Map";
import Cookies from "js-cookie";
import { checkHexagon } from "../../api/lib/api";

import StringInput from "../inputs/StringInput";
import FormField from "../inputs/FormField";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const LeftContent = styled.div`
  display: flex;
  width: 460px;
  border-top-left-radius: inherit;
  align-items: center;
  padding: 20px;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 20px;
`;

const final_list = list_of_words_v1;

export default function ThreeWordsDialog({ ...props }) {
  const [selectedHexId, setSelectedHexId] = useState(null);
  const [onSingleView, setOnSingleView] = useState(false);

  const [userInHex, setUserInHex] = useState(null);
  const user = Cookies.get("AuthUID");

  const [hexOccupied, setHexOccupied] = useState(false);

  const [reducedOptions] = useState(
    list_of_words_v1.slice(0, 10).map((word) => ({
      label: word,
      value: word,
    }))
  );
  const [selectedValues, setSelectedValues] = useState([]);

  async function changeHexId(hex_id) {
    setOnSingleView(false);
    setSelectedHexId(hex_id);
    setOnSingleView(true);
    const result = await checkHexagon(selectedHexId);
    setUserInHex(result.data.user);
    setHexOccupied(result.data.result && result.data.user != user);
  }
  async function changeSelectedValues(hex_id) {
    setOnSingleView(false);
    setSelectedValues(
      form_h3_to_words(hex_id).map((word) => ({
        label: word,
        value: word,
      }))
    );
    setOnSingleView(true);
    setSelectedHexId(hex_id);
    const result = await checkHexagon(hex_id);
    setUserInHex(result.data.user);
    setHexOccupied(result.data.result && result.data.user != user);
  }

  function onConfirm() {
    const publishState = { selectedHexId, selectedValues, hexOccupied };
    if (selectedHexId) {
      props.onConfirm(publishState);
    } else {
      alert(
        "You have to bind your project to a land. Select an hexagon on the map or use the search tool to track one of your choice."
      );
    }
  }

  return (
    <Dialog
      tag={props.tag}
      title={props.title}
      cancelLabel={props.cancelLabel}
      confirmLabel={props.confirmLabel}
      onConfirm={onConfirm}
      onCancel={props.onCancel}
    >
      <RightContent>
        <FormContainer>
          <h2 style={{ paddingBottom: "10px" }}>{props.message}</h2>
          <div id="geolocation_container"></div>
          <h3 style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            or use OVR Three Words Search tool
          </h3>
          <MultiSelect
            theme="bootstrap3"
            maxOptions={3}
            placeholder="Select three words"
            options={reducedOptions}
            values={selectedValues}
            filterOptions={function(options, values, search) {
              if (search == "") {
                return options;
              } else {
                let filtered = list_of_words_v1.filter(function(str) {
                  return str.indexOf(search) >= 0;
                });
                if (filtered.length > 15) {
                  filtered = filtered.slice(0, 15);
                }
                let filtered_op = filtered.map((word) => ({
                  label: word,
                  value: word,
                }));
                // setReducedOptions(filtered_op);
                return filtered_op;
              }
            }}
            onValuesChange={(value) => {
              setSelectedValues(value);
              if (value.length == 3) {
                let arrayNames = value.map((a) => a.value);
                const hex_id = from_triplet_to_h3(arrayNames);
                changeHexId(hex_id);
              }
            }}
            maxValues={3}
          />
          {user == userInHex && (
            <h3 style={{ paddingTop: "10px", color: "yellow" }}>
              WARNING: you already have a project published here. If you proceed
              this one will be lost.
            </h3>
          )}
          {hexOccupied && (
            <h3 style={{ paddingTop: "10px", color: "red" }}>
              WARNING: this hexagon is already bound to a project pubblished by
              another User. Change Hexagon please...
            </h3>
          )}
        </FormContainer>
      </RightContent>
      <LeftContent>
        <Map
          value={{
            state: {
              hexId: selectedHexId,
              onSingleView: onSingleView,
            },
            actions: {
              changeHexId: changeHexId,
              changeSelectedValues: changeSelectedValues,
            },
          }}
        ></Map>
      </LeftContent>
    </Dialog>
  );
}

// Helpers calcoli
function add(x, y, base) {
  const z = [];
  const n = Math.max(x.length, y.length);
  191;
  let carry = 0;
  let i = 0;
  while (i < n || carry) {
    const xi = i < x.length ? x[i] : 0;
    const yi = i < y.length ? y[i] : 0;
    const zi = carry + xi + yi;
    z.push(zi % base);
    carry = Math.floor(zi / base);
    i++;
  }
  return z;
}

function multiplyByNumber(num, x, base) {
  if (num < 0) return null;
  if (num == 0) return [];
  let result = [];
  let power = x;
  while (true) {
    if (num & 1) {
      result = add(result, power, base);
    }
    num = num >> 1;
    if (num === 0) break;
    power = add(power, power, base);
  }
  return result;
}

function parseToDigitsArray(str, base) {
  const digits = str.split("");
  const ary = [];
  for (let i = digits.length - 1; i >= 0; i--) {
    const n = parseInt(digits[i], base);
    if (isNaN(n)) return null;
    ary.push(n);
  }
  return ary;
}

function convertBase(str, fromBase, toBase) {
  const digits = parseToDigitsArray(str, fromBase);
  if (digits === null) return null;
  let outArray = [];
  let power = [1];
  for (var i = 0; i < digits.length; i++) {
    if (digits[i]) {
      outArray = add(
        outArray,
        multiplyByNumber(digits[i], power, toBase),
        toBase
      );
    }
    power = multiplyByNumber(fromBase, power, toBase);
  }
  let out = "";
  for (var i = outArray.length - 1; i >= 0; i--) {
    out += outArray[i].toString(toBase);
  }
  return out;
}
const combinations_vocab = {
  0: "000",
  1: "001",
  2: "010",
  3: "011",
  4: "100",
  5: "101",
  6: "110",
  7: "111",
  8: "002",
  9: "012",
  10: "020",
  11: "021",
  12: "022",
  13: "102",
  14: "112",
  15: "120",
  16: "121",
  17: "122",
  18: "200",
  19: "201",
  20: "202",
  21: "210",
  22: "211",
  23: "212",
  24: "220",
  25: "221",
  26: "222",
};
// Funzioni calcolo nome
const from_triplet_to_h3 = (triplet) => {
  console.log(triplet);
  const h3_invariant_head = "10001100";
  const h3_invariant_tail = "111111111";
  const triplet_adj = [];
  let str_value = "";
  for (let i = 0; i < triplet.length; i++) {
    for (let j = 0; j < final_list.length; j++) {
      if (final_list[j] === triplet[i]) {
        str_value = j.toString();
      }
    }
    const length_string = str_value.length;
    if (length_string < 5) {
      for (let n = 0; n < 5 - length_string; n++) {
        str_value = "0" + str_value;
      }
    }
    triplet_adj.push(str_value);
  }
  console.log("triplets", triplet_adj);
  const first_trinary_code =
    triplet_adj[0].substring(0, 1) +
    triplet_adj[1].substring(0, 1) +
    triplet_adj[2].substring(0, 1);
  let first_integer_value = 0;
  for (const key in combinations_vocab) {
    if (combinations_vocab[key] === first_trinary_code) {
      first_integer_value = key;
    }
  }
  const full_integer =
    first_integer_value.toString() +
    triplet_adj[0].substring(1) +
    triplet_adj[1].substring(1) +
    triplet_adj[2].substring(1);
  let binary_full_integer = convertBase(full_integer, 10, 2);
  const binary_full_integer_length = binary_full_integer.length;
  for (let i = 0; i < 43 - binary_full_integer_length; i++) {
    binary_full_integer = "0" + binary_full_integer;
  }
  const whole_binary =
    h3_invariant_head + binary_full_integer + h3_invariant_tail;
  const h3_index = convertBase(whole_binary, 2, 16);

  console.log(h3_index);
  return h3_index;
};
const form_h3_to_words = (h3_address) => {
  console.log(h3_address);
  const binary = convertBase(h3_address, 16, 2);
  const binary_clean = binary.substr(8, 43);
  const integer = parseInt(binary_clean, 2);
  let str_integer = integer.toString();
  const integer_length = str_integer.length;
  if (integer_length < 13) {
    for (let i = 0; i < 13 - integer_length; i++) {
      str_integer = "0" + str_integer;
    }
  }
  const integer_first_value = str_integer[0];
  const first_word_idx = parseInt(
    combinations_vocab[parseInt(integer_first_value)][0] +
      str_integer.substring(1, 5)
  );

  const second_word_idx = parseInt(
    combinations_vocab[parseInt(integer_first_value)][1] +
      str_integer.substring(5, 9)
  );
  const third_word_idx = parseInt(
    combinations_vocab[parseInt(integer_first_value)][2] +
      str_integer.substring(9)
  );

  console.log([first_word_idx, second_word_idx, third_word_idx]);

  // return true;
  return [
    list_of_words_v1[first_word_idx],
    list_of_words_v1[second_word_idx],
    list_of_words_v1[third_word_idx],
  ];
};
