/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

const StyledTextField = styled(TextField)`
  margin-bottom: 8px !important;

  .MuiFormHelperText-root {
    color: #eaf329 !important;
  }

  fieldset {
    border: none !important;
  }

  .MuiInputBase-root {
    background-color: rgba(255, 255, 255, 0.09) !important;
    color: white !important;
    transition: all ease 0.3s;
    &:hover {
      background-color: rgba(255, 255, 255, 0.13) !important;
    }
  }
  .ovr-component-field-input {
    position: relative;

    width: 100%;

    .MuiOutlinedInput-input {
      padding: 12px 25px;
      font-weight: 400;
      letter-spacing: 0.5px;
      font-family: "Work Sans", sans-serif;
    }

    .MuiInputBase-root {
      border-radius: 50px;
      background-color: rgba(255, 255, 255, 0.09) !important;
      .MuiInputBase-root {
        color: white !important;

        input {
          letter-spacing: 2px;
        }
        .MuiInputBase-input {
          padding: 25px;
          letter-spacing: 2px !important;
        }

        fieldset {
          transition: all ease 0.3s;
        }

        .MuiOutlinedInput-notchedOutline {
          border: 1px solid #857f7c;
        }
      }

      .Mui-focused {
        border-color: red;
      }
    }
  }
`;

const FieldInput = ({ value = "", className, name, onChange, disabled, placeholder, helperText, type, ...other }) => {
  return (
    <StyledTextField
      disabled={disabled}
      name={name}
      defaultValue={value}
      className={`${className} ovr-component-field-input`}
      variant="outlined"
      type={type}
      size="small"
      placeholder={placeholder}
      onChange={onChange}
      helperText={helperText}
      {...other}
    />
  );
};

export default FieldInput;
