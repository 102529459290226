import GlobalStyle from "./GlobalStyle";
import "./App.scss";

import _ from "lodash";

import React, { useRef, Suspense, lazy, useState } from "react";
import TagManager from "react-gtm-module";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Loading from "./Loading";
import Error from "./Error";

import { ApiContextProvider } from "./contexts/ApiContext";
import { AuthContextProvider } from "./contexts/AuthContext";

import RedirectRoute from "./router/RedirectRoute";
import { Telemetry } from "../telemetry";

// import LandingPage from "./landing/LandingPage";
import WhatsNewPage from "./whats-new/WhatsNewPage";
import LoginPage from "./auth/LoginPage";
import LogoutPage from "./auth/LogoutPage";
import HomePage from "./projects/HomePage";
import ProjectsPage from "./projects/ProjectsPage";

import CreateProjectPage from "./projects/CreateProjectPage";
//import CreateScenePage from "./projects/CreateScenePage";
import ReactNotification from "react-notifications-component";
import APIKeys from "./api-keys";
import LandScan from "./land-scan";

import { ThemeProvider } from "styled-components";
import { ThemeProvider as MaterialUIThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";

import { Column } from "./layout/Flex";

import theme from "./theme";
import CreateThreediumProjectPage from "./threedium/CreateThreediumProjectPage";
import ThreediumRequestChange from "./threedium/ThreediumRequestChange";

import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Translation } from "react-i18next";
import { UserContext, UserProvider } from "./contexts/UserContext";
import { Web3Context, Web3Provider } from "./contexts/Web3Context";

import { createBrowserHistory } from "history";
import { useEffect } from "react";
import AppBar from "./AppBar";

const EditorContainer = lazy(() =>
  import(/* webpackChunkName: "project-page", webpackPrefetch: true */ "./EditorContainer")
);

const PackageKitPage = lazy(() =>
  import(/* webpackChunkName: "package-kit-page", webpackPrefetch: true */ "./assets/PackageKitPage")
);

const supportedLangs = ["en", "zh"];
const fallbackLang = "en";

const useUnload = fn => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args) => {
      if (window.location.pathname.includes("/projects/new")) {
        cb.current?.(...args);
      }
    };

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
};

function App(props) {
  i18next.use(LanguageDetector).init({
    // order and from where user language should be detected
    order: ["querystring", "cookie", "localStorage", "sessionStorage", "navigator", "htmlTag", "path", "subdomain"],

    // keys or params to lookup language from
    lookupQuerystring: "lng",
    lookupCookie: "i18next",
    lookupLocalStorage: "i18nextLng",
    lookupSessionStorage: "i18nextLng",
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    lng: navigator.language || navigator.userLanguage,

    // cache user language on
    caches: ["localStorage", "cookie"],
    excludeCacheFor: ["cimode"] // languages to not persist (cookie, localStorage)
  });

  if (!supportedLangs.includes(i18next.language)) {
    localStorage.removeItem("i18nextLng");
    i18next.changeLanguage(fallbackLang);
  }

  const [isAuthenticated, setIsAuthenticated] = useState(props?.api?.isAuthenticated());

  const onAuthenticationChanged = isAuthenticated => {
    setIsAuthenticated({ isAuthenticated });
    console.log("onAuthenticationChanged", isAuthenticated);
  };

  const history = createBrowserHistory();

  function historyPush(route) {
    history.push(route);
  }

  window.historyPush = historyPush;

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-P3JJVN6"
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  const darkTheme = createTheme({
    palette: {
      type: "dark"
    }
  });

  useUnload(e => {
    console.debug("useUnload");
    e.preventDefault();
    e.returnValue = "";
  });

  return (
    <ApiContextProvider value={props?.api}>
      <AuthContextProvider value={isAuthenticated}>
        <Translation>
          {(t, { i18n }) => (
            <UserProvider t={t} i18n={i18n} onAuthenticationChanged={onAuthenticationChanged}>
              <Web3Provider t={t} i18n={i18n}>
                <UserContext.Consumer>
                  {userValue => {
                    return (
                      <Web3Context.Consumer>
                        {web3Value => {
                          return (
                            <MaterialUIThemeProvider theme={darkTheme}>
                              <ThemeProvider theme={theme}>
                                <Router basename={process.env.ROUTER_BASE_PATH}>
                                  <ReactNotification />
                                  <GlobalStyle />
                                  <AppBar />
                                  <Column as={Suspense} fallback={<Loading message="Loading..." fullScreen />}>
                                    <Switch>
                                      <RedirectRoute path="/" exact to="/home" />
                                      <Route path="/whats-new" exact component={WhatsNewPage} />
                                      <RedirectRoute path="/new" exact to="/projects" />
                                      <Route path="/login" exact component={LoginPage} />
                                      <Route path="/logout" exact component={LogoutPage} />
                                      <Route path="/projects/create" exact component={CreateProjectPage} />
                                      <RedirectRoute path="/projects/templates" exact to="/projects/create" />
                                      <Route path="/home" exact component={HomePage} />
                                      <Route path="/projects" exact component={ProjectsPage} />
                                      <Route path="/projects/:projectId" component={EditorContainer} />
                                      <Route path="/kits/package" component={PackageKitPage} />

                                      <Route path="/threedium/create" exact component={CreateThreediumProjectPage} />
                                      <Route
                                        path="/threedium/change-request/:threediumRequestId"
                                        component={ThreediumRequestChange}
                                      />

                                      <Route path="/api-keys" component={APIKeys} />
                                      <Route path="/land-scan" component={LandScan} />
                                      <Route render={() => <Error message="Page not found." />} />
                                    </Switch>
                                  </Column>
                                  <Telemetry />
                                </Router>
                              </ThemeProvider>
                            </MaterialUIThemeProvider>
                          );
                        }}
                      </Web3Context.Consumer>
                    );
                  }}
                </UserContext.Consumer>
              </Web3Provider>
            </UserProvider>
          )}
        </Translation>
      </AuthContextProvider>
    </ApiContextProvider>
  );
}

export default App;
