import config, { camelCaseKeys } from "../lib/config";
import { isNil, isEmpty } from "ramda";
import Cookies from "js-cookie";
import { getToken, saveUser, getSketchFabToken } from "../lib/auth";
import axios from "axios";
// FILE SAVE-LOAD REQUEST
// ----------------------------------------------------------------------------------------

export function userAuth(token, provider) {
  return railsRequest({ url: "/user/auth", method: "GET" }, { oauth: token, oauth_provider: provider });
}

// -------

export function signInOffChain(username, password) {
  return railsRequest({ url: "/authentication/login", method: "POST" }, { username, password }, null);
}

export function signUp(username, email, password, campaign = undefined) {
  return railsRequest(
    { url: "/authentication/signup", method: "POST" },
    {
      username,
      email,
      password
    },
    null
  );
}

export function signInMetamask(publicAddress, singedNonce) {
  return railsRequest(
    { url: "/authentication/login-metamask", method: "POST" },
    { public_address: publicAddress, signed_nonce: singedNonce },
    null
  );
}

export function signUpMetamask(publicAddress) {
  return railsRequest(
    { url: "/authentication/signup-metamask", method: "POST" },
    {
      public_address: publicAddress
      // referred_by_user_uuid: getCookie('referredByUser'),
    },
    null
  );
}

export function uploadNftAsset(fileUrl, filePermalink, nftName, nftCreatorName, nftId) {
  return railsRequest(
    { url: "/builder/assets/upload_nft", method: "POST" },
    {
      file_url: fileUrl,
      nft_permalink: filePermalink,
      nft_name: nftName,
      nft_creator_name: nftCreatorName,
      nft_id: nftId
    },
    null
  );
}

export function retrieve(userToken, provider, fileExtension = null) {
  return railsRequest(
    { url: "/builder/userdata", method: "GET" },
    { oauth: userToken, oauth_provider: provider, fileExtension: fileExtension }
  );
}

export function get_file(fileUuid, downloadUrl) {
  return railsRequest({ url: "/file/", method: "GET" }, { fileUuid: fileUuid, downloadUrl: downloadUrl });
}
export function get_project(fileUuid, downloadUrl) {
  return railsRequest({ url: "/builder/project/", method: "GET" }, { uuid: fileUuid, downloadUrl: downloadUrl });
}

export function get_sketch_asset(file_id) {
  return railsRequest(
    { url: "/builder/sketchfab/download", method: "GET" },
    { uuid: file_id, access_token: getSketchFabToken() }
  );
}

export function get_blob(url) {
  return new Promise((resolve, reject) => {
    let req = axios({
      method: "GET",
      url
    });
    req
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
        console.log(err);
      });
  });
}

//FILE .glb
//=============================================================================
export function uploadFile(file, file_thumbnail, userToken, provider, others, onUploadProgress, signal) {
  const formdata = new FormData();
  formdata.set("file", file);
  formdata.set("oauth", userToken);
  formdata.set("oauth_provider", provider);
  formdata.set("others", others);
  formdata.set("thumbnail", file_thumbnail);

  return formdata_request({ url: "/builder/assets/upload", method: "POST" }, formdata, {
    onUploadProgress: onUploadProgress,
    signal: signal
  });
}

//PROJECT .spoke
//=============================================================================
export function uploadProject(
  file,
  file_thumbnail,
  userToken,
  provider,
  others,
  onUploadProgress,
  signal,
  land_uuid,
  land_scan_uuid,
  folder_uuid
) {
  const formdata = new FormData();
  formdata.set("file", file);
  formdata.set("oauth", userToken);
  formdata.set("oauth_provider", provider);
  formdata.set("thumbnail", file_thumbnail);
  formdata.set("others", others);
  formdata.set("land_uuid", land_uuid);
  formdata.set("land_scan_uuid", land_scan_uuid);
  formdata.set("land_folder_uuid", folder_uuid);

  return formdata_request({ url: "/builder/project/upload", method: "POST" }, formdata, {
    onUploadProgress: onUploadProgress,
    signal: signal
  });
}

export function updateProject(file, file_thumbnail, userToken, provider, fileUuid, onUploadProgress, signal) {
  const formdata = new FormData();
  formdata.set("file", file);
  formdata.set("oauth", userToken);
  formdata.set("oauth_provider", provider);
  formdata.set("thumbnail", file_thumbnail);
  formdata.set("uuid", fileUuid);

  return formdata_request({ url: "/builder/project/update", method: "POST" }, formdata, {
    onUploadProgress: onUploadProgress,
    signal: signal
  });
}

//PUBLISHING

export function publishProject(
  userToken,
  provider,
  projectID,
  { selectedLandUuid, selectedFolderUuid, landScanUuid },
  publishingInfo,
  file_spoke,
  file_glb,
  file_thumbnail,
  file_video,
  nft_pass,
  onUploadProgress,
  signal
) {
  let json_publishingInfo = JSON.parse(publishingInfo);
  const formdata = new FormData();
  formdata.set("is_workspace", "false");
  if (selectedLandUuid.includes("is_workspace")) {
    formdata.set("is_workspace", "true");
    formdata.set("land_uuids", null);
  } else {
    if (selectedFolderUuid) {
      formdata.set("land_folder_uuid", selectedFolderUuid);
    } else {
      formdata.set("land_uuids", selectedLandUuid);
      if (landScanUuid) formdata.set("land_scan_uuid", landScanUuid);
    }
  }
  formdata.set("nft_pass", nft_pass);
  formdata.set("project_uuid", projectID);
  formdata.set("publishing_info", publishingInfo);
  formdata.set("environment_occlusion_ar", json_publishingInfo.environment_occlusion_ar);
  formdata.set("human_occlusion_ar", json_publishingInfo.human_occlusion_ar);
  formdata.set("mesh_occlusion_ar", json_publishingInfo.mesh_occlusion_ar);
  formdata.set("is_remote_ar_ever", json_publishingInfo.is_remote_ar_ever);
  formdata.set("is_remote_in_walk_mode_default", json_publishingInfo.is_remote_in_walk_mode_default);
  formdata.set("sdk_version", json_publishingInfo.sdk_version);
  let poap = sessionStorage.getItem("POAPToken");

  if (poap !== undefined) {
    formdata.set("poap_links", poap);
  }

  file_spoke != null && formdata.set("file_ovr", file_spoke);
  file_glb != null && formdata.set("file_glb", file_glb);
  file_thumbnail != null && formdata.set("file_thumbnail", file_thumbnail);
  file_video != null && formdata.set("file_video", file_video);

  return formdata_request({ url: "/builder/project/publish", method: "POST" }, formdata, {
    onUploadProgress: onUploadProgress,
    signal: signal
  });
}

// AUTHENTICATION REQUEST
// ----------------------------------------------------------------------------------------

/**
 * @function signIn
 * @param {*}
 */
export function signIn(publicAddress, singedNonce) {
  return railsRequest(
    { url: "/authentication/login", method: "POST" },
    { public_address: publicAddress, signed_nonce: singedNonce },
    null
  );
}

export function generateLoginToken() {
  return formdata_request({ url: "/authentication/generate-login-token", method: "POST" }, new FormData(), {})
}

export function getUserNonce(publicAddress) {
  return railsRequest({ url: "/user/nonce", method: "GET" }, { public_address: publicAddress }, null);
}

export function getUserLands(page = 1, perPage = 6, searchVal = null) {
  return railsRequest(
    { url: "/builder/user/lands", method: "GET" },
    { page: page, per_page: perPage, search_val: searchVal },
    null
  );
}

export function getUserFolders(page = 1, perPage = 6, searchVal = null) {
  return railsRequest(
    { url: "/land/folders", method: "GET" },
    { page: page, per_page: perPage, search_val: searchVal },
    null
  );
}

export function getUserPublishedLands(page = 1, perPage = 6) {
  return railsRequest({ url: "/user/published-lands", method: "GET" }, { page: page, per_page: perPage }, null);
}

export function searchUserLandScan(page = 1, perPage = 6, hexId, landUuid, strict = false) {
  return railsRequest(
    { url: "/landscan/search", method: "GET" },
    { page: page, per_page: perPage, hex_id: hexId, land_uuid: landUuid, strict: strict },
    null
  );
}

export function getUserLandScan(page = 1, perPage = 6) {
  return railsRequest({ url: "/landscan/index", method: "GET" }, { page: page, per_page: perPage }, null);
}

export function searchLandScan(page = 1, perPage = 6, hexId, landUuid, folderUuid = null, strict = false) {
  return railsRequest(
    { url: "/landscan/search2", method: "GET" },
    { page: page, per_page: perPage, hex_id: hexId, land_uuid: landUuid, land_folder_uuid: folderUuid, strict: strict },
    null
  );
}

export function getPaopPerProject(projectUuid = null) {
  return railsRequest({ url: "/poap/per-project", method: "GET" }, { project_uuid: projectUuid }, null);
}

export function userProfile(userToken) {
  saveUser("user", userToken);
  return railsRequest({ url: "/user/profile", method: "GET" }, {}, null);
}

export function getUserBalanceAndAllowance() {
  return railsRequest({ url: "/user/balance_and_allowance", method: "GET" }, {}, null);
}

export function signUpLoginMetamask(publicAddress) {
  return railsRequest({ url: "/authentication/metamask", method: "POST" }, { public_address: publicAddress }, null);
}
//DELETE
//=============================================================================
export function deleteFile(userToken, provider, uuid) {
  const formdata = new FormData();
  formdata.set("uuid", uuid);
  return formdata_request({ url: "/builder/file/delete", method: "POST" }, formdata);
}

export function deleteProject(userToken, provider, uuid) {
  const formdata = new FormData();
  formdata.set("uuid", uuid);

  return formdata_request({ url: "/builder/project/delete", method: "POST" }, formdata);
}
//UNPUBLISH
//=============================================================================
export function unpublishProject(uuid) {
  const formdata = new FormData();
  formdata.set("ovrbuilder_project_uuid", uuid);

  return formdata_request({ url: "/builder/project/unpublish", method: "POST" }, formdata);
}

export function unpublishLand(landUuid) {
  const formdata = new FormData();
  formdata.set("lands_uuid", landUuid);

  return formdata_request({ url: "/builder/project/unpublish", method: "POST" }, formdata);
}

//GET OVR ASSETS
//=============================================================================
export function retrieveOVRAssets(repoID, fileExtension) {
  return railsRequest({ url: "/builder/ovr-assets", method: "GET" }, { repoID: repoID, fileExtension: fileExtension });
}

//check for hexagon

export function checkHexagon(hexID) {
  return request({ url: "/locate", method: "GET" }, { hex_id: hexID });
}

export function retrieveNFTAssets(publicAddress, search) {
  if (search != "" && search != null) {
    return railsRequest({ url: "/user/nfts", method: "GET" }, { public_address: publicAddress, all: true, search });
  }
  return railsRequest({ url: "/user/nfts", method: "GET" }, { public_address: publicAddress, all: true });
}

// SDK TOKEN
export function createBuilderSDKToken(note, expiration) {
  return railsRequest({ url: "/users/create-builder-sdk-token", method: "POST" }, { note, expiration });
}

export function deleteBuilderSDKToken(id) {
  return railsRequest({ url: "/users/remove-builder-sdk-token", method: "POST" }, { sdk_token_id: id });
}

export function getBuilderSDKTokens() {
  return railsRequest({ url: "/users/builder-sdk-tokens", method: "GET" }, {});
}

// Threedium

// THREEDIUM_REQUEST_STATUS = {
//   pending: 1,
//   estimating: 2,
//   estimated: 3,
//   refused: 4,
//   confirmed: 5,
//   paied: 6,
//   working: 7,
//   completed: 8
// }

export function threediumCreateRequest(data) {
  return railsRequest({ url: "/threedium/create-request-action", method: "POST" }, data);
}

export function getThreediumRequests(page) {
  return railsRequest({ url: "/threedium/requests", method: "GET" }, { page: page, per_page: 999 });
}

export function getThreediumModels(page, per_page, status) {
  return railsRequest({ url: "/threedium/models", method: "GET" }, { page: page, per_page, status });
}

export function confirmThreediumRequest(uuid, paymentMethod) {
  return railsRequest(
    { url: "/threedium/confirm-request-action", method: "POST" },
    { request_uuid: uuid, confirmed: 1, payment_method: paymentMethod }
  );
}

export function rejectThreediumRequest(uuid) {
  return railsRequest(
    { url: "/threedium/confirm-request-action", method: "POST" },
    { request_uuid: uuid, confirmed: 0 }
  );
}

// GENERAL REQUEST
// ----------------------------------------------------------------------------------------
/**
 * @description This function helps the generation of an axios request with the api server.
 * @param {object} endpoint
 * @param {object} params
 * @param {string} token
 */
export function request(
  endpoint,
  params,
  req_config = {
    headers: {
      "x-api-key": "CDPgM9bZzCFxBJvbN1H098VIRAHhK5M5jmH02CTd",
      "Content-Type": "application/json"
    }
  }
) {
  const token = getToken("userToken");
  if (token) {
    axios.defaults.headers.common["Authorization"] = token; // eslint-disable-line
  }
  return new Promise((resolve, reject) => {
    let req;
    if (endpoint.method === "POST") {
      req = axios.post(`${config.apis.hostname}${endpoint.url}`, params, req_config);
    } else {
      req = axios.get(`${config.apis.hostname}${endpoint.url}`, { params });
    }
    req
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
        console.log(err);
      });
  });
}

export function railsRequest(
  endpoint,
  params,
  req_config = {
    headers: {
      "x-api-key": "CDPgM9bZzCFxBJvbN1H098VIRAHhK5M5jmH02CTd",
      "Content-Type": "application/json"
    }
  }
) {
  const token = getToken("userToken");
  if (token) {
    axios.defaults.headers.common["Authorization"] = token; // eslint-disable-line
  }
  return new Promise((resolve, reject) => {
    let req;
    if (endpoint.method === "POST") {
      req = axios.post(`${config.apis.railsHostname}${endpoint.url}`, params, req_config);
    } else {
      req = axios.get(`${config.apis.railsHostname}${endpoint.url}`, { params });
    }
    req
      .then(response => {
        resolve(camelCaseKeys(response));
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
}

export function formdata_request(
  endpoint,
  formdata,
  actionData,
  req_config = {
    headers: {
      "x-api-key": "CDPgM9bZzCFxBJvbN1H098VIRAHhK5M5jmH02CTd",
      "Content-Type": "multipart/form-data"
    }
  }
) {
  const token = getToken("userToken");
  if (token) {
    axios.defaults.headers.common["Authorization"] = token; // eslint-disable-line
  }

  let method = endpoint.method.toLowerCase();
  return new Promise((resolve, reject) => {
    let req = axios({
      method: method,
      url: `${config.apis.railsHostname}${endpoint.url}`,
      data: formdata,
      headers: req_config,
      unUploadProgress: function(progressEvent) {
        if (actionData && actionData.onUploadProgress) {
          actionData.onUploadProgress(progressEvent.loaded / progressEvent.total);
        }
      },
      unDownloadProgress: function(progressEvent) {
        if (actionData && actionData.onDownloadProgress) {
          actionData.onDownloadProgress(progressEvent.loaded / progressEvent.total);
        }
      }
    });

    req
      .then(response => {
        resolve(camelCaseKeys(response));
      })
      .catch(err => {
        reject(err);
        console.log(err);
      });
  });
}

export function sketch_request(params) {
  const token = getSketchFabToken();

  console.debug("sketch_request.token", token);
  if (token) {
    axios.defaults.headers.common["Authorization"] = token; // eslint-disable-line
  }
  return new Promise((resolve, reject) => {
    let req;
    req = axios.get(`https://api.sketchfab.com/v3/search`, { params });

    req
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
        console.log(err);
      });
  });
}

export function mappingSavePosition(land_map_uuid, scale, position, rotation) {
  return railsRequest(
    { url: "/landscan/reposition-data", method: "POST" },
    {
      land_map_uuid,
      hasData: true,
      reposition_data: {
        scale,
        position,
        rotation
      }
    },
    null
  );
}
