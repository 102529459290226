/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { Component, useState, useContext } from "react";
import * as R from "ramda";
import PropTypes from "prop-types";
import styled from "styled-components";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import LinearProgress from "@material-ui/core/LinearProgress";
import { Link } from "react-router-dom";
import { showMenu } from "../layout/ContextMenu";
import { Button, IconButton } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";

import { UserContext } from "../../ui/contexts/UserContext";
import { Web3Context } from "../../ui/contexts/Web3Context";

import { confirmThreediumRequest, rejectThreediumRequest } from "../../api/lib/api";
import { successNotification, warningNotification } from "../../api/lib/notifications";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const StyledRequestGridItem = styled(Link)`
  display: flex;
  flex-direction: column;
  height: 300px;
  border-radius: 6px;
  background-color: white;
  text-decoration: none;

  position: relative;
  overflow: hidden;
  cursor: default;
  color: #2a2266;
`;

const statusColor = {
  1: "#da9f25", // pending
  2: "#25daa7", // estimating
  3: "#25b9da", // estimated
  4: "#da9f25", // refused
  5: "#da9f25", // confirmed
  6: "#da9f25", // paied
  7: "#da9f25", // working
  8: "#3eb569" // completed
};

const StlyledProgressBar = styled(LinearProgress)`
  & {
    background-color: rgba(255, 255, 255, 0.35) !important;
    .MuiLinearProgress-bar {
      ${props =>
        props.status === "estimating" &&
        `
          background-color: #bcbcbc;
        `}

      ${props =>
        props.status === "pending" &&
        `
          background-color: #bcbcbc;
        `}

        ${props =>
          props.status === "completed" &&
          `
          background-color: #3c2bbc;
        `}
    }
  }
`;

const TileContent = styled.div`
  padding: 16px;

  &.completed {
    height: 100%;
    color: white;
    background-color: #715eff;
    .date {
      color: #3c2bbc;
    }
  }

  h3 {
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .date {
    font-size: 10px;
    color: #bcbcbc;
    font-weight: 500;
    margin-top: 5px;
  }

  .request-status {
    display: inline-block;
    border-radius: 4px;
    padding: 2px 13px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    color: white;

    &.pending {
      background-color: white;
      border: 1px solid #bcbcbc;
      color: #bcbcbc;
    }

    &.estimating {
      background-color: white;
      border: 1px solid #bcbcbc;
      color: #bcbcbc;
    }

    &.estimated {
      background-color: white;
      border: 1px solid #715eff;
      color: #715eff;
    }

    &.refused {
      background-color: ${statusColor[4]};
    }

    &.confirmed {
      background-color: white;
      border: 1px solid #00db4d;
      color: #00db4d;
    }

    &.paied {
      background-color: ${statusColor[6]};
    }

    &.working {
      background-color: ${statusColor[7]};
    }

    &.completed {
      background-color: #3c2bbc;
      border: 1px solid #3c2bbc;
      color: white;
    }
  }

  .section-buttons {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 16px;

    &.preventivato {
      display: flex;
      justify-content: space-around;
    }

    .single-button {
      border-radius: 50px;
      padding: 4px 15px;
      font-size: 12px !important;
      &.accept {
        background-color: #ff6e1a;
      }
    }
  }

  .button-download {
    margin-top: 20px;
    .MuiButtonBase-root {
      border-radius: 50px;
      background-color: #3eb569;
      color: white;
      box-shadow: none;
    }
  }
  .button-edit {
    margin-top: 5px;
    .MuiButtonBase-root {
      border-radius: 50px;
      box-shadow: none;
    }
  }

  .button-close {
    margin-top: 5px;
    .MuiButtonBase-root {
      border-radius: 50px;
      box-shadow: none;
    }
  }

  .price {
    .title {
      margin-top: 10px;
      text-align: center;
      font-size: 18px;
    }
    .value {
      display: flex;
      margin-top: 15px;
      margin-bottom: 15px;
      justify-content: flex-start;
      font-size: 18px;
      span {
        display: inline-block;
        margin-left: 6px;
      }
      svg {
        transform: scale(1.2);
        transform-origin: center;
      }
    }
  }

  button {
    svg {
      width: 1em;
      height: 1em;
    }
  }
`;
export default class ThreediumRequestGridItem extends Component {
  static propTypes = {
    contextMenuId: PropTypes.string,
    project: PropTypes.object.isRequired
  };

  onShowMenu = event => {
    event.preventDefault();
    event.stopPropagation();

    const x = event.clientX || (event.touches && event.touches[0].pageX);
    const y = event.clientY || (event.touches && event.touches[0].pageY);
    showMenu({
      position: { x, y },
      target: event.currentTarget,
      id: this.props.contextMenuId,
      data: {
        request: this.props.request
      }
    });
  };

  render() {
    const { request, contextMenuId, callback } = this.props;

    return (
      <StyledRequestGridItem to={request.url}>
        <Tile request={request} callback={callback} />
      </StyledRequestGridItem>
    );
  }
}

const Tile = ({ request, callback }) => {
  // console.debug('REQUESTTTT', request)
  const { state: userState, actions: userActions } = useContext(UserContext);
  const { refreshBalanceAndAllowance } = userActions;

  const { state: web3State, actions: web3Actions } = useContext(Web3Context);
  const { authorizeOvrExpense } = web3Actions;
  const { currentNetworkId } = web3State;

  const allowance = R.pathOr(null, ["user", "allowance"], userState);
  const balance = R.pathOr(null, ["user", "balance"], userState);

  const bscAllowance = R.pathOr(null, ["user", "ovrbscAllowance"], userState);
  const bscBalance = R.pathOr(null, ["user", "ovrbscBalance"], userState);

  const polygonAllowance = R.pathOr(null, ["user", "polygonAllowance"], userState);
  const polygonBalance = R.pathOr(null, ["user", "polygonBalance"], userState);

  const [ovrCurrencyType, setOvrCurrencyType] = useState(0);

  // console.debug('TILE.userState', userState)

  const [disabled, setDisabled] = useState(false);
  const { status: statusString, formParams, updatedAt, uuid } = request;
  const ovrPrice = R.pathOr(0, ["ovrPrice"], request);
  const gasValue = R.pathOr(0, ["gasValue"], request);
  const bscGasValue = R.pathOr(0, ["bscGasValue"], request);

  const totalToSpend = R.sum([Number(ovrPrice), Number(gasValue)]);
  const status = Number(statusString);

  const isAllowanceInsufficient =
    Number(totalToSpend) > Number(allowance) && !R.isNil(totalToSpend) && !R.isNil(allowance);

  const d = new Date(updatedAt);
  const year = d.getFullYear(); // 2019
  const date = d.getDate(); // 23
  const monthIndex = d.getMonth();
  const monthName = months[monthIndex];
  const dayIndex = d.getDay();
  const dayName = days[dayIndex]; //
  const hours = d.getHours();
  const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();

  const formatted = `${dayName}, ${date} ${monthName} ${year}, ${hours}:${minutes}`;

  const onClickConfirm = () => {
    console.log("ovrCurrencyType", ovrCurrencyType);
    switch (ovrCurrencyType) {
      case 0: {
        if (totalToSpend > allowance) {
          return warningNotification(
            "Warning",
            "Insufficient OVR on Ethereum Allowance, please go to marketplace.ovr.ai and increase ETH Auction Allowance"
          );
        }

        if (totalToSpend > balance) {
          return warningNotification("Warning", "Insufficient OVR ETH Balance");
        }
        break;
      }

      case 4: {
        // BSC
        if (totalToSpend > bscAllowance) {
          return warningNotification(
            "Warning",
            "Insufficient OVR BSC Allowance, please go to marketplace.ovr.ai and increase BSC Auction Allowance"
          );
        }

        if (totalToSpend > bscBalance) {
          return warningNotification("Warning", "Insufficient OVR BSC Balance");
        }
        break;
      }

      case 5: {
        // Polygon
        if (totalToSpend > polygonAllowance) {
          return warningNotification(
            "Warning",
            "Insufficient OVR Polygon Allowance, please go to marketplace.ovr.ai and increase Polygon Auction Allowance"
          );
        }

        if (totalToSpend > polygonBalance) {
          return warningNotification("Warning", "Insufficient OVR Polygon Balance");
        }
        break;
      }
    }

    setDisabled(true);
    confirmThreediumRequest(uuid, ovrCurrencyType)
      .then(res => {
        console.debug("confirmThreediumRequest.res", res);
        setDisabled(false);
        const result = R.pathOr(false, ["data", "result"], res);
        if (result) {
          callback();
        }

        const errorMessage = R.path(["data", "errors", 0, "message"], res);

        if (errorMessage) {
          warningNotification("Error", errorMessage);
        }
      })
      .catch(err => {
        console.debug("confirmThreediumRequest.err", err);
        setDisabled(false);
      });
  };

  const onClickReject = () => {
    rejectThreediumRequest(uuid)
      .then(res => {
        console.debug("rejectThreediumRequest.res", res);
        const result = R.pathOr(false, ["data", "result"], res);
        if (result) {
          callback();
        }

        const errorMessage = R.path(["data", "errors", 0, "message"], res);
        if (errorMessage) {
          warningNotification("Error", errorMessage);
        }
      })
      .catch(err => {
        console.error("rejectThreediumRequest.err", err);
      });
  };

  // const onClickAllowance = async () => {
  //   const res = await authorizeOvrExpense();
  //   successNotification(
  //     "Allowance sended",
  //     "Please wait the allowance transaction to succeed and try again. You can check transaction status on etherscan."
  //   );

  //   const update = await refreshBalanceAndAllowance();
  // };

  switch (status) {
    case 1: {
      // TODO - STATUS: PENDING
      return (
        <>
          <TileContent>
            <div className="request-status pending">Pending</div>
            <h3>{formParams?.question1}</h3>
            <div className="date">{formatted}</div>
          </TileContent>
          <StlyledProgressBar
            status="pending"
            className="linear-progress"
            style={{ position: "absolute", left: 0, right: 0, bottom: 0 }}
          />
        </>
      );
    }

    case 2: {
      // TODO - STATUS: ESTIMATING
      return (
        <>
          <TileContent>
            <div className="request-status estimating">Estimating</div>
            <h3>{formParams?.question1}</h3>
            <div className="date">{formatted}</div>
          </TileContent>
          <StlyledProgressBar
            status="estimating"
            className="linear-progress"
            style={{ position: "absolute", left: 0, right: 0, bottom: 0 }}
          />
        </>
      );
    }

    case 5: {
      // TODO - STATUS: CONFIRMED
      return (
        <>
          <TileContent>
            <Tooltip title={"Looking forward to receiving payment"} placement="left" arrow>
              <div className="request-status confirmed">Confirmed</div>
            </Tooltip>
            <h3>{formParams?.question1}</h3>

            <div className="date">{formatted}</div>
          </TileContent>
        </>
      );
    }

    case 6: {
      // TODO - STATUS: PAID
      return (
        <>
          <TileContent>
            <div className="request-status completed">Paid</div>
            <h3>{formParams?.question1}</h3>
            <div className="date">{formatted}</div>
          </TileContent>
        </>
      );
    }

    case 7: {
      // TODO - STATUS: WORKING
      return (
        <>
          <TileContent>
            <div className="request-status confirmed">Working</div>
            <h3>{formParams?.question1}</h3>
            <div className="date">{formatted}</div>
          </TileContent>
          <StlyledProgressBar
            status="pending"
            className="linear-progress"
            style={{ position: "absolute", left: 0, right: 0, bottom: 0 }}
          />
        </>
      );
    }

    case 8: {
      return (
        <>
          <TileContent className="completed">
            <div className="request-status completed">Completed</div>
            <h3>{formParams?.question1}</h3>
            <div className="date">{formatted}</div>
            <div
              style={{
                fontSize: "16px",
                marginTop: "15px",
                color: "white",
                position: "absolute",
                bottom: 16,
                left: 16,
                right: 16,
                fontWeight: 600
              }}
            >
              You can find the 3D asset under "My Assets"
            </div>
          </TileContent>
        </>
      );
    }

    case 3: {
      // TODO - STATUS: ESTIMATED
      return (
        <>
          <TileContent>
            <div className="request-status estimated">Estimated</div>
            <h3>{formParams?.question1}</h3>
            <div className="date">{formatted}</div>
            <div className="price">
              <p className="value">
                <CurrencyOVRLogo />
                <span>
                  {ovrCurrencyType == 0
                    ? Number(R.sum([Number(ovrPrice).toFixed(2), Number(gasValue).toFixed(2)])).toFixed(2)
                    : Number(R.sum([Number(ovrPrice).toFixed(2), Number(bscGasValue).toFixed(2)])).toFixed(2)}{" "}
                  <Tooltip
                    title={`The price includes the transaction cost: ${
                      ovrCurrencyType == 0 ? Number(gasValue).toFixed(2) : Number(bscGasValue).toFixed(2)
                    } OVR`}
                    placement="left"
                    arrow
                  >
                    <HelpIcon style={{ fontSize: 13 }} />
                  </Tooltip>
                </span>
              </p>
            </div>
            <SelectOVRCoin
              value={ovrCurrencyType}
              onChange={e => {
                const type = e.target.value;
                setOvrCurrencyType(type);
              }}
            />

            <div
              className="buttons-section"
              style={{
                display: "flex",
                alignItems: "center",
                justifyontent: "center",
                position: "absolute",
                left: 16,
                right: 16,
                bottom: 16
              }}
            >
              <Tooltip title="Reject and delete the request" placement="left" arrow>
                <IconButton
                  size="small"
                  onClick={onClickReject}
                  style={{
                    color: "black",
                    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Button
                size="small"
                fullWidth
                disabled={disabled}
                variant="contained"
                style={{ borderRadius: 50, boxShadow: "none", background: "#715EFF", marginLeft: 10, color: "white" }}
                onClick={onClickConfirm}
              >
                Confirm and Pay
              </Button>
            </div>
          </TileContent>
        </>
      );
    }

    default: {
      return (
        <TileContent>
          <div className="request-status completed">Accepted</div>
          <h3>{formParams?.question1}</h3>
          <div className="date">{formatted}</div>
        </TileContent>
      );
    }
  }
};

// const DialogConfirmPay = ({ open, closeConfirmPayDialog }) => {
//   return (
//     <Dialog
//       open={open}
//       onClose={closeConfirmPayDialog}
//       aria-labelledby="alert-dialog-title"
//       aria-describedby="alert-dialog-description"
//     >
//       <DialogTitle id="alert-dialog-title">Accept and Pay or Decline</DialogTitle>
//       <DialogContent>
//         <DialogContentText id="alert-dialog-description">
//           Once accepted, the Threedium team will start creating the 3D model you requested.
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={closeConfirmPayDialog} color="primary">
//           Decline
//         </Button>
//         <Button onClick={closeConfirmPayDialog} color="primary" autoFocus>
//           Accept and Pay
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

const SelectOVRCoin = ({
  allowedCoins = ["ovrD", "ovrBSC", "ovrPolygon"], // ovrD || ovrM || ovrBSC || credits || dai || treasure
  value,
  defaultValue,
  className,
  ...other
}) => {
  return (
    <div
      style={{
        background: "white",
        color: "black",
        border: "1px solid #e0e0e0",
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        borderRadius: 10,
        marginTop: 10,
        marginBottom: 0,
        textAlign: "center",
        padding: "0 10px"
      }}
    >
      <div
        style={{
          textAlign: "left",
          color: "#BCBCBC",
          fontWeight: 600,
          paddingTop: 5,
          fontSize: 12
        }}
      >
        Payment Method
      </div>
      <Select
        value={value}
        defaultValue={1}
        {...other}
        style={{ width: "100%", color: "#715EFF" }}
        className={`ovr-select-payment-method ${className}`}
      >
        {allowedCoins.map((el, index) => {
          if (el === "ovrD") {
            return (
              <MenuItem value={0} key={index}>
                <span style={{ display: "flex", alignItems: "center" }} className="c-text">
                  OVR on Ethereum
                </span>
              </MenuItem>
            );
          }

          if (el === "ovrBSC") {
            return (
              <MenuItem value={4} key={index}>
                <span style={{ display: "flex", alignItems: "center" }} className="c-text">
                  OVR on BSC
                </span>
              </MenuItem>
            );
          }

          if (el === "ovrPolygon") {
            return (
              <MenuItem value={5} key={index}>
                <span style={{ display: "flex", alignItems: "center" }} className="c-text">
                  OVR on Polygon
                </span>
              </MenuItem>
            );
          }
        })}
      </Select>
    </div>
  );
};

const CurrencyOVRLogo = () => (
  <svg
    className="ovr-currency-logo"
    width="18px"
    height="20px"
    data-name="Livello 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1024 1024"
  >
    <defs>
      <linearGradient
        id="a"
        data-name="Sfumatura senza nome 92"
        x1="36.971"
        y1="145.626"
        x2="878.232"
        y2="792.362"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".001" stopColor="#f9b215" />
        <stop offset="1" stopColor="#de5f60" />
      </linearGradient>
      <linearGradient id="b" x1="36.839" y1="145.798" x2="878.101" y2="792.533" xlinkHref="#a" />
      <linearGradient id="c" x1="36.398" y1="146.372" x2="877.659" y2="793.107" xlinkHref="#a" />
    </defs>
    <path
      d="M512 1024a123.246 123.246 0 01-48.546-9.905L124.388 869.7a123.924 123.924 0 01-75.359-114V265.928a123.92 123.92 0 0176.036-114.284L464.13 9.62a123.906 123.906 0 0195.74.001l339.067 142.023a123.917 123.917 0 0176.034 114.284V755.7a123.92 123.92 0 01-75.359 114L560.55 1014.094a123.256 123.256 0 01-48.55 9.906zm0-983.506a82.972 82.972 0 00-32.225 6.477L140.709 188.994a83.424 83.424 0 00-51.185 76.934V755.7a83.427 83.427 0 0050.73 76.742l339.065 144.395a83.422 83.422 0 0065.364 0l339.063-144.395a83.425 83.425 0 0050.73-76.743V265.928a82.919 82.919 0 00-15.757-48.784v-.001a83.345 83.345 0 00-35.426-28.148L544.225 46.97A82.968 82.968 0 00512 40.494z"
      fill="url(#a)"
    />
    <path
      d="M512 910.95a91.983 91.983 0 01-37.941-8.162L226.586 791.59a96.761 96.761 0 01-40.294-34.03 102.566 102.566 0 01-17.668-57.884V322.498a102.53 102.53 0 0117.82-58.11 96.682 96.682 0 0140.67-34.038l247.472-109.373a92.26 92.26 0 0174.828 0L796.887 230.35a96.695 96.695 0 0140.667 34.038v-.001a102.523 102.523 0 0117.822 58.111v377.178a102.561 102.561 0 01-17.668 57.884 96.755 96.755 0 01-40.295 34.03L549.941 902.788A91.99 91.99 0 01512 910.95zm0-757.405a51.747 51.747 0 00-21.045 4.47L243.483 267.388a56.227 56.227 0 00-23.603 19.845 62.2 62.2 0 00-10.761 35.265v377.178a62.225 62.225 0 0010.67 35.13 56.292 56.292 0 0023.395 19.847L490.656 865.85a51.779 51.779 0 0042.689 0l247.471-111.198a56.292 56.292 0 0023.396-19.847 62.221 62.221 0 0010.67-35.13V322.498a62.192 62.192 0 00-10.762-35.264v-.001a56.233 56.233 0 00-23.603-19.845L533.045 158.015a51.752 51.752 0 00-21.045-4.47z"
      fill="url(#b)"
    />
    <path
      d="M512 740.97a228.972 228.972 0 11161.907-67.064A227.47 227.47 0 01512 740.971zm0-417.447c-103.926 0-188.477 84.55-188.477 188.477S408.073 700.477 512 700.477 700.477 615.927 700.477 512 615.927 323.523 512 323.523z"
      fill="url(#c)"
    />
  </svg>
);
