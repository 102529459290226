import React, { useEffect, useState } from "react";
import * as R from "ramda";
import styled from "styled-components";

import NavBar from "../navigation/NavBar";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { createBuilderSDKToken, getBuilderSDKTokens, deleteBuilderSDKToken } from "../../api/lib/api";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const displayDate = toFormat => {
  if (R.isNil(toFormat) || R.isEmpty(toFormat)) return "Never";
  const d = new Date(toFormat);
  const year = d.getFullYear(); // 2019
  const date = d.getDate(); // 23
  const monthIndex = d.getMonth();
  const monthName = months[monthIndex];
  const dayIndex = d.getDay();
  const hours = d.getHours();
  const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();

  return `${date} ${monthName} ${year} ${hours}:${minutes}`;
};

const APIKeys = props => {
  const [tokens, setTokens] = useState([]);
  const [form, setForm] = useState({ note: null, expiration: null });
  const [loading, setloading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = () => {
    createBuilderSDKToken(form.note, form.expiration)
      .then(res => {
        console.debug("createBuilderSDKToken.res", res);
        getTokens();
      })
      .catch(err => {
        console.error("createBuilderSDKToken.err", err);
      });

    setTimeout(() => {
      handleClose();
    }, 1000);
  };

  const handleDelete = id => {
    deleteBuilderSDKToken(id)
      .then(res => {
        console.debug("deleteBuilderSDKToken.res", res);
        getTokens();
      })
      .catch(err => {
        console.error("deleteBuilderSDKToken.err", err);
      });
  };

  const getTokens = () => {
    getBuilderSDKTokens()
      .then(res => {
        console.debug("getBuilderSDKTokens.res", res);
        const tokens = R.pathOr([], ["data", "sdkTokens"], res);
        setTokens(R.reverse(tokens));
      })
      .catch(err => {
        console.error("getBuilderSDKTokens.err", err);
      });
  };

  useEffect(() => {
    getTokens();
  }, []);

  return (
    <>
      <NavBar />
      <Container>
        <PageHeader>
          <h1>Access tokens</h1>
          <p>
            Here you can create Unity SDK access tokens to grant permission to create a projects and publish them in any
            OVRLand in your possession.
          </p>
          <Button
            variant="contained"
            color="default"
            onClick={handleClickOpen}
            style={{ borderRadius: 30, marginTop: 30 }}
            startIcon={<AddIcon />}
          >
            Create new Token
          </Button>
        </PageHeader>
        <StyledTable aria-label="simple table" className="table-keys">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">Token</TableCell>
              <TableCell align="left">Expiration</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tokens.map(row => (
              <>
                <TableRow key={row?.id}>
                  <TableCell component="th" scope="row">
                    {row?.note}
                  </TableCell>
                  <TableCell align="left">
                    <Nonce>{row.nonce}</Nonce>
                  </TableCell>
                  <TableCell align="left">{displayDate(row.expiration)}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="default"
                      size="small"
                      style={{
                        borderRadius: 30,
                        fontFamily: "'Work Sans', sans-serif!important",
                        padding: "6px 20px",
                        textTransform: "CAPITALIZE"
                      }}
                      startIcon={<DeleteOutlineIcon />}
                      onClick={() => handleDelete(row.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
                <tr className="spacer"></tr>
              </>
            ))}
          </TableBody>
        </StyledTable>
      </Container>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create New Token</DialogTitle>
        <DialogContent>
          <DialogContentText>If you want the token to never expire, leave the date field blank.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="note"
            label="Name"
            type="text"
            fullWidth
            onChange={e => {
              const value = e?.target?.value;
              setForm(s => ({ ...s, note: value }));
            }}
          />

          <TextField
            style={{ marginTop: 30 }}
            autoFocus
            fullWidth
            id="textField_18"
            label="Expiration Date"
            type="date"
            defaultValue={form.expiration}
            value={form.expiration}
            InputLabelProps={{
              shrink: true
            }}
            onChange={e => {
              const value = e?.target?.value;
              setForm(s => ({ ...s, expiration: value }));
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreate} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
  width: 100%;

  .MuiButton-root {
    font-family: "Work Sans", sans-serif !important;
  }
`;

const PageHeader = styled.div`
  padding: 80px 0;
  text-align: center;
  h1 {
    font-size: 53px;
    font-weight: 600;
  }
  p {
    font-size: 20px;
    width: 60%;
    margin: 15px auto;
  }

  .MuiButton-root {
    font-family: "Work Sans", sans-serif !important;
  }
`;

const StyledTable = styled(Table)`
  margin-top: 50px;

  .MuiTableCell-head {
    color: white;
    font-weight: 600;
  }

  .MuiTableCell-body {
    color: white;
  }

  .MuiTableCell-root {
    border-bottom: none;
  }

  .spacer {
    height: 10px;
  }

  tbody {
    .MuiTableRow-root {
      margin-bottom: 10px;
      background: #2a2266;
      border-radius: 3px;
    }
  }
`;

// const StyledTable = styled(Table)`
//   .MuiTableCell-head {
//     color: white;
//     font-weight: 600;
//   }

//   .MuiTableCell-body {
//     color: white;
//   }

//   .spacer {
//     height: 10px;
//   }
// `;

const Nonce = styled.span`
  font-size: 11px;
  font-family: monospace;
  letter-spacing: 1px;
  /* background: #422f6e; */
  padding: 3px 5px;
  border-radius: 4px;
`;

export default APIKeys;
