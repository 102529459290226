import React, { useContext, useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'
import Dialog from './Dialog'
import { bytesToSize } from '../utils'
import { ReactSelectize, SimpleSelect, MultiSelect } from 'react-selectize'
import list_of_words_v1 from '../../list_of_words_v1.json'
import _ from 'lodash'
import Map from '../assets/Map'
import Cookies from 'js-cookie'
import { checkHexagon } from '../../api/lib/api'
import { getUserLands, getUserFolders } from '../../api/lib/api'
import Pagination from '@material-ui/lab/Pagination'
import LandCard from '../ovr/LandCard/LandCard'
import LandFolder from '../ovr/LandFolder/LandFolder'
import SandBoxLandCard from '../ovr/LandCard/SandBoxLandCard'
import TextField from '@material-ui/core/TextField'

import StringInput from '../inputs/StringInput'
import FormField from '../inputs/FormField'

import { default as MuiButton } from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { Button } from '../inputs/Button'
import { BigNumber } from 'ethers'
import axios from "axios";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const LeftContent = styled.div`
  display: flex;
  width: 460px;
  border-top-left-radius: inherit;
  align-items: center;
  padding: 20px;
`

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 20px;
`

export default function SelectLandDialog({ ...props }) {
  // const [selectedHexId, setSelectedHexId] = useState(null)
  // const [selectedLandUuid, setSelectedLandUuid] = useState(null)
  const [selectedHexId, setSelectedHexId] = useState([])
  const [selectedLandUuid, setSelectedLandUuid] = useState([])
  const [selectedFolderUuid, setSelectedFolderUuid] = useState(null)
  const [selectedIntIds, setSelectedIntIds] = useState([])
  const [selectedLandLatitude, setSelectedLandLatitude] = useState(0)
  const [hexOccupied, setHexOccupied] = useState(false)
  const [searchBarValue, setSearchBarValue] = useState('')
  const [landList, setLandList] = useState([])
  const [folderList, setFolderList] = useState([])
  const [numberOfLandPages, setNumberOfLandPages] = useState(0)
  const [numberOfFolderPages, setNumberOfFolderPages] = useState(0)
  const [currentLandPage, setCurrentLandPage] = useState(1)
  const [currentFolderPage, setCurrentFolderPage] = useState(1)
  const [currentTab, setCurrentTab] = useState(0)

  useEffect(() => {
    loadLandsByPage(currentLandPage, 5)
    loadFoldersByPage(currentFolderPage, 6)
  }, [])

  function handleLandPageClick(event, number) {
    setCurrentLandPage(number)
    // if there is no search value load page
    if (searchBarValue == '') {
      loadLandsByPage(number, 5)
    } else {
      handleSearchLands(number)
    }
  }

  function handleFolderPageClick(event, number) {
    setCurrentFolderPage(number)
    loadFoldersByPage(number, 6)
  }

  function loadLandsByPage(page, perPage = 5) {
    getUserLands(page, perPage).then((result) => {
      let lands = result.data.lands
      if (lands.length > 0) {
        // Load user data in context store
        setLandList(lands)
        setNumberOfLandPages(result.data.numberOfPages)
      } else {
        setLandList(
          <div className="c-dialog --centered">
            <div className="c-dialog-main-title">
              Currently you don't own any land
            </div>
            <div className="c-dialog-sub-title">
              Visit{' '}
              <a href="https://marketplace.ovr.ai" target="_blank">
                marketplace.ovr.ai
              </a>{' '}
              and get your first Land now! In alternative you can use your
              personal Workspace.
            </div>
          </div>
        )
      }
    })
  }

  function loadFoldersByPage(page, perPage = 6) {
    getUserFolders(page, perPage).then((result) => {
      let folders = result.data.folders
      console.log('result', result.data)
      if (folders.length > 0) {
        // Load user data in context store
        setFolderList(folders)
        setNumberOfFolderPages(result.data.totalPages)
      } else {
        setFolderList(
          <div className="c-dialog --centered">
            <div className="c-dialog-main-title">
              Currently you don't have any folder
            </div>
            <div className="c-dialog-sub-title">
              Visit{' '}
              <a href="https://marketplace.ovr.ai" target="_blank">
                marketplace.ovr.ai
              </a>{' '}
              and get your first Land now! In alternative you can use your
              personal Workspace.
            </div>
          </div>
        )
      }
    })

  }

  function handleSearchLands(page) {
    setCurrentLandPage(1)
    getUserLands(page, 5, searchBarValue).then((result) => {
      let lands = result.data.lands
      if (lands.length > 0) {
        // Load user data in context store
        setLandList(lands)
        setNumberOfLandPages(result.data.numberOfPages)
      } else {
        setLandList(
          <div className="c-dialog --centered">
            <div className="c-dialog-main-title">
              No land is matching your search value
            </div>
          </div>
        )
      }
    })
  }

  // const handleChangeSelectedLand = (hexId, landUuid) => {
  //   setSelectedHexId(hexId)
  //   setSelectedLandUuid(landUuid)
  // }

  const handleChangeSelectedLand = (hexId, landUuid, intId, landLatitude) => {
    setSelectedFolderUuid(() => null);
    if (!selectedHexId.includes(hexId)) {
      setSelectedHexId((s) => {
        // if (
        //   hexId === 'is_workspace' ||
        //   selectedHexId.includes('is_workspace')
        // ) {
        //   return [hexId]
        // } else {
        //   return [...s, hexId]
        // }
        return [hexId]
      })

      setSelectedIntIds(() => [intId])
      setSelectedLandLatitude(() => landLatitude)

      setSelectedLandUuid((s) => {
        // if (
        //   hexId === 'is_workspace' ||
        //   selectedHexId.includes('is_workspace')
        // ) {
        //   return [landUuid]
        // } else {
        //   return [...s, landUuid]
        // }
        return [landUuid]
      })
    } else {
      setSelectedHexId((s) => selectedHexId.filter((single) => hexId != single))
      setSelectedLandUuid((s) =>
        selectedLandUuid.filter((single) => landUuid != single)
      )
    }
    
  }

  // useEffect(() => {
  //   if (selectedIntIds.length > 0) {
  //     const mapSize = 2048;
  //     const zoomLevel = 19;
  //     //const mapUrl = `https://map-screenshot.ovr.ai/image?map=${selectedIntIds.join()}&zoom=${zoomLevel}&size=${mapSize}`;
  //     const mapUrl = `https://mws.ovr.ai/services/land/map_builder_image/${selectedIntIds.join()}/${zoomLevel}`;
  //     let req = axios.get(mapUrl);
  //     req
  //     .then(response => {
  //       console.log('DEBUG: created map')
  //     })
  //     .catch(err => {
  //       reject(err);
  //       console.log('DEBUG: created map')
  //     });
  //   }
  // }, [selectedIntIds])


  const handleChangeSelectedFolder = uuid => {
    setSelectedHexId(() => [])
    setSelectedLandUuid(() => [])
    if (selectedFolderUuid == uuid) {
      setSelectedFolderUuid(() => null)
      return;
    }
    setSelectedIntIds(() => folderList.find(folder => folder.uuid == uuid).lands.map(land => {
      let hexNumber = land.hexId;
      if (hexNumber.indexOf('0x') != 0)
        hexNumber = '0x' + hexNumber;
      return BigNumber.from(hexNumber).toString();
    }));
    setSelectedLandLatitude(() => landList.find(land => land.uuid == folderList.find(folder => folder.uuid == uuid).landUuids[0])?.address?.geocenterString[0])
    setSelectedFolderUuid(() => uuid)
  }

  function onConfirm() {
    const publishState = { selectedHexId, selectedLandUuid, hexOccupied, selectedFolderUuid, selectedIntIds, selectedLandLatitude }
    if (selectedHexId.length || selectedFolderUuid) {
      props.onConfirm(publishState)
    } else {
      alert('You have to bind your project to a land. Select an OVRLand.')
    }
  }

  function handleChangeCurrentTab(tabVal) {
    setCurrentTab(tabVal)
  }

  return (
    <Dialog
      tag={props.tag}
      title={props.title}
      cancelLabel={props.cancelLabel}
      confirmLabel={props.confirmLabel}
      onConfirm={onConfirm}
      onCancel={props.onCancel}
    >
      <>
        <div className="marketplace-switch-container">
          <ButtonGroup
            className="marketplace-switch"
            color="primary"
            aria-label="outlined primary button group"
          >
            <MuiButton
              onClick={() => handleChangeCurrentTab(0)}
              className={`type type-primary ${currentTab === 0 ? 'selected' : ''}`}
              color="default"
            >
              Lands
            </MuiButton>
            <MuiButton
              onClick={() => handleChangeCurrentTab(1)}
              className={`type type-secondary ${currentTab === 1 ? 'selected' : ''
                }`}
              color="default"
            >
              Folder
            </MuiButton>
          </ButtonGroup>
        </div>
        {currentTab === 0 && (
          <div className="d-select-land d-tab">
            <div>Select the land you want your project to be published to.</div>
            <div className="o-search-bar-holder">
              <TextField
                type="text"
                placeholder="Search by NFT ID, Land Id, Land Name"
                onChange={(e) => {
                  setSearchBarValue(e.target.value)
                }}
              />
              <Button style={{
                fontSize: '16px',
                paddingTop: '8px',
                paddingBottom: '8px',
                display: 'inline-block',
                marginLeft: '10px'
              }} onClick={() => {
                handleSearchLands()
              }}>
                Search
              </Button>
            </div>
            <div className="o-land-list">
              {searchBarValue === '' && (
                <SandBoxLandCard
                  key={'is_workspace'}
                  className={`${selectedHexId.includes('is_workspace') ? '--selected' : ''
                    }`}
                  customOnClick={handleChangeSelectedLand.bind(this)}
                />
              )}

              {landList instanceof Array
                ? landList.map((obj) => (
                  <LandCard
                    key={obj.hexId}
                    background_image={`url(${obj.mapTileUrl}`}
                    name={{ sentence: obj.sentenceId, hex: obj.hexId }}
                    landUuid={obj.uuid}
                    hexId={obj.hexId}
                    intId={obj.intId}
                    location={obj.address.full}
                    landLatitude={obj.address.geocenterString[0]}
                    // className={`${
                    //   obj.hexId === selectedHexId ? '--selected' : ''
                    // }`}
                    className={
                      selectedHexId.includes(obj?.hexId) ? '--selected' : ''
                    }
                    customOnClick={handleChangeSelectedLand.bind(this)}
                  />
                ))
                : landList}
            </div>
            <div className="o-pagination">
              {numberOfLandPages > 1 && (
                <Pagination
                  count={numberOfLandPages}
                  page={currentLandPage}
                  onChange={handleLandPageClick}
                />
              )}
            </div>
          </div>
        )}
        {currentTab === 1 && (
          <div className="d-select-land d-tab">
            <div>Select the contiguous lands, grouped by folder, you want your project to be published to.</div>
            <div className="o-land-list">


              {folderList instanceof Array
                ? folderList.map((obj) => (
                  <LandFolder
                    key={obj.uuid}
                    folder={obj}
                    className={(selectedFolderUuid == obj?.uuid) ? '--selected' : ''}
                    customOnClick={handleChangeSelectedFolder.bind(this)}
                  />
                ))
                : folderList}
            </div>
            <div className="o-pagination">
              {numberOfFolderPages > 1 && (
                <Pagination
                  count={numberOfFolderPages}
                  page={currentFolderPage}
                  onChange={handleFolderPageClick}
                />
              )}
            </div>
          </div>
        )}
      </>
    </Dialog>
  )
}
