import React, { useState, useEffect } from 'react'
import Dialog from './Dialog'
import _ from 'lodash'
import { searchLandScan, searchUserLandScan } from '../../api/lib/api'
import Pagination from '@material-ui/lab/Pagination'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import placeholder from '../../assets/mapping-placeholder.jpg'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ProgressBar from "../inputs/ProgressBar";

const MappingCard = styled.div`
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;
  position: relative;
  cursor: pointer;

  .selected-overlay {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  
  &.--selected {
    transform: scale(0.98);

    .selected-overlay {
      opacity: 1;
      background-color: rgba(114, 74, 146, 0.6);

      .MuiSvgIcon-root {
        font-size: 100px;
        color: #fff;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translate(-50%, -50%);
        left: 50%;
      }
    }
  }

  .image {
    padding-bottom: 46.25%;
    position: relative;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .name {
    background-color: #fff;
    padding: 10px;
    color: #333;
  }

  .created-at {
    font-size: 13px;
    color: #857f7c;
  }
`

export default function SelectMappingDialog({ ...props }) {
  // const [selectedRibeyeMapId, setSelectedRibeyeMapId] = useState(null)
  // const [selectedLandScanUuid, setSelectedLandScanUuid] = useState(null)
  const [selectedRibeyeMapId, setSelectedRibeyeMapId] = useState([])
  const [selectedLandScanUuid, setSelectedLandScanUuid] = useState([])
  const [repositionData, setRepositionData] = useState(null)
  const [isLoading, setIsLoading] = useState(1);

  const [searchBarValue, setSearchBarValue] = useState('')
  const [mappingsList, setMappingsList] = useState([])
  const [numberOfLandPages, setNumberOfLandPages] = useState(0)
  const [currentLandPage, setCurrentLandPage] = useState(1)

  const selectedLandHexId = props.selectedLand.selectedHexId[0];
  const selectedLandUuid = props.selectedLand.selectedLandUuid[0];
  const selectedFolderUuid = props.selectedLand.selectedFolderUuid;

  useEffect(() => {
    loadMappingsByPage(currentLandPage, 5)
  }, [])

  function handleLandPageClick(event, number) {
    setCurrentLandPage(number)
    // if there is no search value load page
    if (searchBarValue == '') {
      if (number > 1) {
        loadMappingsByPage(number, 6)
      } else {
        loadMappingsByPage(number, 5)
      }
    } else {
      handleSearchLands(number)
    }
  }

  function loadMappingsByPage(page, perPage = 6) {
    const renderEmpty = () => {
      setMappingsList(
        <div className="c-dialog --centered">
          <div className="c-dialog-main-title" style={{ margin: '20px 0 10px' }}>
            Currently there are no mappings associated with the chosen land.
          </div>
          <div className="c-dialog-sub-title" style={{ margin: '10px 0' }}>
            {/* Download OVeR app to generate a mapping */}
            You can still continue creating your project.
          </div>
        </div>
      )
    }
    // if (!selectedLandHexId || selectedLandHexId == 'is_workspace') {
    //   setIsLoading(() => false);
    //   renderEmpty();
    //   return;
    // }
    searchLandScan(page, perPage, selectedLandHexId, null, selectedFolderUuid, true).then((result) => {
      setIsLoading(() => false);
      let landscans = result.data.landScans
      if (landscans && landscans.length > 0) {
        // Load user data in context store
        setMappingsList(landscans)
        setNumberOfLandPages(result.data.numberOfPages)
      } else {
        renderEmpty();
      }
    })
  }

  function handleSearchLands(page) {
    setCurrentLandPage(1)
    getUserLandScan(page, 6, searchBarValue).then((result) => {
      let landscans = result.data.landscans
      if (landscans.length > 0) {
        // Load user data in context store
        setMappingsList(landscans)
        setNumberOfLandPages(result.data.numberOfPages)
      } else {
        setMappingsList(
          <div className="c-dialog --centered">
            <div className="c-dialog-main-title">
              No mapping is matching your search value
            </div>
          </div>
        )
      }
    })
  }

  const handleChangeSelectedMapping = (ribeyeMapId, landScanUuid, repositionData) => {
    if (!selectedRibeyeMapId.includes(ribeyeMapId)) {
      setSelectedRibeyeMapId((s) => [ribeyeMapId])
      setSelectedLandScanUuid((s) => [landScanUuid])
      setRepositionData((s) => repositionData)
    } else {
      setSelectedRibeyeMapId((s) => selectedRibeyeMapId.filter((single) => ribeyeMapId != single))
      setSelectedLandScanUuid((s) => selectedLandScanUuid.filter((single) => landScanUuid != single))
      setRepositionData((s) => null)
    }
  }

  function onConfirm() {
    const publishState = { selectedRibeyeMapId, selectedLandScanUuid, repositionData }
    if (selectedRibeyeMapId) {
      props.onConfirm(publishState)
    } else {
      alert('You have to bind your project to a land. Select an OVRLand.')
    }
  }

  function RenderCreatedAt(obj) {
    if (obj.completedAt) {
      const d = new Date(obj.completedAt);
      if (d.toString() == 'Invalid Date')
        return null;
      return <div className='created-at'>{d.toLocaleDateString()}</div>;
    }
    return null;
  }

  return (
    <Dialog
      tag={props.tag}
      title={props.title}
      cancelLabel={props.cancelLabel}
      confirmLabel={props.confirmLabel}
      onConfirm={onConfirm}
      onCancel={props.onCancel}
    >
      <>
        <div className="d-select-land">
          <div>Select the mapping you want to be imported in the project:</div>
          {/* <div className="o-search-bar-holder">
            <TextField
              type="text"
              placeholder="Search"
              onChange={(e) => {
                setSearchBarValue(e.target.value)
              }}
            />
            <div
              className="cLmyBK"
              onClick={() => {
                handleSearchLands()
              }}
            >
            </div>
          </div> */}
          {mappingsList instanceof Array && mappingsList.length > 0 ?
            <div className="o-mappings-list">
              {mappingsList.map(obj => (
                <MappingCard
                  onClick={() => { handleChangeSelectedMapping(obj?.ribeyeMapId, obj?.uuid, obj?.repositionData) }}
                  className={selectedRibeyeMapId.includes(obj?.ribeyeMapId) ? '--selected' : ''}
                >
                  <div className="selected-overlay">
                    <CheckCircleIcon></CheckCircleIcon>
                  </div>
                  <div className='image'>
                    <img src={obj.imageUrl || placeholder} />
                  </div>
                  <div className='name'>
                    {RenderCreatedAt(obj)}
                    {obj.sentenceId}
                  </div>
                </MappingCard>
              ))}
            </div>
            : isLoading ? <div style={{ marginTop: '20px' }}><ProgressBar /></div> : mappingsList}
          <div className="o-pagination">
            {numberOfLandPages > 1 && (
              <Pagination
                count={numberOfLandPages}
                page={currentLandPage}
                onChange={handleLandPageClick}
              />
            )}
          </div>
        </div>
      </>
    </Dialog>
  )
}
