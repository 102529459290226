import React, { useEffect, useState } from "react";
import * as R from "ramda";
import styled from "styled-components";

import NavBar from "../navigation/NavBar";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import SvgIcon from "@material-ui/core/SvgIcon";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import HexButton from "../ovr/HexButton/HexButton";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Pagination from "@material-ui/lab/Pagination";

import { getUserLandScan } from "../../api/lib/api";

import buttonImage from "../../assets/0_pointcloud.png";
import buttonImage2 from "../../assets/RemoveRedEyeFilled.png";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const displayDate = toFormat => {
  if (R.isNil(toFormat) || R.isEmpty(toFormat)) return "Never";
  const d = new Date(toFormat);
  const year = d.getFullYear(); // 2019
  const date = d.getDate(); // 23
  const monthIndex = d.getMonth();
  const monthName = months[monthIndex];
  const dayIndex = d.getDay();
  const hours = d.getHours();
  const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();

  return `${date} ${monthName} ${year} ${hours}:${minutes}`;
};

const LandScan = props => {
  const [landScan, setLandScan] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const getLandScan = (number, perPage) => {
    getUserLandScan(number, perPage)
      .then(res => {
        console.log(res);
        const tokens = R.pathOr([], ["data", "landscans"], res);
        const totalPages = R.pathOr([], ["data", "totalPages"], res);
        const currentPage = R.pathOr([], ["data", "currentPage"], res);
        setCurrentPage(currentPage);
        setNumberOfPages(totalPages);
        console.log("currentPage", currentPage);
        console.log("totalPages", totalPages);
        setLandScan(tokens);
      })
      .catch(err => {});
  };

  useEffect(() => {
    getLandScan(1, 6);
  }, []);

  function handlePageClick(event, number) {
    getLandScan(number, 6);
    // if there is no search value load page
    // if (searchBarValue == '') {
    //   if (number > 1) {
    //     loadLandsByPage(number, 6)
    //   } else {
    //     loadLandsByPage(number, 5)
    //   }
    // } else {
    //   handleSearchLands(number)
    // }
  }

  function getStatus(status) {
    if (status == 0) {
      return "pending";
    }
    if (status == 1) {
      return "ready_glb";
    }
    if (status == 2) {
      return "ready_sqlite";
    }
    if (status == 3) {
      return "completed";
    }
    if (status == 99) {
      return "running";
    }
    if (status == -1) {
      return "deleted";
    }
  }

  return (
    <>
      <NavBar />
      <Container className="p-land-scan">
        <PageHeader>
          <h1>OVRMaps</h1>
          <p>
            Here you can download 3D assets of the Mapping to use through Unity SDK, that you previously did on
            OVERApp’s Map2Earn
          </p>
        </PageHeader>
        <StyledTable aria-label="simple table" className="">
          <TableHead>
            <TableRow>
              <TableCell>UUID</TableCell>
              <TableCell align="left">Hex ID</TableCell>
              <TableCell align="left">Sentence ID</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Created At</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {landScan instanceof Array
              ? landScan.map((row, key) => (
                  <>
                    <TableRow key={row?.uuid}>
                      <TableCell component="th" scope="row">
                        {row?.uuid}
                      </TableCell>
                      <TableCell>{row?.hexId}</TableCell>
                      <TableCell>{row?.sentenceId}</TableCell>
                      <TableCell className="o-status">{getStatus(row?.status)}</TableCell>
                      <TableCell>{displayDate(row?.createdAt)}</TableCell>
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          {row?.attachments?.ply !== null ? (
                            <a
                              href={row?.attachments?.ply}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              <Button
                                variant="contained"
                                color="default"
                                size="small"
                                style={{
                                  borderRadius: 30,
                                  fontFamily: "'Work Sans', sans-serif!important",
                                  padding: "6px 20px",
                                  textTransform: "CAPITALIZE",
                                  backgroundColor: "white",
                                  color: "#33C7B8",
                                  margin: 2
                                }}
                                startIcon={<img src={buttonImage} style={{ width: 20 }} />}
                              >
                                Mapping
                              </Button>
                            </a>
                          ) : (
                            <Button
                              disabled
                              variant="contained"
                              color="default"
                              size="small"
                              style={{
                                borderRadius: 30,
                                fontFamily: "'Work Sans', sans-serif!important",
                                padding: "6px 20px",
                                textTransform: "CAPITALIZE",
                                margin: 2
                              }}
                              startIcon={<DeleteOutlineIcon />}
                            >
                              Mapping
                            </Button>
                          )}

                          {row?.attachments?.nerf !== null ? (
                            <a
                              href={row?.attachments?.nerf}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              <Button
                                variant="contained"
                                color="default"
                                size="small"
                                style={{
                                  borderRadius: 30,
                                  fontFamily: "'Work Sans', sans-serif!important",
                                  padding: "6px 20px",
                                  textTransform: "CAPITALIZE",
                                  backgroundColor: "white",
                                  color: "#33C7B8",
                                  margin: 2
                                }}
                                startIcon={<img src={buttonImage2} style={{ width: 20 }} />}
                              >
                                FlyThrough
                              </Button>
                            </a>
                          ) : (
                            <Button
                              disabled
                              variant="contained"
                              color="default"
                              size="small"
                              style={{
                                borderRadius: 30,
                                fontFamily: "'Work Sans', sans-serif!important",
                                padding: "6px 20px",
                                textTransform: "CAPITALIZE",
                                margin: 2
                              }}
                              startIcon={<DeleteOutlineIcon />}
                            >
                              FlyThrough
                            </Button>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                    <tr className="spacer"></tr>
                  </>
                ))
              : landScan}
          </TableBody>
        </StyledTable>
        <Pagination
          count={numberOfPages}
          page={currentPage}
          onChange={handlePageClick}
          style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
        />
      </Container>
    </>
  );
};

const Container = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
  width: 100%;

  .HexButton {
    margin-right: 5px;
  }

  .green {
    background-color: white;
    color: #33c7b8;
  }
`;

const PageHeader = styled.div`
  padding: 80px 0;
  text-align: center;
  h1 {
    font-size: 53px;
    font-weight: 600;
  }
  p {
    font-size: 20px;
    width: 60%;
    margin: 15px auto;
  }

  .MuiButton-root {
    font-family: "Work Sans", sans-serif !important;
  }
`;

const StyledTable = styled(Table)`
  margin-top: 50px;

  .MuiTableCell-head {
    color: white;
    font-weight: 600;
  }

  .MuiTableCell-body {
    color: white;
  }

  .MuiTableCell-root {
    border-bottom: none;
  }

  .spacer {
    height: 10px;
  }

  tbody {
    .MuiTableRow-root {
      margin-bottom: 10px;
      background: #33c7b8;
      border-radius: 3px;
    }
  }
`;

const Nonce = styled.span`
  font-size: 11px;
  font-family: monospace;
  letter-spacing: 1px;
  background: #422f6e;
  padding: 3px 5px;
  border-radius: 4px;
`;

export default LandScan;
