import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import configs from "../configs";
import styled from "styled-components";
import Input from "../ui/inputs/Input";
import FacebookLoginWithButton from "../facebook/facebook-with-button";

import HexButton from "../ui/ovr/HexButton/HexButton";
import { Web3Context, withWeb3Context } from '../ui/contexts/Web3Context'
import { UserContext, withUserContext } from '../ui/contexts/UserContext'
import { useHistory } from 'react-router-dom'
import { PRIVACY, TERMS } from "../constants";
// import { GoogleLogin } from "react-google-login";

const StyledAuthForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 400px;
  align-self: center;

  & > * {
    margin-bottom: 20px;
  }

  button {
    display: inline-block;
    border: none;
    border-radius: 4px;
    background: ${props => props.theme.blue};
    color: ${props => props.theme.white};
    white-space: nowrap;
    min-height: 36px;
    font-size: 16px;
    padding: 1px 6px;

    &:hover,
    &:active {
      background-color: ${props => props.theme.bluePressed};
    }
  }

  h3 {
    font-size: 2em;
    color: ${props => props.theme.text};
  }

  h4 {
    font-size: 1.1em;
    color: ${props => props.theme.text};
  }
`;

const FormInput = styled(Input)`
  font-size: 20px;
  padding: 8px;
  height: 36px;
`;

const ErrorMessage = styled.p`
  color: ${props => props.theme.red};
  margin-bottom: 20px;
`;

const LegalText = styled.p`
  margin-bottom: 20px;
`;

const AuthForm = (props) => {

  let history = useHistory()
  const { state: userState, actions: userActions } = useContext(UserContext);
  const { state: web3State, actions: web3Actions } = useContext(Web3Context);

  const [email, setEmail] = React.useState("");
  const [id, setId] = React.useState("");
  const [name, setName] = React.useState("");

  const handleMetamaskAuthentication = () => {
    web3Actions.setupWeb3((res) => {  
      // if (res == false) {
      //   history.push('/projects')
      // } else {
      //   history.push('/projects')
      // }
      //props.onSubmit(email)
      props.onSubmit('this.state.accessToken', "facebook");
    })
  }


  const onSubmit = (e) => {
    e.preventDefault();
    // this.props.onSubmit(email);
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };


  return (
    <StyledAuthForm onSubmit={() => {onSubmit}}>
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
      <h3>Register or Login</h3>
      <h4>
        We use Web3 as provider for login, please use Metamask or ImToken wallet
      </h4>
      {/* <h4>Login to save projects and publish scenes{configs.isMoz() && " to Hubs"}.</h4> */}
      <HexButton
        url="#"
        text={"Connect Wallet"}
        className={`--orange --wallet-button`}
        onClick={(e) => handleMetamaskAuthentication()}
      ></HexButton>
      
      {/* <FormInput
        type="email"
        name="email"
        placeholder="Email"
        value={this.state.email}
        onChange={this.onEmailChange}
      /> */}
      <LegalText>
        By proceeding, you agree to the{" "}
        <a rel="noopener noreferrer" target="_blank" href={TERMS}>
          terms of use
        </a>{" "}
        and{" "}
        <a rel="noopener noreferrer" target="_blank" href={PRIVACY}>
          privacy notice
        </a>
        .
      </LegalText>
      {/* <button type="submit">Send Magic Link</button> */}
    </StyledAuthForm>
  );

}


export default withUserContext(withWeb3Context(AuthForm))
