import React from 'react'
import LandName from '../LandName/LandName'
import { Link } from 'react-router-dom'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Textfit } from 'react-textfit'

const SandBoxLandCard = (props) => {
  const {
    className,
    customOnClick
  } = props

  const handleClick = () => { 
    customOnClick('is_workspace', 'is_workspace')
  } 

  return (
    <div className={`LandCard ${className}`} onClick={handleClick}>
      <div className="selected-overlay">
        <CheckCircleIcon></CheckCircleIcon>
      </div>
      <div>
        <div className="LandCard__header LandCard__header--SandBox">
        <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1080"
      height="1080"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 1080 1080"
      xmlSpace="preserve"
    >
      <switch>
        <g>
          <g
            fill="none"
            stroke="#2A2266"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="10"
          >
            <path d="M545.997 48.151a104.006 104.006 0 00-5.998-.173v6"></path>
            <path
              strokeDasharray="12.248004,20.4133396"
              d="M539.9994507 74.3912964L539.9994507 414.2858582 539.9994507 652.0888062"
            ></path>
            <path d="M539.9994507 662.2954712L539.9994507 668.2954712 545.5336304 670.6135864"></path>
            <path
              strokeDasharray="12.3376923,20.5628204"
              d="M564.5 678.558L949.514 839.828"
            ></path>
            <path d="M958.997 843.8l5.534 2.318a104.11 104.11 0 003.353-4.975"></path>
            <path
              strokeDasharray="11.9973021,19.9955044"
              d="M976.895 823.33A103.787 103.787 0 00984 785.552V294.368c0-22.31-7.134-43.459-19.638-60.801a103.827 103.827 0 00-44.155-35.084l-132.472-55.488L580.163 56.05a103.922 103.922 0 00-24.213-6.842"
            ></path>
          </g>
          <g
            fill="none"
            stroke="#2A2266"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="10"
          >
            <path d="M112.116 841.143a104.18 104.18 0 003.353 4.975l5.534-2.318"></path>
            <path
              strokeDasharray="12.3376551,20.5627594"
              d="M139.969 835.855L524.982 674.586"
            ></path>
            <path d="M534.465332 670.6135864L539.9994507 668.2954712 539.9994507 662.2954712"></path>
            <path
              strokeDasharray="12.248004,20.4133396"
              d="M539.9994507 641.8821411L539.9994507 414.2858582 539.9994507 64.1846237"
            ></path>
            <path d="M540 53.978v-6c-2 0-4 .058-5.998.173"></path>
            <path
              strokeDasharray="11.9972944,19.995491"
              d="M514.275 51.21a103.967 103.967 0 00-14.438 4.84L171.154 193.724l-11.36 4.759a103.829 103.829 0 00-44.157 35.084A103.87 103.87 0 0096 294.367v491.186a103.79 103.79 0 0011.182 46.899"
            ></path>
          </g>
          <path
            fill="none"
            stroke="#2A2266"
            strokeDasharray="12,20"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="10"
            d="M849.727 304.278c-8.01-11.724-19.042-21.19-32.227-27.018l-96.686-42.732-151.5-66.957c-9.377-4.144-19.345-6.216-29.314-6.216s-19.937 2.072-29.314 6.216L270.792 273.595l-8.291 3.665c-13.185 5.827-24.217 15.293-32.229 27.018-9.125 13.356-14.332 29.642-14.332 46.823v378.265c0 17.1 5.159 33.317 14.21 46.642 7.935 11.682 18.863 21.14 31.936 27.015l199.63 89.701 48.556 21.819c9.496 4.267 19.613 6.4 29.728 6.4 10.116 0 20.231-2.133 29.728-6.4l33.799-15.187 214.387-96.333c13.073-5.874 24.001-15.333 31.937-27.015 9.05-13.325 14.209-29.542 14.209-46.642V351.101c0-17.181-5.207-33.467-14.333-46.823z"
          ></path>
          <g
            fill="none"
            stroke="#2A2266"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="10"
          >
            <path d="M119.284 228.804a104.174 104.174 0 00-3.647 4.763l5.52 2.35"></path>
            <path
              strokeDasharray="12.3644199,20.6073666"
              d="M140.117 243.992L525 407.898"
            ></path>
            <path d="M534.4796143 411.9349365L539.999939 414.2857971 545.5202026 411.9349365"></path>
            <path
              strokeDasharray="12.3644199,20.6073666"
              d="M564.48 403.861L949.362 239.955"
            ></path>
            <path d="M958.842 235.918l5.52-2.351a104.198 104.198 0 00-3.646-4.763"></path>
            <path
              strokeDasharray="12.0786963,20.1311607"
              d="M946.595 214.502a103.82 103.82 0 00-26.388-16.019l-132.472-55.488L580.163 56.05A103.934 103.934 0 00540 47.978c-13.658 0-27.316 2.69-40.163 8.072L171.154 193.725l-11.36 4.758a103.783 103.783 0 00-33.792 22.824"
            ></path>
          </g>
          <g
            fill="none"
            stroke="#2A2266"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="10"
          >
            <path d="M967.73 238.532a104.215 104.215 0 00-3.368-4.965l-5.52 2.35"></path>
            <path
              strokeDasharray="12.3644199,20.6073666"
              d="M939.882 243.992L555 407.898"
            ></path>
            <path d="M545.5202026 411.9349365L539.999939 414.2857971 539.999939 420.2857971"></path>
            <path
              strokeDasharray="12.2423096,20.4038486"
              d="M539.999939 440.6896362L539.999939 668.2956543 539.999939 1018.1185303"
            ></path>
            <path d="M540 1028.32v6c2 0 4-.057 5.997-.173"></path>
            <path
              strokeDasharray="12.0108528,20.0180874"
              d="M565.746 1031.082a103.946 103.946 0 0014.985-5.073l46.308-19.72 293.736-125.091a103.832 103.832 0 0043.757-35.08A103.87 103.87 0 00984 785.553V294.368c0-16.743-4.018-32.832-11.307-47.148"
            ></path>
          </g>
          <circle
            cx="540"
            cy="539.689"
            r="209.276"
            fill="none"
            stroke="#2A2266"
            strokeDasharray="12,20"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="10"
          ></circle>
          <path
            fill="none"
            stroke="#2A2266"
            strokeMiterlimit="10"
            strokeWidth="18"
            d="M964.362 233.567a104.13 104.13 0 00-6.814-8.473 103.749 103.749 0 00-37.34-26.61l-107.813-45.16-24.66-10.329L580.163 56.05A103.932 103.932 0 00540 47.978c-13.658 0-27.316 2.69-40.163 8.072L171.154 193.724l-2.765 1.159-8.596 3.6c-2.09.875-4.135 1.82-6.145 2.82-.161.08-.327.154-.488.235-.213.108-.42.224-.631.332a103.83 103.83 0 00-36.892 31.697A103.87 103.87 0 0096 294.367v491.186c0 22.204 7.068 43.263 19.47 60.565a103.825 103.825 0 0043.756 35.08l273.515 116.48 66.528 28.33a103.937 103.937 0 0040.73 8.312s0 0 0 0v0c11.865 0 23.728-2.037 35.06-6.097a103.917 103.917 0 005.672-2.214l6.95-2.96 39.358-16.76 293.736-125.091a103.832 103.832 0 0043.757-35.08A103.87 103.87 0 00984 785.553V294.368c0-22.31-7.134-43.459-19.638-60.801z"
          ></path>
          <path
            fill="none"
            stroke="#2A2266"
            strokeMiterlimit="10"
            strokeWidth="18"
            d="M116 234L540 414 964 234"
          ></path>
          <path
            fill="none"
            stroke="#2A2266"
            strokeMiterlimit="10"
            strokeWidth="18"
            d="M540 1034L540 414"
          ></path>
        </g>
      </switch>
    </svg>
        </div>
      </div>
      <div className="LandCard__body">     
        <div className="LandName">
            <div className="LandName__name">
                <h2>  
                    <Textfit mode="single" max={14}>
                    OVR Workspace
                    </Textfit>
                </h2>
            </div>
        </div>
        <h2 className="--nft-id">It's a test environment that you can use to view content from your App account.</h2>
      </div>
    </div>
  )
}

export default SandBoxLandCard
