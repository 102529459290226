import galleryTemplateUrl from "../../assets/templates/gallery.spoke";
import galleryTemplateThumbnail from "../../assets/templates/gallery.jpg";

import showroomTemplateUrl from "../../assets/templates/showroom.spoke";
import showroomTemplateThumbnail from "../../assets/templates/showroom.jpg";

import storeTemplateUrl from "../../assets/templates/store.spoke";
import storeTemplateThumbnail from "../../assets/templates/store.jpg";

const templates = [
    {
        project_id: "OVRGallery",
        name: "OVR Gallery",
        project_thumbnail: galleryTemplateThumbnail,
        project_url: galleryTemplateUrl,
    },
    {
        project_id: "OVRShowroom",
        name: "OVR Showroom",
        project_thumbnail: showroomTemplateThumbnail,
        project_url: showroomTemplateUrl,
    },
    {
        project_id: "OVRStore",
        name: "OVR Store",
        project_thumbnail: storeTemplateThumbnail,
        project_url: storeTemplateUrl,
    }
];

function transformUrls(templates) {
    const searchParams = new URLSearchParams();

    for (const template of templates) {
        searchParams.set("template", template.project_url);
        template.url = "/projects/new?" + searchParams;
    }

    return templates;
}

export default transformUrls(templates);
