/* eslint-disable react/prop-types */
import React, { useState, useContext } from "react";
import * as R from "ramda";
import { Formik } from "formik";
import { Link, useHistory } from "react-router-dom";
import configs from "../configs";
import styled from "styled-components";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { FieldInput } from "./fields";
import { Collapse, Divider, LinearProgress } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { UserContext } from "./contexts/UserContext";
import { Web3Context } from "./contexts/Web3Context";
import { warningNotification, successNotification } from "../api/lib/notifications";

import { signInOffChain, signUp } from "../api/lib/api";
import UserImage from "./UserImage";

export const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[-.@#${'$'}%!\\-_?&]).{8,}$/;

// upper case, lower case, digits
export const usernameRegex = /^[a-zA-Z0-9]+$/;

export const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const registerValidation = values => {
  const errors = {};

  if (!usernameRegex.test(values.username) && !R.isEmpty(values.username) && !R.isNil(values.username)) {
    errors.username = "Only letters and numbers are allowed for the username";
  }

  if (!emailRegex.test(values.email) && !R.isEmpty(values.email) && !R.isNil(values.email)) {
    errors.email = "Not valid email";
  }

  if (!passwordRegex.test(values.password) && !R.isEmpty(values.password) && !R.isNil(values.password)) {
    errors.password =
      "Min 8 chars, at least: one digit, one lower case, one upper case, one special char ( . _ ! ' $ % & ^ @ # - ).";
  }

  if (values.password !== values.repeat_password && !R.isEmpty(values.repeat_password) && !R.isEmpty(values.password)) {
    errors.repeat_password = "The two passwords do not match";
  }
  return errors;
};

const AppBar = () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { state: userState, actions: userActions } = useContext(UserContext);
  const { state: web3State, actions: web3Actions } = useContext(Web3Context);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [offChainLoginLoading, setOffChainLoginLoading] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);

  const connectedWallet = R.pathOr(null, ["address"], web3State);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loginOffchain = values => {
    const username = values.username;
    const password = values.password;
    console.debug("loginOffchain", { username, password });

    if (R.isEmpty(username) || R.isEmpty(password)) {
      warningNotification("Error", "Please fill all the fields");
    } else {
      // SIGNIN
      setOffChainLoginLoading(true);
      signInOffChain(username, password).then(response => {
        console.debug("handleSignin.response", response);
        const {
          data: { result, token, user }
        } = response;
        if (result) {
          userActions.loginUser(token, user, "centralized");
          setDialogOpen(false);
          successNotification("Success", "You have logged in");
        } else {
          const errorMessage = R.path(["data", "errors", 0, "message"], response);
          warningNotification("Error", errorMessage);
          setDialogOpen(false);
        }

        setOffChainLoginLoading(false);
      });
    }
  };

  const register = values => {
    const username = values.username;
    const email = values.email;
    const password = values.password;

    const errors = {};

    if (!R.isEmpty(errors)) {
      warningNotification("Error", errors);
    } else {
      // SIGNIN
      setRegisterLoading(true);
      signUp(username, email, password).then(response => {
        console.debug("handleSignin.response", response);
        const {
          data: { result, token, user }
        } = response;
        if (result) {
          userActions.loginUser(token, user, "centralized");
          setDialogOpen(false);
          successNotification("Success", "You have logged in");
        } else {
          const errorMessage = R.path(["data", "errors", 0, "message"], response);
          warningNotification("Error", errorMessage);
          setDialogOpen(false);
        }

        setRegisterLoading(false);
      });
    }
  };

  const handleMetamaskAuthentication = () => {
    web3Actions.setupWeb3(res => {
      console.log("web3Navbar", res);
      setDialogOpen(false);
    });
  };

  const logout = () => {
    handleClose();
    history.push("/logout");
  };

  return (
    <StyledAppBar position="fixed" sx={{ paddingLeft: { xs: "0px", sm: "0px" } }}>
      <Toolbar>
        <div className="left-side">
          <Link to="/" style={{ display: "flex" }}>
            <img src={configs.icon()} alt={configs.name()} style={{ width: 100 }} />
          </Link>
        </div>

        <div className="right-side">
          {userState.isLoggedIn ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div className="user-info">
                {R.isNil(connectedWallet) ? (
                  <StyledButton
                    className="button"
                    variant="contained"
                    color="secondary"
                    size="small"
                    style={{ backgroundColor: "#e4caef", color: "black" }}
                    startIcon={<AccountBalanceWalletIcon />}
                  >
                    Connect Wallet
                  </StyledButton>
                ) : (
                  <>
                    <Typography
                      variant="overline"
                      className="wallet-address"
                      style={{
                        marginLeft: 15,
                        letterSpacing: 1,
                        fontSize: 14,
                        fontFamily: "'Work Sans', sans-serif",
                        fontWeight: 400
                      }}
                    >
                      {R.join("…", [R.take(5, connectedWallet), R.takeLast(4, connectedWallet)])}
                    </Typography>
                    <ChainViewer className="chainViewer" chainId={web3State?.chainId} />
                  </>
                )}

                <UserImage
                  isNft={R.pathOr(false, ["user", "isProfilePicNft"], userState)}
                  user={userState?.user}
                  onClick={handleClick}
                  style={{ cursor: "pointer" }}
                  overrideNftClipBackground="white"
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                >
                  <div className="heading">
                    <div
                      className="profile"
                      style={{
                        minWidth: 340,
                        display: "flex",
                        alignItems: "center",
                        padding: "8px 15px",
                        marginBottom: 8
                      }}
                    >
                      <UserImage
                        isNft={R.pathOr(false, ["user", "isProfilePicNft"], userState)}
                        user={userState?.user}
                        onClick={handleClick}
                        style={{ cursor: "pointer" }}
                        overrideNftClipBackground="#424242"
                      />
                      <Typography
                        style={{
                          fontFamily: "'Work Sans', sans-serif",
                          fontWeight: 400,
                          paddingLeft: 10
                        }}
                      >
                        {userState?.user?.username}
                      </Typography>
                    </div>
                    <Divider />
                  </div>
                  <a
                    href="https://marketplace.ovr.ai/profile"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <MenuItem>
                      <ListItemIcon>
                        <AccountCircleIcon />
                      </ListItemIcon>
                      <Typography
                        style={{
                          fontFamily: "'Work Sans', sans-serif",
                          fontWeight: 400,
                          textDecoration: "none"
                        }}
                      >
                        Profile
                      </Typography>
                    </MenuItem>
                  </a>

                  <MenuItem onClick={logout}>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <Typography
                      style={{
                        fontFamily: "'Work Sans', sans-serif",
                        fontWeight: 400
                      }}
                    >
                      Logout
                    </Typography>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          ) : (
            <StyledButton
              style={{ backgroundColor: "#e4caef", color: "black" }}
              variant="contained"
              color="primary"
              onClick={() => setDialogOpen(true)}
            >
              Sign In
            </StyledButton>
          )}

          <DialogSignin
            open={dialogOpen}
            handleClose={() => setDialogOpen(false)}
            loginOffchain={loginOffchain}
            register={register}
            offChainLoginLoading={offChainLoginLoading}
            registerLoading={registerLoading}
            handleMetamaskAuthentication={handleMetamaskAuthentication}
          />
        </div>
      </Toolbar>
    </StyledAppBar>
  );
};

const StyledAppBar = styled(MuiAppBar)`
  background-image: none;
  background-color: #2a2266 !important;
  box-shadow: none;
  border-bottom: 1px solid 222222;
  height: 50px;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.12); */
  .MuiToolbar-root {
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
  .MuiDialogContent-root:first-child {
    padding-top: 0px !important;
  }

  .user-info {
    box-sizing: content-box;
    background-color: rgb(255, 255, 255);
    background-image: none;
    display: flex;
    column-gap: 7px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px;
    height: 33px;
    border-radius: 100px;
    overflow: hidden;
    margin-right: 10px;
    border: 1px solid rgb(255, 255, 255);
    color: #1e1e1e;
    @media screen and (max-width: 600px) {
      margin-right: 0;
    }

    .chainViewer {
      @media screen and (max-width: 600px) {
        margin-right: 0px !important;
        margin-left: 0px !important;
      }
      span {
        @media screen and (max-width: 600px) {
          font-size: 12px !important;
        }
      }

      svg {
        @media screen and (max-width: 600px) {
          height: 17px !important;
          width: 17px !important;
        }
      }
    }
  }
`;

const StyledButton = styled(Button)`
  border-radius: 30px !important;
  padding: 4px 20px !important;
  font-size: 0.875rem !important;
  font-family: "Work Sans", sans-serif !important;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
`;

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
    paddingTop: "0!important"
  }
}))(MuiDialogContent);

const DialogSignin = ({
  open,
  handleClose,
  loginOffchain,
  offChainLoginLoading,
  register,
  registerLoading,
  handleMetamaskAuthentication
}) => {
  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={"xs"}>
      <DialogContent>
        <LoginOnChain handleClose={handleClose} handleMetamaskAuthentication={handleMetamaskAuthentication} />
        <LoginOffChain
          loginOffchain={loginOffchain}
          offChainLoginLoading={offChainLoginLoading}
          registerLoading={registerLoading}
          register={register}
        />
      </DialogContent>
    </Dialog>
  );
};

const LoginOnChain = ({ handleClose, handleMetamaskAuthentication }) => {
  return (
    <div
      className="login-on-chain"
      style={{ padding: 20, backgroundColor: "rgb(71, 35, 166)", height: 150, color: "white", position: "relative" }}
    >
      <IconButton aria-label="close" style={{ position: "absolute", right: 13, top: 13 }}>
        <CloseIcon style={{ color: "white" }} onClick={handleClose} />
      </IconButton>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div className="content connect-wallet">
            <Typography
              variant="h6"
              style={{
                fontFamily: "'Work Sans', sans-serif",
                textAlign: "center",
                marginBottom: 30,
                color: "white",
                fontSize: "16px",
                fontWeight: 300,
                letterSpacing: "0.5px"
              }}
            >
              ON CHAIN LOGIN
            </Typography>
            <StyledButton
              id="gtag-dialog-signin-button-connectwallet"
              className="connect-wallet-button"
              startIcon={<AccountBalanceWalletIcon />}
              style={{ backgroundColor: "white", color: "black", fontFamily: "'Work Sans', sans-serif" }}
              onClick={handleMetamaskAuthentication}
            >
              CONNECT WALLET
            </StyledButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const LoginOffChain = ({ loginOffchain, offChainLoginLoading, register, registerLoading }) => {
  const [registerFormVisibile, setRegisterFormVisibile] = useState(false);

  return (
    <div className="login-off-chain" style={{ padding: "20px 45px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div className="content connect-wallet">
            <Typography
              variant="h6"
              style={{
                fontFamily: "'Work Sans', sans-serif",
                textAlign: "center",
                marginBottom: 10,
                color: "white",
                fontSize: "16px",
                fontWeight: 300,
                letterSpacing: "0.5px"
              }}
            >
              OFF CHAIN LOGIN
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Formik key="login-on-chain" initialValues={{ username: "", password: "" }} onSubmit={loginOffchain}>
            {formikProps => {
              return (
                <form onSubmit={formikProps.handleSubmit}>
                  <FieldInput
                    value={formikProps.values.username}
                    placeholder="Username"
                    className="appbar-field-input"
                    name="username"
                    type="text"
                    setFieldValue={formikProps.setFieldValue}
                    onChange={formikProps.handleChange}
                  />
                  <FieldInput
                    value={formikProps.values.password}
                    placeholder="Password"
                    className="appbar-field-input"
                    name="password"
                    type="password"
                    setFieldValue={formikProps.setFieldValue}
                    onChange={formikProps.handleChange}
                  />

                  <StyledButton
                    type="submit"
                    disabled={offChainLoginLoading}
                    style={{
                      backgroundColor: "#e4caef",
                      boxShadow:
                        "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px",
                      color: "black",
                      fontFamily: "'Work Sans', sans-serif",
                      width: "100%"
                    }}
                  >
                    LOGIN
                    {offChainLoginLoading ? (
                      <LinearProgress style={{ position: "absolute", bottom: -1, width: "100%" }} />
                    ) : null}
                  </StyledButton>
                </form>
              );
            }}
          </Formik>

          <Divider style={{ marginTop: 20, marginBottom: 20, backgroundColor: "rgba(255, 255, 255, 0.12)" }} />
          <Typography
            variant="h6"
            style={{
              fontFamily: "'Work Sans', sans-serif",
              textAlign: "center",
              marginBottom: 10,
              color: "white",
              fontSize: "16px",
              fontWeight: 300,
              letterSpacing: "0.5px"
            }}
          >
            Not a member?
          </Typography>
          <Collapse in={registerFormVisibile}>
            <Formik
              key="register"
              validate={registerValidation}
              initialValues={{ username: "", email: "", password: "", repeat_password: "" }}
              onSubmit={register}
            >
              {formikProps => {
                return (
                  <form onSubmit={formikProps.handleSubmit}>
                    <FieldInput
                      value={formikProps.values.username}
                      placeholder="Username"
                      className="appbar-field-input"
                      name="username"
                      setFieldValue={formikProps.setFieldValue}
                      onChange={formikProps.handleChange}
                      helperText={formikProps.errors.username}
                    />
                    <FieldInput
                      value={formikProps.values.email}
                      placeholder="Email"
                      className="appbar-field-input"
                      name="email"
                      setFieldValue={formikProps.setFieldValue}
                      onChange={formikProps.handleChange}
                      helperText={formikProps.errors.email}
                    />
                    <FieldInput
                      value={formikProps.values.password}
                      placeholder="Password"
                      className="appbar-field-input"
                      name="password"
                      type="password"
                      setFieldValue={formikProps.setFieldValue}
                      onChange={formikProps.handleChange}
                      helperText={formikProps.errors.password}
                    />
                    <FieldInput
                      value={formikProps.values.repeat_password}
                      placeholder="Repeat Password"
                      className="appbar-field-input"
                      name="repeat_password"
                      type="password"
                      setFieldValue={formikProps.setFieldValue}
                      onChange={formikProps.handleChange}
                      helperText={formikProps.errors.repeat_password}
                    />

                    <StyledButton
                      type="submit"
                      onClick={() => setRegisterFormVisibile(true)}
                      disabled={registerLoading}
                      style={{
                        backgroundColor: "#4723a6",
                        boxShadow:
                          "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px",
                        color: "white",
                        fontFamily: "'Work Sans', sans-serif",
                        width: "100%"
                      }}
                    >
                      REGISTER
                      {registerLoading ? (
                        <LinearProgress style={{ position: "absolute", bottom: -1, width: "100%" }} />
                      ) : null}
                    </StyledButton>
                  </form>
                );
              }}
            </Formik>
          </Collapse>
          <Collapse in={!registerFormVisibile}>
            <StyledButton
              onClick={() => setRegisterFormVisibile(true)}
              style={{
                backgroundColor: "#4723a6",
                boxShadow:
                  "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px",
                color: "white",
                fontFamily: "'Work Sans', sans-serif",
                width: "100%"
              }}
            >
              REGISTER
            </StyledButton>
          </Collapse>

          <Divider style={{ marginTop: 20, marginBottom: 20, backgroundColor: "rgba(255, 255, 255, 0.12)" }} />

          <div className="social-login" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <a
              href="https://marketplace.ovr.ai/social-login?app=builder&provider=google"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                className="social-button"
                size="large"
                style={{ backgroundColor: "white", marginLeft: 8, marginRight: 8 }}
              >
                <svg viewBox="0 0 18 18" style={{ width: 20 }}>
                  <g fill="none" fillRule="evenodd">
                    <path
                      fill="#4285F4"
                      fillRule="nonzero"
                      d="M17.64 9.205c0-.639-.057-1.252-.164-1.841H9v3.481h4.844a4.14 4.14 0 0 1-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615Z"
                    />
                    <path
                      fill="#34A853"
                      fillRule="nonzero"
                      d="M9 18c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H.957v2.332A8.997 8.997 0 0 0 9 18Z"
                    />
                    <path
                      fill="#FBBC05"
                      fillRule="nonzero"
                      d="M3.964 10.71A5.41 5.41 0 0 1 3.682 9c0-.593.102-1.17.282-1.71V4.958H.957A8.996 8.996 0 0 0 0 9c0 1.452.348 2.827.957 4.042l3.007-2.332Z"
                    />
                    <path
                      fill="#EA4335"
                      fillRule="nonzero"
                      d="M9 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.463.891 11.426 0 9 0A8.997 8.997 0 0 0 .957 4.958L3.964 7.29C4.672 5.163 6.656 3.58 9 3.58Z"
                    />
                    <path d="M0 0h18v18H0z" />
                  </g>
                </svg>
              </IconButton>
            </a>

            <a
              href="https://marketplace.ovr.ai/social-login?app=builder&provider=apple"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                className="social-button"
                size="large"
                style={{ backgroundColor: "white", marginLeft: 8, marginRight: 8 }}
              >
                <svg style={{ width: 24 }} focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                  <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11z"></path>
                </svg>
              </IconButton>

              <a
                href="https://marketplace.ovr.ai/social-login?app=builder&provider=facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  className="social-button"
                  size="large"
                  style={{ backgroundColor: "white", marginLeft: 8, marginRight: 8 }}
                >
                  <svg
                    style={{ width: 24, fill: "rgb(66, 103, 178)" }}
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z"></path>
                  </svg>
                </IconButton>
              </a>
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const ChainViewer = ({ chainId, className }) => {
  if (chainId == 1) {
    return (
      <div className={className} style={{ display: "flex", alignItems: "center", marginRight: 5, marginLeft: 5 }}>
        <svg
          style={{ height: 23, width: 30 }}
          version="1.1"
          shapeRendering="geometricPrecision"
          textRendering="geometricPrecision"
          imageRendering="optimizeQuality"
          fillRule="evenodd"
          clipRule="evenodd"
          viewBox="0 0 784.37 1277.39"
        >
          <g id="Layer_x0020_1">
            <metadata id="CorelCorpID_0Corel-Layer" />
            <g id="_1421394342400">
              <g>
                <polygon
                  fill="#343434"
                  fillRule="nonzero"
                  points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54 "
                />
                <polygon fill="#8C8C8C" fillRule="nonzero" points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33 " />
                <polygon
                  fill="#3C3C3B"
                  fillRule="nonzero"
                  points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89 "
                />
                <polygon fill="#8C8C8C" fillRule="nonzero" points="392.07,1277.38 392.07,956.52 -0,724.89 " />
                <polygon fill="#141414" fillRule="nonzero" points="392.07,882.29 784.13,650.54 392.07,472.33 " />
                <polygon fill="#393939" fillRule="nonzero" points="0,650.54 392.07,882.29 392.07,472.33 " />
              </g>
            </g>
          </g>
        </svg>
        <span style={{ fontSize: 14, fontWeight: 400, paddingLeft: 5 }}>ETHEREUM</span>
      </div>
    );
  }

  if (chainId == 56) {
    return (
      <div style={{ display: "flex", alignItems: "center", marginRight: 5, marginLeft: 5 }}>
        <svg style={{ height: 23, width: 30 }} version="1.1" x="0px" y="0px" viewBox="0 0 2496 2496">
          <g>
            <path
              fill="#F0B90B"
              d="M1248,0c689.3,0,1248,558.7,1248,1248s-558.7,1248-1248,1248
		S0,1937.3,0,1248S558.7,0,1248,0L1248,0z"
            />
            <path
              d="M685.9,1248l0.9,330l280.4,165v193.2l-444.5-260.7v-524L685.9,1248L685.9,1248z M685.9,918v192.3
		l-163.3-96.6V821.4l163.3-96.6l164.1,96.6L685.9,918L685.9,918z M1084.3,821.4l163.3-96.6l164.1,96.6L1247.6,918L1084.3,821.4
		L1084.3,821.4z"
            />
            <path
              d="M803.9,1509.6v-193.2l163.3,96.6v192.3L803.9,1509.6L803.9,1509.6z M1084.3,1812.2l163.3,96.6
		l164.1-96.6v192.3l-164.1,96.6l-163.3-96.6V1812.2L1084.3,1812.2z M1645.9,821.4l163.3-96.6l164.1,96.6v192.3l-164.1,96.6V918
		L1645.9,821.4L1645.9,821.4L1645.9,821.4z M1809.2,1578l0.9-330l163.3-96.6v524l-444.5,260.7v-193.2L1809.2,1578L1809.2,1578
		L1809.2,1578z"
            />
            <polygon points="1692.1,1509.6 1528.8,1605.3 1528.8,1413 1692.1,1316.4 1692.1,1509.6 	" />
            <path
              d="M1692.1,986.4l0.9,193.2l-281.2,165v330.8l-163.3,95.7l-163.3-95.7v-330.8l-281.2-165V986.4
		L968,889.8l279.5,165.8l281.2-165.8l164.1,96.6H1692.1L1692.1,986.4z M803.9,656.5l443.7-261.6l444.5,261.6l-163.3,96.6
		l-281.2-165.8L967.2,753.1L803.9,656.5L803.9,656.5z"
            />
          </g>
        </svg>
        <span style={{ fontSize: 14, fontWeight: 400, paddingLeft: 5 }}>BSC</span>
      </div>
    );
  }

  if (chainId == 137) {
    return (
      <div style={{ display: "flex", alignItems: "center", marginRight: 5, marginLeft: 5 }}>
        <svg style={{ height: 19, width: 30 }} version="1.1" x="0px" y="0px" viewBox="0 0 38.4 33.5">
          <g>
            <path
              fill="#8247E5"
              d="M29,10.2c-0.7-0.4-1.6-0.4-2.4,0L21,13.5l-3.8,2.1l-5.5,3.3c-0.7,0.4-1.6,0.4-2.4,0L5,16.3
		c-0.7-0.4-1.2-1.2-1.2-2.1v-5c0-0.8,0.4-1.6,1.2-2.1l4.3-2.5c0.7-0.4,1.6-0.4,2.4,0L16,7.2c0.7,0.4,1.2,1.2,1.2,2.1v3.3l3.8-2.2V7
		c0-0.8-0.4-1.6-1.2-2.1l-8-4.7c-0.7-0.4-1.6-0.4-2.4,0L1.2,5C0.4,5.4,0,6.2,0,7v9.4c0,0.8,0.4,1.6,1.2,2.1l8.1,4.7
		c0.7,0.4,1.6,0.4,2.4,0l5.5-3.2l3.8-2.2l5.5-3.2c0.7-0.4,1.6-0.4,2.4,0l4.3,2.5c0.7,0.4,1.2,1.2,1.2,2.1v5c0,0.8-0.4,1.6-1.2,2.1
		L29,28.8c-0.7,0.4-1.6,0.4-2.4,0l-4.3-2.5c-0.7-0.4-1.2-1.2-1.2-2.1V21l-3.8,2.2v3.3c0,0.8,0.4,1.6,1.2,2.1l8.1,4.7
		c0.7,0.4,1.6,0.4,2.4,0l8.1-4.7c0.7-0.4,1.2-1.2,1.2-2.1V17c0-0.8-0.4-1.6-1.2-2.1L29,10.2z"
            />
          </g>
        </svg>
        <span style={{ fontSize: 14, fontWeight: 400, paddingLeft: 5 }}>POLYGON</span>
      </div>
    );
  }
};

export default AppBar;
