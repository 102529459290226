/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Dialog from "./Dialog";
import _ from "lodash";
import Cookies from "js-cookie";

import StringInput from "../inputs/StringInput";
import FormField from "../inputs/FormField";
import SelectInput from "../inputs/SelectInput";
import BooleanInput from "../inputs/BooleanInput";
import { Grid, Collapse, Divider } from "@material-ui/core";

const SubTitle = styled.span`
  text-align: left;
  padding-right: 0.5em;
  font-weight: bold;
  clear: both;
`;

const SubTitlePadd = styled.span`
  text-align: left;
  padding-right: 0.5em;
  padding-top: 50px;
  font-weight: bold;
  clear: both;
`;

const Content = styled.div`
  display: flex;
  width: 1200px;
  max-width: 1500px;
  border-top-left-radius: inherit;
  align-items: left;
  padding: 20px;
`;

export default function OverSettingDialog({ ...props }) {
  const [overDefualtLights, setOverDefualtLights] = useState(2);
  const [overArLights, setOverArLights] = useState(0);
  const [overCustomShadow, setOverCustomShadow] = useState(0);
  const [overShadowDistance, setOverShadowDistance] = useState(false);
  const [overShadowDistanceValue, setOverShadowDistanceValue] = useState(0);

  const [overProjection, setOverProjection] = useState(false);
  const [overProjectionValue, setOverProjectionValue] = useState(0);

  const [overResolution, setOverResolution] = useState(false);
  const [overResolutionValue, setOverResolutionValue] = useState(0);

  const [overGeolocatedAndroidOcclusion, setOverGeolocatedAndroidOcclusion] = useState(false);
  const [overGeolocatediOSHumanOcclusion, setOverGeolocatediOSHumanOcclusion] = useState(true);
  const [overGeolocatediOSLidarMeshOcclusion, setOverGeolocatediOSLidarMeshOcclusion] = useState(false);
  const [hasRemotePreview, setHasRemotePreview] = useState(true);
  const [isRemoteArEver, setIsRemoteArEver] = useState(true);

  const [overRemoteWalkMode, setOverRemoteWalkMode] = useState(false);

  useEffect(() => {}, []);

  function onConfirm() {
    // const publishState = { selectedHexId, selectedLandUuid, hexOccupied }
    const stateAsJson = JSON.stringify({
      overDefualtLights,
      overArLights,
      overCustomShadow,
      overShadowDistance,
      overShadowDistanceValue,
      overProjection,
      overProjectionValue,
      overResolution,
      overResolutionValue,
      overGeolocatedAndroidOcclusion,
      overGeolocatediOSHumanOcclusion,
      overGeolocatediOSLidarMeshOcclusion,
      hasRemotePreview,
      isRemoteArEver,
      overRemoteWalkMode
    });
    Cookies.set("overSetting", stateAsJson);
    props.onConfirm(true);

    // if (selectedHexId) {
    //   props.onConfirm(publishState)
    // } else {
    //   alert('You have to bind your project to a land. Select an OVRLand.')
    // }
  }

  console.debug("overCustomShadow", overCustomShadow);

  return (
    <StyledDialog
      tag={props.tag}
      title={props.title}
      cancelLabel={props.cancelLabel}
      confirmLabel={props.confirmLabel}
      onCancel={props.onCancel}
      onConfirm={onConfirm}
      className="overSettingDialog"
    >
      <Content>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <SubTitlePadd>Shadow</SubTitlePadd> <br></br>
            <FormField inline style={{ marginTop: "10px" }}>
              <label htmlFor="customShadow">Custom Shadow Settings</label>
              <SelectInput
                className="dialog-select-input"
                options={[{ label: "Ignore", value: 0 }, { label: "On", value: 1 }, { label: "Off", value: 2 }]}
                value={overCustomShadow}
                onChange={e => setOverCustomShadow(e)}
              />
            </FormField>
            <Collapse in={overCustomShadow === 1}>
              <FormField inline style={{ marginTop: "10px" }}>
                <label htmlFor="setDistance">Set Distance</label>
                <BooleanInput id="setDistance" value={overShadowDistance} onChange={e => setOverShadowDistance(e)} />
              </FormField>
              <Collapse in={overShadowDistance === true}>
                <FormField inline style={{ marginTop: "10px" }}>
                  <label htmlFor="shadowDistance">Shadow Distance</label>
                  <StringInput
                    id="shadowDistance"
                    value={overShadowDistanceValue}
                    onChange={e => setOverShadowDistanceValue(e)}
                  />
                </FormField>
              </Collapse>

              <FormField inline style={{ marginTop: "10px" }}>
                <label htmlFor="setProjection">Set Projection</label>
                <BooleanInput id="setProjection" value={overProjection} onChange={e => setOverProjection(e)} />
              </FormField>
              <Collapse in={overProjection === true}>
                <FormField inline style={{ marginTop: "10px" }}>
                  <label htmlFor="customShadow">Shadow Projection</label>
                  <SelectInput
                    className="dialog-select-input"
                    options={[{ label: "Close Fit", value: 0 }, { label: "Stable Fit", value: 1 }]}
                    value={overProjectionValue}
                    onChange={e => setOverProjectionValue(e)}
                  />
                </FormField>
              </Collapse>
              <FormField inline style={{ marginTop: "10px" }}>
                <label htmlFor="setResolution">Set Resolution</label>
                <BooleanInput id="setResolution" value={overResolution} onChange={e => setOverResolution(e)} />
              </FormField>
              <Collapse in={overResolution === true}>
                <FormField inline style={{ marginTop: "10px" }}>
                  <label htmlFor="shadoRes">Shadow Resolution</label>
                  <SelectInput
                    className="dialog-select-input"
                    options={[
                      { label: "Low", value: 0 },
                      { label: "Medium", value: 1 },
                      { label: "High", value: 2 },
                      { label: "Very high", value: 3 }
                    ]}
                    value={overResolutionValue}
                    onChange={e => setOverResolutionValue(e)}
                  />
                </FormField>
              </Collapse>
            </Collapse>
          </Grid>
          <Grid item xs={4}>
            <SubTitle>AR Settings</SubTitle> <br></br>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <SubTitlePadd>Android Occlusion Setting</SubTitlePadd> <br></br>
            <FormField inline style={{ marginTop: "10px" }}>
              <label htmlFor="androidOcclusion">Environment Occlusion</label>
              <BooleanInput
                id="androidOcclusion"
                value={overGeolocatedAndroidOcclusion}
                onChange={e => setOverGeolocatedAndroidOcclusion(e)}
              />
            </FormField>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <SubTitlePadd>iOS Occlusion Settings</SubTitlePadd> <br></br>
            {/* <FormField inline style={{ marginTop: "10px" }}>
              iOS Occlusion Settings
            </FormField> */}
            <FormField inline style={{ marginTop: "10px" }}>
              <label htmlFor="iosOcclusion">Human Occlusion</label>
              <BooleanInput
                id="iosOcclusion"
                value={overGeolocatediOSHumanOcclusion}
                onChange={e => setOverGeolocatediOSHumanOcclusion(e)}
              />
            </FormField>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <SubTitlePadd>iOS Lidar Occlusion Settings</SubTitlePadd> <br></br>
            <FormField inline style={{ marginTop: "10px" }}>
              <label htmlFor="meshOcclusion">Mesh Occlusion</label>
              <BooleanInput
                id="meshOcclusion"
                value={overGeolocatediOSLidarMeshOcclusion}
                onChange={e => setOverGeolocatediOSLidarMeshOcclusion(e)}
              />
            </FormField>
          </Grid>
          <Grid item xs={4}>
            <SubTitlePadd>Remote Experience Settings</SubTitlePadd> <br></br>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <SubTitlePadd>Enable Remote Experience</SubTitlePadd> <br></br>
            <FormField inline style={{ marginTop: "10px" }}>
              <label htmlFor="remoteandroidOcclusion">Enable Remote Experience</label>
              <BooleanInput
                id="remoteandroidOcclusion"
                value={hasRemotePreview}
                onChange={e => setHasRemotePreview(e)}
              />
            </FormField>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <SubTitlePadd>Show OVRMap</SubTitlePadd> <br></br>
            <FormField inline style={{ marginTop: "10px" }}>
              <label htmlFor="remoteiosOcclusion">Show OVRMap</label>
              <BooleanInput id="remoteiosOcclusion" value={isRemoteArEver} onChange={e => setIsRemoteArEver(e)} />
            </FormField>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <SubTitlePadd>Walk Mode with OVRMap</SubTitlePadd> <br></br>
            <FormField inline style={{ marginTop: "10px" }}>
              <label htmlFor="walkMode">Walk Mode enabled by default</label>
              <BooleanInput id="walkMode" value={overRemoteWalkMode} onChange={e => setOverRemoteWalkMode(e)} />
            </FormField>
            <br></br>
            <br></br>
          </Grid>
        </Grid>
      </Content>
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)`
  &.overSettingDialog {
    max-width: 100%;

    label {
      font-size: 14px;
    }
    .dialog-select-input {
      max-width: 130px;
    }

    #shadowDistance {
      max-width: 130px;
    }
    .space {
      height: 35px;
    }
  }
`;
