/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import * as R from "ramda";
import styled from "styled-components";

import { threediumCreateRequest } from "../../api/lib/api";
import { successNotification, warningNotification } from "../../api/lib/notifications";

import NavBar from "../navigation/NavBar";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";

import PCImage from "../../assets/over-thredium.png";
import feat1 from "../../assets/feat1.png";
import feat2 from "../../assets/feat2.png";
import feat3 from "../../assets/feat3.png";
import basic from "../../assets/basic.png";
import medium from "../../assets/medium.png";
import high from "../../assets/high.png";
import ring from "../../assets/img_threedium.png";

const PageContainer = styled.section`
  margin: 0 auto;
  max-width: 1300px;
  padding: 0 20px;
  width: 85%;
  @media screen and (max-width: 995px) {
    width: auto;
  }
  .upload-section {
    max-width: 450px;
    width: auto !important;
    @media screen and (max-width: 995px) {
      margin-left: 0 !important;
      width: auto !important;
      max-width: initial;
    }
  }
`;

const ThreediumHeader = styled.div`
  padding-top: 40px;
  margin-top: 45px;
  margin-bottom: 36px;
  margin: 0 auto;
  text-align: center;

  .logos-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 25px;
    @media screen and (max-width: 769px) {
      flex-direction: column;
      margin-bottom: 20px;
    }
    img {
      &.logo-1 {
        max-width: 140px;
        height: fit-content;
        margin: 0 40px 0 0;
        @media screen and (max-width: 769px) {
          margin: 15px 20px;
        }
      }
      &.logo-2 {
        max-width: 200px;
        height: fit-content;
        margin: 0 0 0 40px;
        @media screen and (max-width: 769px) {
          margin: 15px 20px;
        }
      }
    }
  }

  .process {
    padding: 30px 40px;
    border-radius: 6px;
    background-color: #2a2266;
    max-width: 1200px;
    margin: 20px auto;

    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 50px;
      margin: 10px;
    }

    .single {
      display: flex;
      align-items: center;
      justify-content: center;

      &.size {
        img {
          max-width: 250px;
        }
      }

      img {
        max-width: 50px;
      }
      span {
        padding-left: 15px;
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        max-width: 215px;
      }
    }
  }

  .process-2 {
    padding: 30px 40px;
    border-radius: 6px;
    background-color: #2a2266;
    max-width: 1200px;
    margin: 20px auto;

    .flexed {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    svg {
      width: 50px;
      margin: 10px;
    }

    .single {
      display: flex;
      align-items: center;
      justify-content: center;

      &.size {
        img {
          max-width: 250px;
        }
      }

      img {
        max-width: 50px;
      }
      span {
        padding-left: 15px;
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        max-width: 215px;
      }
    }
  }

  .logo {
    width: 450px;
    height: "auto";
    margin-bottom: 20px;
  }
  .title {
    font-family: "Work Sans", sans-serif;
    font-size: 25px;
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
    span {
      color: #327d94;
    }
  }
  .banner-1 {
    margin-top: 30px;
  }
  .header-banner {
    width: 80%;
    height: auto;
  }
`;

const RequestFieldsContainer = styled.div`
  margin-top: 50px;
  text-align: center;
  position: relative;

  .MuiFormLabel-root {
    font-size: 18px !important;
    color: #715eff !important;
    min-height: 21px !important;
  }

  .MuiTimelineConnector-root {
    background-color: #715eff !important;
  }

  svg {
    fill: white path {
      fill: white;
    }
    g {
      fill: white;
    }
  }
  .MuiInputBase-input {
    svg {
      fill: white;
    }
  }
  .MuiFormControl-root {
    display: block;
    color: white;
    /* font-size: 25px; */
    width: 100%;
    text-align: left;
    .MuiInputBase-root {
      &:after {
        border-bottom-color: white !important;
      }
    }
    .MuiInput-underline {
      &:after {
        border-bottom: 2px solid white;
      }
    }
    .MuiFormLabel-root {
      color: white;
      letter-spacing: -0.3px;
      font-size: 15px;
      /* font-size: 20px; */
      transform: translate(0, 9px) scale(1);
      min-height: 36px;
      display: flex;
      align-items: end;
    }

    .MuiInputLabel-shrink {
      transform: translate(0, -14px) scale(0.8);
    }

    .MuiInputBase-root {
      color: white;
      /* font-size: 25px; */
      width: 100%;

      /* Change autocomplete styles in WebKit */
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus {
        -webkit-text-fill-color: white;
        -webkit-box-shadow: 0 0 0px 1000px #1d1c2d inset;
      }

      &.MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 2px solid white;
      }

      &.MuiInput-underline {
        border-bottom: 1px solid white;
        &:after {
          border-bottom-color: white !important;
        }
      }

      .MuiSelect-icon {
        color: white;
      }
    }
  }
  .date-input {
    color: black !important;
    //background-color: white !important;

    .MuiFormLabel-root {
      //color: black !important;
    }

    .MuiInputBase-root {
      //color: black !important;
    }
  }
  .MuiInputBase-input[type="date"] {
    background-size: contain;
    background-position: top right;
    background-repeat: no-repeat;
    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
      opacity: 1;
    }
  }
  @media screen and (max-width: 995px) {
    .MuiTimelineItem-missingOppositeContent {
      &:before {
        display: none;
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  icon: {
    fill: "white"
  },
  chip: {
    margin: 2
  }
}));

let today = new Date();
const dd = String(today.getDate()).padStart(2, "0");
const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
const yyyy = today.getFullYear();

today = yyyy + "-" + mm + "-" + dd;

const CreateThreediumProjectPage = ({ history }) => {
  const classes = useStyles();

  const [formState, setFormState] = useState({
    question_1: "",
    question_2: null,
    // question_3: null,
    question_4: null,
    question_5: null,
    question_6: null,
    // question_7: [],
    question_8: new Date()
  });

  const [loading, setLoading] = useState(false);
  const [fileListCount, setFileListCount] = useState(1);
  const [fileList, setFileList] = useState([]);

  const [inspirationListCount, setInspirationListCount] = useState(1);
  const [inspirationList, setInspirationList] = useState([]);

  const [menu8Open, setMenu8Open] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  function uploadFile(id) {
    const selector = document.querySelector(`#fileupload_${id}`);
    const file = selector.files[0];

    setFileList(s => R.append(file, s));

    successNotification("Success", "File ready to send. Press 'Send Request once completed to fill out the form' ");
  }

  function uploadInspiration(id) {
    const selector = document.querySelector(`#inspirationupload_${id}`);
    const file = selector.files[0];

    setInspirationList(s => R.append(file, s));

    successNotification("Success", "File ready to send. Press 'Send Request' once completed to fill out the form");
  }

  const handleSubmit = () => {
    const formData = new FormData();

    fileList.map((element, index) => {
      formData.append(`files[${index}]`, element);
    });

    inspirationList.map((element, index) => {
      formData.append(`inspirations[${index}]`, element);
    });

    formData.append("question_1", formState["question_1"]);
    formData.append("question_2", formState["question_2"]);
    // formData.append('question_3', formState['question_3'])
    formData.append("question_4", formState["question_4"]);
    formData.append("question_5", formState["question_5"]);
    formData.append("question_6", formState["question_6"]);
    formData.append("question_7", formState["question_7"]);
    formData.append("question_8", formState["question_8"]);

    // console.debug('handleSubmit', {
    //   files: { fileList, inspirationList },
    //   formState,
    //   formData,
    //   test: formData.values(),
    // })

    // If missing one or more fields show warning notification
    const completelyFilledForm = !R.any(single => single === false)(
      R.map(single => (R.isNil(single) || R.isEmpty(single) ? false : true))(R.values(formState))
    );

    if (!completelyFilledForm) {
      warningNotification("Warning", "You must enter all fields");
    } else {
      setLoading(true);
      threediumCreateRequest(formData)
        .then(response => {
          const result = R.pathOr(false, ["data", "result"], response);
          console.debug("handleSubmit.response", response);
          setLoading(false);
          if (result) {
            successNotification("Success", "Request created successfully");
            setTimeout(() => {
              history.push("/projects");
            }, 800);
          } else if (response?.data?.errorCode == "authentication") {
            warningNotification("Warning", "You must log in to submit a request");
          }
        })
        .catch(err => {
          console.debug(err);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <NavBar />

      <WelcomeContainer>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} style={{ display: "flex", alignItems: "center" }}>
            <div className="head-content">
              <div>
                <h2>Elevate your experiences with deluxe 3D assets</h2>
                <h4>
                  Threedium and OVER gives you the possibility to obtain incredible 3d models and crate premium
                  experiences.
                </h4>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="head-content">
              <img src={PCImage} />
            </div>
          </Grid>
        </Grid>
      </WelcomeContainer>

      <PageContainer>
        <ThreediumHeader>
          <div className="process">
            <div className="single">
              <img src={feat1} />
              <span>Fill out the form and upload the info</span>
            </div>

            <svg width="59px" height="24px" viewBox="0 0 59 24" version="1.1">
              <title>Group 18</title>
              <g id="BUILDER-WEB-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                <g
                  id="HOME-/-THREEDIUM"
                  transform="translate(-543.000000, -729.000000)"
                  stroke="#FFFFFF"
                  strokeWidth="4"
                >
                  <g id="Group-18" transform="translate(544.716662, 731.821835)">
                    <line x1="0.283338445" y1="9.17816474" x2="52.7046225" y2="9.17816474" id="Path-3"></line>
                    <polyline
                      id="Path-6"
                      strokeLinejoin="round"
                      transform="translate(50.689318, 9.178165) rotate(90.000000) translate(-50.689318, -9.178165) "
                      points="41.5111529 13.7672471 50.6893177 4.58908237 59.8674824 13.7672471"
                    ></polyline>
                  </g>
                </g>
              </g>
            </svg>

            <div className="single">
              <img src={feat2} />
              <span>Get the quote for the experience</span>
            </div>

            <svg width="59px" height="24px" viewBox="0 0 59 24" version="1.1">
              <title>Group 18</title>
              <g id="BUILDER-WEB-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                <g
                  id="HOME-/-THREEDIUM"
                  transform="translate(-543.000000, -729.000000)"
                  stroke="#FFFFFF"
                  strokeWidth="4"
                >
                  <g id="Group-18" transform="translate(544.716662, 731.821835)">
                    <line x1="0.283338445" y1="9.17816474" x2="52.7046225" y2="9.17816474" id="Path-3"></line>
                    <polyline
                      id="Path-6"
                      strokeLinejoin="round"
                      transform="translate(50.689318, 9.178165) rotate(90.000000) translate(-50.689318, -9.178165) "
                      points="41.5111529 13.7672471 50.6893177 4.58908237 59.8674824 13.7672471"
                    ></polyline>
                  </g>
                </g>
              </g>
            </svg>

            <div className="single">
              <img src={feat3} />
              <span>Open the assets you requested in the builder</span>
            </div>
          </div>

          <div className="process-2">
            <div className="full">
              <p style={{ fontWeight: 500, fontSize: 24 }}>Prince Range</p>
              <p style={{ fontWeight: 500, fontSize: 16, marginBottom: 30, marginTop: 10 }}>
                The price of the model for your experience varies according to its complexity
              </p>
            </div>

            <div className="flexed">
              <div className="single size">
                <img src={basic} />
              </div>

              <div className="single size">
                <img src={medium} />
              </div>

              <div className="single size">
                <img src={high} />
              </div>
            </div>
          </div>
        </ThreediumHeader>
        <RequestFieldsContainer>
          <img
            src={ring}
            style={{ width: "45%", position: "absolute", left: 0, top: "50%", transform: "translateY(-50%)" }}
          />
          <Timeline style={{ textAlign: "left" }}>
            {/* {1} */}
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ marginBottom: 20 }}>
                <TextField
                  fullWidth
                  defaultValue={formState.question_1}
                  required
                  id="textField_1"
                  label="Project Name"
                  size="medium"
                  onChange={e => {
                    const value = e?.target?.value;
                    setFormState(s => ({ ...s, question_1: value }));
                  }}
                />
              </TimelineContent>
            </TimelineItem>
            {/* {2} */}
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ marginBottom: 20 }}>
                <FormControl>
                  <TextField
                    fullWidth
                    required
                    id="textField_3"
                    label="Project objectives"
                    size="medium"
                    onChange={e => {
                      const value = e?.target?.value;
                      setFormState(s => ({ ...s, question_2: value }));
                    }}
                  />
                  <FormHelperText id="component-helper-text" style={{ color: "white" }}>
                    Explanation of product / anything that can make us understand better your needs
                  </FormHelperText>
                </FormControl>
              </TimelineContent>
            </TimelineItem>

            {/* {4} */}
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ marginBottom: 20 }}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-controlled-open-select-label">
                    What assets besides HD images will you be providing?
                  </InputLabel>

                  <Select
                    required
                    labelId="demo-controlled-open-select-label"
                    id="textField_8"
                    open={menu8Open}
                    onClose={() => setMenu8Open(false)}
                    onOpen={() => setMenu8Open(true)}
                    value={formState.question_4}
                    onChange={e =>
                      setFormState(s => ({
                        ...s,
                        question_4: e.target.value
                      }))
                    }
                  >
                    <MenuItem value={0}>CAD files</MenuItem>
                    <MenuItem value={1}>OtherTechnical Drawings</MenuItem>
                  </Select>
                  <FormHelperText id="component-helper-text" style={{ color: "white" }}>
                    (remember you have to provide 1 HD image for every single angle)
                  </FormHelperText>
                </FormControl>
              </TimelineContent>
            </TimelineItem>

            {/* {5} */}
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ marginBottom: 20 }}>
                <TextField
                  fullWidth
                  defaultValue={formState.question_5}
                  required
                  id="textField_5"
                  label="Please provide a link to your cloud folder (dropbox, google drive, etc.)"
                  size="medium"
                  onChange={e => {
                    const value = e?.target?.value;
                    setFormState(s => ({ ...s, question_5: value }));
                  }}
                />
              </TimelineContent>
            </TimelineItem>

            {/* {6} */}
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent style={{ marginBottom: 20 }}>
                <TextField
                  fullWidth
                  defaultValue={formState.question_6}
                  value={formState.question_6}
                  required
                  id="standard-required"
                  label="Comments"
                  size="medium"
                  multiline
                  rows={4}
                  onChange={e => {
                    const value = e?.target?.value;
                    setFormState(s => ({ ...s, question_6: value }));
                  }}
                />
              </TimelineContent>
            </TimelineItem>

            {/* {8} */}
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                style={{
                  marginBottom: 70
                }}
              >
                <TextField
                  fullWidth
                  required
                  id="textField_18"
                  label="Expected Launch Date"
                  type="date"
                  defaultValue={formState.question_8}
                  value={formState.question_8}
                  className={`${classes.textField} date-input`}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={e => {
                    const value = e?.target?.value;

                    const today = new Date();
                    const selectedDate = new Date(value);

                    function addDays(date, days) {
                      const copy = new Date(Number(date));
                      copy.setDate(date.getDate() + days);
                      return copy;
                    }

                    const newDate = addDays(today, 4);

                    if (newDate.getTime() > selectedDate.getTime()) {
                      setFormState(s => ({ ...s, question_8: null }));
                      warningNotification("Warning", "Minimum in 4 days from today");
                    } else {
                      setFormState(s => ({ ...s, question_8: value }));
                    }
                  }}
                />
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </RequestFieldsContainer>

        <UploadContainer className="upload-container" style={{ display: "flex" }}>
          <div className="upload-section" style={{ flex: 1 }}>
            <Card className={classes.root} style={{ marginBottom: 40 }}>
              <CardContent>
                <Typography variant="h5" component="h2" style={{ marginBottom: 30 }}>
                  Inspirations Upload
                </Typography>
                {R.times(
                  number => (
                    <InspirationUpload id={number + 1} uploadInspiration={uploadInspiration} />
                  ),
                  inspirationListCount
                )}
              </CardContent>
              <CardActions>
                <Button startIcon={<AddIcon />} onClick={() => setInspirationListCount(s => s + 1)}>
                  Add More
                </Button>
              </CardActions>
            </Card>
          </div>

          <div className="upload-section" style={{ flex: 1 }}>
            <Card className={classes.root} style={{ marginBottom: 40 }}>
              <CardContent>
                <Typography variant="h5" component="h2" style={{ marginBottom: 30 }}>
                  Explanation Media
                </Typography>
                {R.times(
                  number => (
                    <FileUpload id={number + 1} uploadFile={uploadFile} />
                  ),
                  fileListCount
                )}
              </CardContent>
              <CardActions>
                <Button startIcon={<AddIcon />} onClick={() => setFileListCount(s => s + 1)}>
                  Add More
                </Button>
              </CardActions>
            </Card>

            {/* SUBMIT BUTTON */}
          </div>
        </UploadContainer>

        <section style={{ textAlign: "center" }}>
          {loading ? (
            <>
              <Button
                onClick={handleSubmit}
                style={{
                  backgroundColor: "#715EFF",
                  color: "white",
                  padding: "20px 8px",
                  borderRadius: 30,
                  width: 300
                }}
                color="primary"
              >
                Send Request
              </Button>
              <LinearProgress
                style={{
                  marginBottom: 50
                }}
              />
            </>
          ) : (
            <>
              <Button
                onClick={handleSubmit}
                style={{
                  backgroundColor: "#715EFF",
                  color: "white",
                  padding: "20px 8px",
                  marginBottom: 50,
                  borderRadius: 30,
                  width: 300
                }}
                color="primary"
              >
                Send Request
              </Button>
            </>
          )}
        </section>
      </PageContainer>
    </div>
  );
};

const UploadContainer = styled.div`
  .upload-section {
    max-width: inherit !important;
  }
  .MuiPaper-root {
    margin: 10px;
    background-color: #2a2266 !important;
  }

  .MuiButtonBase-root {
    background: white;
    border-radius: 30px;
    color: black;
    padding: 6px 20px;
  }
`;

const FileUpload = ({ uploadFile, id }) => {
  const classes = useStyles();

  return (
    <>
      <div className="container-file-uploads" style={{ textAlign: "left", width: "100%" }}>
        <input
          className={classes.input}
          name={`fileupload_${id}`}
          id={`fileupload_${id}`}
          type="file"
          onChange={() => uploadFile(id)}
        />
      </div>
      <Divider style={{ marginTop: 30, marginBottom: 30 }} />
    </>
  );
};

const InspirationUpload = ({ uploadInspiration, id }) => {
  const classes = useStyles();

  return (
    <>
      <div className="container-file-uploads" style={{ textAlign: "left", width: "100%" }}>
        <input
          className={classes.input}
          name={`inspirationupload_${id}`}
          id={`inspirationupload_${id}`}
          type="file"
          onChange={() => uploadInspiration(id)}
        />
      </div>
      <Divider style={{ marginTop: 30, marginBottom: 30 }} />
    </>
  );
};

const WelcomeContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;

  .head-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h1 {
    font-size: 15px;
  }
  h2 {
    line-height: 50px;
    font-size: 53px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  h4 {
    font-size: 20px;
  }

  img {
    max-width: 450px;
    height: auto;
  }
`;

export default CreateThreediumProjectPage;
