import configs from "../configs";
import * as R from "ramda";
import axios from "axios";
import EventEmitter from "eventemitter3";
import uuid from "uuid/v4";
import AuthContainer from "./AuthContainer";
import LoginDialog from "./LoginDialog";
import PublishDialog from "./PublishDialog";
import ProgressDialog from "../ui/dialogs/ProgressDialog";
import PerformanceCheckDialog from "../ui/dialogs/PerformanceCheckDialog";
import jwtDecode from "jwt-decode";
import { buildAbsoluteURL } from "url-toolkit";
import PublishedSceneDialog from "./PublishedSceneDialog";
import { matchesFileTypes, AudioFileTypes } from "../ui/assets/fileTypes";
import { RethrownError } from "../editor/utils/errors";
import { instanceOf } from "prop-types";
import { getUser } from "./lib/auth";
import Cookies from "js-cookie";
import { getUserLands, uploadNftAsset } from "../api/lib/api";

import {
  userAuth,
  uploadFile,
  uploadProject,
  updateProject,
  retrieve,
  deleteFile,
  deleteProject,
  get_project,
  get_blob,
  publishProject,
  retrieveOVRAssets,
  generateLoginToken,
  sketch_request,
  get_sketch_asset,
  checkHexagon
} from "./lib/api";

import config from "./lib/config";
import ThreeWordsDialog from "../ui/dialogs/ThreeWordsDialog";
import SelectLandDialog from "../ui/dialogs/SelectLandDialog";
import SelectMappingDialog from "../ui/dialogs/SelectMappingDialog";
import ErrorDialog from "../ui/dialogs/ErrorDialog";
import OverSettingDialog from "../ui/dialogs/OverSettingDialog";

// Media related functions should be kept up to date with Hubs media-utils:
// https://github.com/mozilla/hubs/blob/master/src/utils/media-utils.js

const resolveUrlCache = new Map();
const resolveMediaCache = new Map();

const RETICULUM_SERVER = configs.RETICULUM_SERVER || document.location.hostname;

// thanks to https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8, then we convert the percent-encodings
  // into raw bytes which can be fed into btoa.
  const CHAR_RE = /%([0-9A-F]{2})/g;
  return btoa(encodeURIComponent(str).replace(CHAR_RE, (_, p1) => String.fromCharCode("0x" + p1)));
}

const farsparkEncodeUrl = url => {
  // farspark doesn't know how to read '=' base64 padding characters
  // translate base64 + to - and / to _ for URL safety
  return b64EncodeUnicode(url)
    .replace(/=+$/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
};

const nonCorsProxyDomains = (configs.NON_CORS_PROXY_DOMAINS || "").split(",");
if (configs.CORS_PROXY_SERVER) {
  nonCorsProxyDomains.push(configs.CORS_PROXY_SERVER);
}

function shouldCorsProxy(url) {
  // Skip known domains that do not require CORS proxying.
  try {
    const parsedUrl = new URL(url);
    if (nonCorsProxyDomains.find(domain => parsedUrl.hostname.endsWith(domain))) return false;
  } catch (e) {
    // Ignore
  }

  return true;
}

export const proxiedUrlFor = url => {
  if (!(url.startsWith("http:") || url.startsWith("https:"))) return url;

  if (!shouldCorsProxy(url)) {
    return url;
  }

  //return `https://${configs.CORS_PROXY_SERVER}/${url}`;
  return `${url}`;
};

export const scaledThumbnailUrlFor = (url, width, height) => {
  if (configs.RETICULUM_SERVER.includes("hubs.local") && url.includes("hubs.local")) {
    return url;
  }

  return `https://${configs.THUMBNAIL_SERVER}/thumbnail/${farsparkEncodeUrl(url)}?w=${width}&h=${height}`;
};

const CommonKnownContentTypes = {
  gltf: "model/gltf",
  glb: "model/gltf-binary",
  png: "image/png",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  pdf: "application/pdf",
  mp4: "video/mp4",
  mp3: "audio/mpeg"
};

const CommonContentTypeGroupings = {
  image: ["jpg", "jpeg", "png"],
  video: ["mp4"],
  audio: ["mp3"],
  model: ["gltf", "glb"],
  application: ["pdf"]
};

const defaultRepo = "ovr_asset_repo_00001";

function guessContentType(url) {
  const extension = new URL(url).pathname.split(".").pop();
  return CommonKnownContentTypes[extension];
}

const LOCAL_STORE_KEY = "___hubs_store";

export default class Project extends EventEmitter {
  constructor() {
    super();

    const { protocol, host } = new URL(window.location.href);

    this.serverURL = protocol + "//" + host;
    this.apiURL = `https://${RETICULUM_SERVER}`;

    this.projectDirectoryPath = "/api/files/";

    // Max size in MB
    this.maxUploadSize = 128;
  }

  getAuthContainer() {
    return AuthContainer;
  }

  async authenticate(token, provider) {
    // Todo fix to make it meaninful
    let id = "login.data.user.id";
    Cookies.set("AuthToken", "token");
    Cookies.set("AuthUID", "login.data.user.id");
    Cookies.set("AuthUID", "login.data.user.id");
    Cookies.set("AuthName", "todoo");

    const authComplete = new Promise(resolve => {
      localStorage.setItem(LOCAL_STORE_KEY, JSON.stringify({ credentials: { id, token } }));
      this.emit("authentication-changed", true); // Questa è la chiave per il reload dell'interfaccia
      resolve();
    });

    return authComplete;
  }

  // authenticate(id, name, token, tokenAge, info) {
  //   const ageInSeconds = (1 / 86400) * tokenAge;
  //   var currentSessionCookie = Cookies.get("AuthToken");
  //   if (currentSessionCookie == null) {
  //     Cookies.set("AuthToken", token, { expires: ageInSeconds });
  //     currentSessionCookie = Cookies.get("AuthToken");
  //   }
  //   var currentUIDCookie = Cookies.get("AuthUID");
  //   if (currentUIDCookie == null) {
  //     Cookies.set("AuthUID", id, { expires: ageInSeconds });
  //     currentUIDCookie = Cookies.get("AuthUID");
  //   }
  //   var currentNameCookie = Cookies.get("AuthName");
  //   if (currentNameCookie == null) {
  //     Cookies.set("AuthName", name, { expires: ageInSeconds });
  //     currentNameCookie = Cookies.get("AuthName");
  //   }
  //   const authComplete = new Promise((resolve) => {
  //     localStorage.setItem(
  //       LOCAL_STORE_KEY,
  //       JSON.stringify({ credentials: { id, token } })
  //     );
  //     this.emit("authentication-changed", true);
  //     resolve();
  //   });

  //   return authComplete;
  // }

  isAuthenticated() {
    return getUser() !== undefined;
  }

  getUserID() {
    return Cookies.get("AuthUID");
  }

  getProvider() {
    return Cookies.get("AuthProvider");
  }

  getToken() {
    return Cookies.get("AuthToken");
  }

  getUserName() {
    return Cookies.get("AuthName");
  }

  logout() {
    localStorage.removeItem(LOCAL_STORE_KEY);
    this.emit("authentication-changed", false);
    Cookies.remove("AuthToken");
    Cookies.remove("AuthUID");
    Cookies.remove("AuthProvider");
  }

  /*
  async authenticate(email, signal) {
    const reticulumServer = RETICULUM_SERVER;
    const socketUrl = `wss://${reticulumServer}/socket`;
    const socket = new Socket(socketUrl, { params: { session_id: uuid() } });
    socket.connect();

    const channel = socket.channel(`auth:${uuid()}`);

    const onAbort = () => socket.disconnect();

    signal.addEventListener("abort", onAbort);

    await new Promise((resolve, reject) =>
      channel
        .join()
        .receive("ok", resolve)
        .receive("error", err => {
          signal.removeEventListener("abort", onAbort);
          reject(err);
        })
    );

    const authComplete = new Promise(resolve =>
      channel.on("auth_credentials", ({ credentials: token }) => {
        localStorage.setItem(LOCAL_STORE_KEY, JSON.stringify({ credentials: { email, token } }));
        this.emit("authentication-changed", true);
        resolve();
      })
    );

    channel.push("auth_request", { email, origin: "spoke" });

    signal.removeEventListener("abort", onAbort);

    return authComplete;
  }

  isAuthenticated() {
    const value = localStorage.getItem(LOCAL_STORE_KEY);

    const store = JSON.parse(value);

    return !!(store && store.credentials && store.credentials.token);
  }
  

  getToken() {
    const value = localStorage.getItem(LOCAL_STORE_KEY);

    if (!value) {
      throw new Error("Not authenticated");
    }

    const store = JSON.parse(value);

    if (!store || !store.credentials || !store.credentials.token) {
      throw new Error("Not authenticated");
    }

    return store.credentials.token;
  }
  */

  getAccountId() {
    const token = this.getToken();
    return jwtDecode(token).sub;
  }

  showLoginDialog(showDialog, hideDialog) {
    return new Promise(resolve => {
      showDialog(LoginDialog, {
        onSuccess: () => {
          hideDialog();
          resolve();
        }
      });
    });
  }

  async getProjects() {
    const token = this.getToken();
    const provider = this.getProvider();

    const json = await retrieve(token, provider);

    const data = json.data;

    if (
      data.hasOwnProperty("user") &&
      data.user.hasOwnProperty("projectList") &&
      !Array.isArray(data.user.projectList)
    ) {
      throw new Error(`Error fetching projects: ${json.error || "Unknown error."}`);
    }

    if (!data.hasOwnProperty("user") || !data.user.hasOwnProperty("projectList") || data.user.projectList.length == 0) {
      return { projects: [] };
    }

    const allProjects = data.user.projectList.map(project => ({
      project_id: project.uuid,
      project_url: project.fileUrl,
      project_thumbnail: project.thumbnailUrl,
      project_name: project.projectName,
      land: project.land,
      folder_uuid: project.landFolderUuid,
      publication: project.publication,
      land_scan: project.landScan
    }));

    return { projects: allProjects };
  }

  async getProject(projectId, downloadUrl = null) {
    /*const token = this.getToken();
    const headers = {
      method: "HEAD",
      "content-type": "application/json",
      authorization: `Bearer ${token}`,
    };*/

    const response = await get_project(projectId, downloadUrl);
    const project = response.data.project.fileUrl;
    const r = await get_blob(project);
    //let blob = await this.fetch(project, { headers }).then(r => r.blob());
    let data = r.data;
    const json = { project_id: projectId, project_data: data, project_json: response.data.project };
    return json;
  }

  /*
  async getProjects() {
    const token = this.getToken();

    const headers = {
      "content-type": "application/json",
      authorization: `Bearer ${token}`
    };

    const response = await this.fetch(
      `https://${RETICULUM_SERVER}/api/v1/projects`,
      { headers }
    );

    const json = await response.json();

    if (!Array.isArray(json.projects)) {
      throw new Error(
        `Error fetching projects: ${json.error || "Unknown error."}`
      );
    }

    return json.projects;
  }

  async getProject(projectId) {
    const token = this.getToken();

    const headers = {
      "content-type": "application/json",
      authorization: `Bearer ${token}`
    };

    const response = await this.fetch(
      `https://${RETICULUM_SERVER}/api/v1/projects/${projectId}`,
      {
        headers
      }
    );

    const json = await response.json();

    return json;
  }

  */

  async resolveUrl(url, index) {
    if (!shouldCorsProxy(url)) {
      return { origin: url };
    }

    const cacheKey = `${url}|${index}`;
    if (resolveUrlCache.has(cacheKey)) return resolveUrlCache.get(cacheKey);

    const request = this.fetch(`https://${RETICULUM_SERVER}/api/v1/media`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ media: { url, index } })
    }).then(async response => {
      if (!response.ok) {
        const message = `Error resolving url "${url}":\n  `;
        try {
          const body = await response.text();
          throw new Error(message + body.replace(/\n/g, "\n  "));
        } catch (e) {
          throw new Error(message + response.statusText.replace(/\n/g, "\n  "));
        }
      }

      return response.json();
    });

    resolveUrlCache.set(cacheKey, request);

    return request;
  }

  fetchContentType(accessibleUrl) {
    console.debug("fetchContentType.accessibleUrl", { accessibleUrl });
    return this.fetch(accessibleUrl, {
      method: "HEAD",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "https://builder.ovr.ai",
        Accept: "*",
        "Access-Control-Allow-Methods": "GET, DELETE, HEAD, OPTIONS"
      }
    }).then(r => r.headers.get("content-type"));
  }

  async getContentType(url) {
    //const result = await this.resolveUrl(url);
    //const canonicalUrl = result.origin;
    //const accessibleUrl = proxiedUrlFor(canonicalUrl);
    const accessibleUrl = proxiedUrlFor(url);

    return (
      //(result.meta && result.meta.expected_content_type) ||
      guessContentType(url) || (await this.fetchContentType(accessibleUrl))
    );
  }

  async resolveMedia(url, index) {
    console.debug("resolveMedia", { url, index });

    const isNFTLink = R.contains("isNFT__", url);
    console.debug("isNFTLink", isNFTLink);
    let nftLink = "";
    let newUrl = url;

    if (isNFTLink) {
      nftLink = R.split("isNFT__", url)[1];
      newUrl = nftLink;
      console.debug("nftLink", nftLink);
    }

    const absoluteUrl = new URL(newUrl, window.location).href;

    if (absoluteUrl.startsWith(this.serverURL)) {
      return { accessibleUrl: absoluteUrl };
    }

    const cacheKey = `${absoluteUrl}|${index}`;

    // if (resolveMediaCache.has(cacheKey)) return resolveMediaCache.get(cacheKey)

    const request = (async () => {
      let contentType, canonicalUrl, accessibleUrl;

      //modded 23/06/2020 ; 17:36
      if (!isNFTLink) {
        try {
          //const result = await this.resolveUrl(absoluteUrl);
          //canonicalUrl = result.origin;
          canonicalUrl = absoluteUrl;
          accessibleUrl = proxiedUrlFor(canonicalUrl, index);


          if (accessibleUrl.indexOf("https://api.sketchfab.com/") == 0) {
            contentType = "model/gltf+zip";
          } else {
            contentType =
              //(result.meta && result.meta.expected_content_type) ||
              guessContentType(canonicalUrl) || (await this.fetchContentType(accessibleUrl));
          }
        } catch (error) {
          throw new RethrownError(`Error resolving media "${absoluteUrl}"`, error);
        }

        try {
          if (contentType === "model/gltf+zip") {
            // TODO: Sketchfab object urls should be revoked after they are loaded by the glTF loader.
            const { getFilesFromSketchfabZip } = await import(
              /* webpackChunkName: "SketchfabZipLoader", webpackPrefetch: true */ "./SketchfabZipLoader"
            );

            var index = accessibleUrl.lastIndexOf("/");
            var fileName = newUrl.substr(index + 1);


            const zip = await get_sketch_asset(fileName);
            const files = await getFilesFromSketchfabZip(zip.data.file.gltf.url);
            return {
              canonicalUrl,
              accessibleUrl: files["scene.gtlf"].url,
              contentType,
              files
            };
          }
        } catch (error) {
          throw new RethrownError(`Error loading Sketchfab model "${accessibleUrl}"`, error);
        }
      } else {
        // is NFT
        await uploadNftAsset(nftLink.toString()).then(response => {
          canonicalUrl = response.data.file.fileUrl;
          accessibleUrl = response.data.file.fileUrl;
        });
        contentType = await this.fetchContentType(accessibleUrl);
      }

      return { canonicalUrl, accessibleUrl, contentType };
    })();

    resolveMediaCache.set(`${absoluteUrl}|${index}`, request);

    return request;
  }

  proxyUrl(url) {
    return proxiedUrlFor(url);
  }

  unproxyUrl(baseUrl, url) {
    if (configs.CORS_PROXY_SERVER) {
      const corsProxyPrefix = `https://${configs.CORS_PROXY_SERVER}/`;

      if (baseUrl.startsWith(corsProxyPrefix)) {
        baseUrl = baseUrl.substring(corsProxyPrefix.length);
      }

      if (url.startsWith(corsProxyPrefix)) {
        url = url.substring(corsProxyPrefix.length);
      }
    }

    // HACK HLS.js resolves relative urls internally, but our CORS proxying screws it up. Resolve relative to the original unproxied url.
    // TODO extend HLS.js to allow overriding of its internal resolving instead
    if (!url.startsWith("http")) {
      url = buildAbsoluteURL(baseUrl, url.startsWith("/") ? url : `/${url}`);
    }

    return proxiedUrlFor(url);
  }

  //TODO : inserire text query
  async getAssets(type = null) {
    const token = this.getToken();
    const provider = this.getProvider();
    if (type) {
      const availabletypes = CommonContentTypeGroupings[type];
      const promises = availabletypes.map(async type => {
        let response = await retrieve(token, provider, type);
        return response.data.result ? response.data.user.fileList : [];
      });
      return Promise.all(promises);
    }
    let response = await retrieve(token, provider);
    return response.data.result ? response.data.user.fileList : [];
  }

  async getOVRAssets(type = null, repo = defaultRepo) {
    if (repo && type) {
      const availabletypes = CommonContentTypeGroupings[type];
      const promises = availabletypes.map(async type => {
        let response = await retrieveOVRAssets(repo, type);
        return response.data.result ? response.data.assets : [];
      });
      return Promise.all(promises);
    }
    let response = await retrieveOVRAssets(repo);
    return response.data.result ? response.data.assets : [];
  }

  async getSketchfabAssets(fileName = null, cursor = 0, type = null, categories = null) {
    let response = await sketch_request({
      q: fileName,
      cursor: cursor,
      type: type,
      categories: categories,
      downloadable: true
    });

    return response;
  }

  async getNFTAssets(publicAddress) {
    let response = await axios.get(
      `https://api.opensea.io/api/v1/assets?owner=${publicAddress}&order_direction=desc&offset=0&limit=20`
    );

    return response;
  }

  //TODO
  async searchMedia(source, params, cursor, signal) {
    const url = new URL(`https://${RETICULUM_SERVER}/api/v1/media/search`);

    const headers = {
      "content-type": "application/json"
    };

    const searchParams = url.searchParams;

    searchParams.set("source", source);

    if (source === "assets") {
      searchParams.set("user", this.getAccountId());
      const token = this.getToken();
      headers.authorization = `Bearer ${token}`;
    }

    if (params.type) {
      searchParams.set("type", params.type);
    }

    if (params.query) {
      searchParams.set("q", params.query);
    }

    if (params.filter) {
      searchParams.set("filter", params.filter);
    }

    if (params.collection) {
      searchParams.set("collection", params.collection);
    }

    if (cursor) {
      searchParams.set("cursor", cursor);
    }


    const resp = await this.fetch(url, { headers, signal });

    if (signal.aborted) {
      const error = new Error("Media search aborted");
      error.aborted = true;
      throw error;
    }

    const json = await resp.json();

    if (signal.aborted) {
      const error = new Error("Media search aborted");
      error.aborted = true;
      throw error;
    }

    const thumbnailedEntries = json.entries.map(entry => {
      if (entry.images && entry.images.preview && entry.images.preview.url) {
        if (entry.images.preview.type === "mp4") {
          entry.images.preview.url = proxiedUrlFor(entry.images.preview.url);
        } else {
          entry.images.preview.url = scaledThumbnailUrlFor(entry.images.preview.url, 200, 200);
        }
      }
      return entry;
    });

    return {
      results: thumbnailedEntries,
      suggestions: json.suggestions,
      nextCursor: json.meta.next_cursor
    };
  }

  async createProject(
    scene,
    parentSceneId,
    thumbnailBlob,
    signal,
    showDialog,
    hideDialog,
    land_uuid,
    land_scan_uuid,
    folder_uuid
  ) {
    this.emit("project-saving");

    // Ensure the user is authenticated before continuing.
    if (!this.isAuthenticated()) {
      await new Promise(resolve => {
        showDialog(LoginDialog, {
          onSuccess: resolve
        });
      });
    }

    if (signal.aborted) {
      throw new Error("Save project aborted");
    }

    //const thumbnailResponse = await this.uploadAssetFile(thumbnailBlob, undefined, signal);
    //const thumbnail_file_id = thumbnailResponse.data.file.fileUuid;

    if (signal.aborted) {
      throw new Error("Save project aborted");
    }

    const serializedScene = scene.serialize();
    const projectBlob = new Blob([JSON.stringify(serializedScene)], {
      type: "application/json"
    });
    const fileName = scene.name.replace(/\s+/g, "_") + ".ovr";

    const abortController = new AbortController();

    const file = new File([projectBlob], fileName);
    const uploadResponse = await this.uploadProjectFile(
      file,
      thumbnailBlob,
      uploadProgress => {
        showDialog(
          ProgressDialog,
          {
            title: "Creating and Uploading Project",
            message: `Uploading ${Math.floor(uploadProgress * 100)}%`,
            onCancel: () => {
              abortController.abort();
            }
          },
          abortController.signal
        );
      },
      signal,
      land_uuid,
      land_scan_uuid,
      folder_uuid
    );
    const file_id = uploadResponse.data.project.uuid;
    const date = uploadResponse.data.project.createdAt;
    if (signal.aborted) {
      throw new Error("Save project aborted");
    }

    const project = {
      name: scene.name,
      scene: scene,
      project_id: file_id,
      //thumbnail_file_id: thumbnail_file_id,
      creation_date: date
    };

    if (parentSceneId) {
      project.parent_scene_id = parentSceneId;
    }
    return project;
  }

  async deleteProject(projectId) {
    const token = this.getToken();
    const provider = this.getProvider();
    const resp = await deleteProject(token, provider, projectId);
    if (resp.result == false) {
      throw new Error(`Project deletion failed. Reason: ${resp.error}`);
    }
    return true;
  }

  async saveProject(projectId, editor, signal, showDialog, hideDialog) {
    this.emit("project-saving");

    // Ensure the user is authenticated before continuing.
    if (!this.isAuthenticated()) {
      await new Promise(resolve => {
        showDialog(LoginDialog, {
          onSuccess: resolve
        });
      });
    }

    if (signal.aborted) {
      throw new Error("Save project aborted");
    }

    const { blob: thumbnailBlob } = await editor.takeScreenshot(512, 320);

    if (signal.aborted) {
      throw new Error("Save project aborted");
    }

    /*
    const {
      file_id: thumbnail_file_id,
      meta: { access_token: thumbnail_file_token }
    } = await this.uploadAssetFile(thumbnailBlob, undefined, signal);
    */

    if (signal.aborted) {
      throw new Error("Save project aborted");
    }


    const serializedScene = editor.scene.serialize();

    const projectBlob = new Blob([JSON.stringify(serializedScene)], {
      type: "application/json"
    });

    const fileName = editor.scene.name.replace(/\s+/g, "_") + ".ovr";

    const abortController = new AbortController();
    const file = new File([projectBlob], fileName);
    const uploadResponse = await this.overwrite(
      file,
      thumbnailBlob,
      projectId,
      uploadProgress => {
        showDialog(
          ProgressDialog,
          {
            title: "Updating Project",
            message: `Updating ${Math.floor(uploadProgress * 100)}%`,
            onCancel: () => {
              abortController.abort();
            }
          },
          abortController.signal
        );
      },
      signal
    );

    if (uploadResponse.status != 200) {
      throw new Error(`Updating Failed. Reason: ${uploadResponse.data.message || uploadResponse.data.error}`);
    }

    /*
    //OLD: se non è autenticato mandalo ad autenticarsi
    if (resp.status === 401) {
      return await new Promise((resolve, reject) => {
        showDialog(LoginDialog, {
          onSuccess: async () => {
            try {
              const result = await this.saveProject(projectId, editor, signal, showDialog, hideDialog);
              resolve(result);
            } catch (e) {
              reject(e);
            }
          }
        });
      });
    }
    */

    const project_file_id = uploadResponse.data.file.uuid;
    const project_url = uploadResponse.data.file.fileUrl;
    const date = uploadResponse.data.file.createdAt;

    if (signal.aborted) {
      throw new Error("Save project aborted");
    }

    const token = this.getToken();
    /*
    const headers = {
      "content-type": "application/json",
      authorization: `Bearer ${token}`
    };
    */

    const project = {
      name: editor.scene.name,
      scene: editor.scene,
      //thumbnail_file_id,
      //thumbnail_file_token,
      project_id: project_file_id,
      project_url: project_url,
      //project_file_token,
      creation_date: date
    };

    const sceneId = editor.scene.metadata && editor.scene.metadata.sceneId ? editor.scene.metadata.sceneId : null;

    if (sceneId) {
      project.scene_id = sceneId;
    }
    this.emit("project-saved");

    return project;
  }

  //GETSCENE
  //========

  async getScene(sceneId) {
    const headers = {
      "content-type": "application/json"
    };

    const response = await this.fetch(`https://${RETICULUM_SERVER}/api/v1/scenes/${sceneId}`, {
      headers
    });

    const json = await response.json();

    return json.scenes[0];
  }

  getSceneUrl(sceneId) {
    if (configs.HUBS_SERVER === "localhost:8080" || configs.HUBS_SERVER === "hubs.local:8080") {
      return `https://${configs.HUBS_SERVER}/scene.html?scene_id=${sceneId}`;
    } else {
      return `https://${configs.HUBS_SERVER}/scenes/${sceneId}`;
    }
  }
  //========

  /*
  async publishProject(project, editor, showDialog, hideDialog) {
    let screenshotUrl;

    try {
      const scene = editor.scene;

      const abortController = new AbortController();
      const signal = abortController.signal;

      // Save the scene if it has been modified.
      if (editor.sceneModified) {
        showDialog(ProgressDialog, {
          title: "Saving Project",
          message: "Saving project...",
          cancelable: true,
          onCancel: () => {
            abortController.abort();
          },
        });

        project = await this.saveProject(
          project.project_id,
          editor,
          signal,
          showDialog,
          hideDialog
        );

        if (signal.aborted) {
          const error = new Error("Publish project aborted");
          error.aborted = true;
          throw error;
        }
      }

      // Ensure the user is authenticated before continuing.
      if (!this.isAuthenticated()) {
        await new Promise((resolve) => {
          showDialog(LoginDialog, {
            onSuccess: resolve,
          });
        });
      }

      showDialog(ProgressDialog, {
        title: "Generating Project Screenshot",
        message: "Generating project screenshot...",
      });

      // Wait for 5ms so that the ProgressDialog shows up.
      await new Promise((resolve) => setTimeout(resolve, 5));

      // Take a screenshot of the scene from the current camera position to use as the thumbnail
      const {
        blob: screenshotBlob,
        cameraTransform: screenshotCameraTransform,
      } = await editor.takeScreenshot();
      screenshotUrl = URL.createObjectURL(screenshotBlob);

      if (signal.aborted) {
        const error = new Error("Publish project aborted");
        error.aborted = true;
        throw error;
      }

      const userInfo = this.getUserInfo();

      // Gather all the info needed to display the publish dialog
      let {
        name,
        creatorAttribution,
        allowRemixing,
        allowPromotion,
      } = scene.metadata;

      name = (project.scene && project.scene.name) || name || editor.scene.name;

      if (project.scene) {
        allowPromotion = project.scene.allow_promotion;
        allowRemixing = project.scene.allow_remixing;
        creatorAttribution = project.scene.attributions.creator || "";
      } else if (
        (!creatorAttribution || creatorAttribution.length === 0) &&
        userInfo &&
        userInfo.creatorAttribution
      ) {
        creatorAttribution = userInfo.creatorAttribution;
      }

      const contentAttributions = scene.getContentAttributions();

      // Display the publish dialog and wait for the user to submit / cancel
      const publishParams = await new Promise((resolve) => {
        showDialog(PublishDialog, {
          screenshotUrl,
          contentAttributions,
          initialSceneParams: {
            name,
            creatorAttribution: creatorAttribution || "",
            allowRemixing:
              typeof allowRemixing !== "undefined" ? allowRemixing : false,
            allowPromotion:
              typeof allowPromotion !== "undefined" ? allowPromotion : false,
          },
          onCancel: () => resolve(null),
          onPublish: resolve,
        });
      });

      // User clicked cancel
      if (!publishParams) {
        URL.revokeObjectURL(screenshotUrl);
        hideDialog();
        const error = new Error("Publish project aborted");
        error.aborted = true;
        throw error;
      }

      // Update the scene with the metadata from the publishDialog
      scene.setMetadata({
        name: publishParams.name,
        creatorAttribution: publishParams.creatorAttribution,
        allowRemixing: publishParams.allowRemixing,
        allowPromotion: publishParams.allowPromotion,
        previewCameraTransform: screenshotCameraTransform,
      });

      // Save the creatorAttribution to localStorage so that the user doesn't have to input it again
      this.setUserInfo({
        creatorAttribution: publishParams.creatorAttribution,
      });

      showDialog(ProgressDialog, {
        title: "Publishing Scene",
        message: "Exporting scene...",
        cancelable: true,
        onCancel: () => {
          abortController.abort();
        },
      });

      // Clone the existing scene, process it for exporting, and then export as a glb blob
      const { glbBlob, scores } = await editor.exportScene(
        abortController.signal,
        { scores: true }
      );

      if (signal.aborted) {
        const error = new Error("Publish project aborted");
        error.aborted = true;
        throw error;
      }

      const performanceCheckResult = await new Promise((resolve) => {
        showDialog(PerformanceCheckDialog, {
          scores,
          onCancel: () => resolve(false),
          onConfirm: () => resolve(true),
        });
      });

      if (!performanceCheckResult) {
        const error = new Error("Publish project canceled");
        error.aborted = true;
        throw error;
      }

      // Serialize Spoke scene
      const serializedScene = editor.scene.serialize();
      const sceneBlob = new Blob([JSON.stringify(serializedScene)], {
        type: "application/json",
      });

      showDialog(ProgressDialog, {
        title: "Publishing Scene",
        message: `Publishing scene`,
        cancelable: true,
        onCancel: () => {
          abortController.abort();
        },
      });

      const size = glbBlob.size / 1024 / 1024;
      const maxSize = this.maxUploadSize;
      if (size > maxSize) {
        throw new Error(
          `Scene is too large (${size.toFixed(
            2
          )}MB) to publish. Maximum size is ${maxSize}MB.`
        );
      }

      showDialog(ProgressDialog, {
        title: "Publishing Scene",
        message: "Uploading thumbnail...",
        cancelable: true,
        onCancel: () => {
          abortController.abort();
        },
      });

      // Upload the screenshot file
      const {
        file_id: screenshotId,
        meta: { access_token: screenshotToken },
      } = await this.upload(screenshotBlob, undefined, abortController.signal);

      if (signal.aborted) {
        const error = new Error("Publish project aborted");
        error.aborted = true;
        throw error;
      }

      const {
        file_id: glbId,
        meta: { access_token: glbToken },
      } = await this.upload(glbBlob, (uploadProgress) => {
        showDialog(
          ProgressDialog,
          {
            title: "Publishing Scene",
            message: `Uploading scene: ${Math.floor(uploadProgress * 100)}%`,
            onCancel: () => {
              abortController.abort();
            },
          },
          abortController.signal
        );
      });

      if (signal.aborted) {
        const error = new Error("Publish project aborted");
        error.aborted = true;
        throw error;
      }

      const {
        file_id: sceneFileId,
        meta: { access_token: sceneFileToken },
      } = await this.upload(sceneBlob, undefined, abortController.signal);

      if (signal.aborted) {
        const error = new Error("Publish project aborted");
        error.aborted = true;
        throw error;
      }

      const sceneParams = {
        screenshot_file_id: screenshotId,
        screenshot_file_token: screenshotToken,
        model_file_id: glbId,
        model_file_token: glbToken,
        scene_file_id: sceneFileId,
        scene_file_token: sceneFileToken,
        allow_remixing: publishParams.allowRemixing,
        allow_promotion: publishParams.allowPromotion,
        name: publishParams.name,
        attributions: {
          creator: publishParams.creatorAttribution,
          content: publishParams.contentAttributions,
        },
      };

      const token = this.getToken();

      const headers = {
        "content-type": "application/json",
        authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({ scene: sceneParams });

      const resp = await this.fetch(
        `https://${RETICULUM_SERVER}/api/v1/projects/${project.project_id}/publish`,
        {
          method: "POST",
          headers,
          body,
        }
      );

      if (signal.aborted) {
        const error = new Error("Publish project aborted");
        error.aborted = true;
        throw error;
      }

      if (resp.status === 401) {
        return await new Promise((resolve, reject) => {
          showDialog(LoginDialog, {
            onSuccess: async () => {
              try {
                const result = await this.publish(
                  editor,
                  showDialog,
                  hideDialog
                );
                resolve(result);
              } catch (e) {
                reject(e);
              }
            },
          });
        });
      }

      if (resp.status !== 200) {
        throw new Error(`Scene creation failed. ${await resp.text()}`);
      }

      project = await resp.json();

      showDialog(PublishedSceneDialog, {
        sceneName: sceneParams.name,
        screenshotUrl,
        sceneUrl: this.getSceneUrl(project.scene.scene_id),
        onConfirm: () => {
          this.emit("project-published");
          hideDialog();
        },
      });
    } finally {
      if (screenshotUrl) {
        URL.revokeObjectURL(screenshotUrl);
      }
    }

    return project;
  }
  */

  async startNewProject(showDialog, hideDialog) {
    const publishing_data = {};
    const landInfo = await new Promise(resolve => {
      showDialog(SelectLandDialog, {
        title: "Pick the Land",
        message: "Where?",
        cancelable: true,
        onCancel: () => resolve(false),
        onConfirm: resolve
      });
    });
    if (!landInfo) {
      const error = new Error("Create project canceled");
      error.aborted = true;
      throw error;
    }

    publishing_data.folderUuid = landInfo.selectedFolderUuid;
    publishing_data.hexId = landInfo.selectedHexId;
    publishing_data.landUuid = landInfo.selectedLandUuid;
    publishing_data.intIds = landInfo.selectedIntIds;
    publishing_data.landLatitude = landInfo.selectedLandLatitude;
    const mapping = await new Promise(resolve => {
      showDialog(SelectMappingDialog, {
        title: "Pick the Mapping",
        message: "Which?",
        cancelable: true,
        onCancel: () => resolve(false),
        onConfirm: resolve,
        selectedLand: landInfo
      });
    });
    if (!mapping) {
      const error = new Error("Create project canceled");
      error.aborted = true;
      throw error;
    }
    publishing_data.ribeyeMapId = mapping?.selectedRibeyeMapId;
    publishing_data.landScanUuid = mapping?.selectedLandScanUuid;
    publishing_data.repositionData = mapping?.repositionData;

    localStorage.setItem("publishing_data", JSON.stringify(publishing_data));
  }

  //TODO
  async publishProject(project, editor, showDialog, hideDialog, { hexId, landUuid, folderUuid, landScanUuid }) {
    console.debug("PP.publishProject", project, editor, showDialog, hideDialog, {
      hexId,
      landUuid,
      folderUuid,
      landScanUuid
    });
    let screenshotUrl;

    try {
      const scene = editor.scene;

      const abortController = new AbortController();
      const signal = abortController.signal;
      const overrideImage = project?.overrideImage;

      // Save the scene if it has been modified.
      if (editor.sceneModified) {
        showDialog(ProgressDialog, {
          title: "Saving Project",
          message: "Saving project...",
          cancelable: true,
          onCancel: () => {
            abortController.abort();
          }
        });

        project = await this.saveProject(project.project_id, editor, signal, showDialog, hideDialog);

        if (signal.aborted) {
          const error = new Error("Publish project aborted");
          error.aborted = true;
          throw error;
        }
      }

      // Ensure the user is authenticated before continuing.
      if (!this.isAuthenticated()) {
        await new Promise(resolve => {
          showDialog(LoginDialog, {
            onSuccess: resolve
          });
        });
      }

      showDialog(ProgressDialog, {
        title: "Generating Project Screenshot",
        message: "Generating project screenshot..."
      });

      // Wait for 5ms so that the ProgressDialog shows up.
      await new Promise(resolve => setTimeout(resolve, 5));

      // Take a screenshot of the scene from the current camera position to use as the thumbnail
      const { blob: screenshotBlob, cameraTransform: screenshotCameraTransform } = await editor.takeScreenshot(
        512,
        320
      );

      screenshotUrl = URL.createObjectURL(screenshotBlob);

      const thumbnailName = editor.scene.name.replace(/\s+/g, "_") + "_thumbnail.png";

      const thumbnailFile = new File([screenshotBlob], thumbnailName);

      if (signal.aborted) {
        const error = new Error("Publish project aborted");
        error.aborted = true;
        throw error;
      }

      const userInfo = this.getUserInfo();

      // Gather all the info needed to display the publish dialog
      let { name, creatorAttribution, allowRemixing, allowPromotion } = scene.metadata;

      name = (project.scene && project.scene.name) || name || editor.scene.name;

      if (project.scene) {
        allowPromotion = project.scene.allow_promotion;
        allowRemixing = project.scene.allow_remixing;
        creatorAttribution = (project.scene.attributions && project.scene.attributions.creator) || "";
      } else if ((!creatorAttribution || creatorAttribution.length === 0) && userInfo && userInfo.creatorAttribution) {
        creatorAttribution = userInfo.creatorAttribution;
      }

      const contentAttributions = scene.getContentAttributions();

      // Display the publish dialog and wait for the user to submit / cancel
      const publishParams = await new Promise(resolve => {
        showDialog(PublishDialog, {
          screenshotUrl,
          contentAttributions,
          initialSceneParams: {
            name,
            overrideImage,
            creatorAttribution: creatorAttribution || "",
            allowRemixing: typeof allowRemixing !== "undefined" ? allowRemixing : false,
            allowPromotion: typeof allowPromotion !== "undefined" ? allowPromotion : false
          },
          onCancel: () => resolve(null),
          onPublish: resolve
        });
      });

      console.debug("publishParams", publishParams);

      // User clicked cancel
      if (!publishParams) {
        URL.revokeObjectURL(screenshotUrl);
        hideDialog();
        const error = new Error("Publish project aborted");
        error.aborted = true;
        throw error;
      }

      // Update the scene with the metadata from the publishDialog
      scene.setMetadata({
        name: publishParams.name,
        overrideImage: publishParams.overrideImage,
        creatorAttribution: publishParams.creatorAttribution,
        allowRemixing: publishParams.allowRemixing,
        allowPromotion: publishParams.allowPromotion,
        nftPass: publishParams.nftPass,
        OVRTarget: publishParams.OVRTarget.value,
        previewCameraTransform: screenshotCameraTransform
      });

      // Save the creatorAttribution to localStorage so that the user doesn't have to input it again
      this.setUserInfo({
        creatorAttribution: publishParams.creatorAttribution
      });

      showDialog(ProgressDialog, {
        title: "Publishing Scene",
        message: "Exporting scene...",
        cancelable: true,
        onCancel: () => {
          abortController.abort();
        }
      });

      // Clone the existing scene, process it for exporting, and then export as a glb blob
      const { glbBlob, scores } = await editor.exportScene(abortController.signal, { scores: true });

      const glbName = editor.scene.name.replace(/\s+/g, "_") + ".glb";

      const glbFile = new File([glbBlob], glbName);

      if (signal.aborted) {
        const error = new Error("Publish project aborted");
        error.aborted = true;
        throw error;
      }

      let hexInfo;
      if (hexId == null || landUuid == null) {
        //TODO inserire three words hex id in publications
        const result = await getUserLands();
        hexInfo = await new Promise(resolve => {
          showDialog(SelectLandDialog, {
            title: "Publication Info",
            message: "Where?",
            landsFromApi: result.data,
            cancelable: true,
            onCancel: () => resolve(false),
            onConfirm: resolve
          });
        });
      } else {
        hexInfo = {
          selectedHexId: hexId,
          selectedLandUuid: landUuid,
          selectedFolderUuid: folderUuid,
          landScanUuid: landScanUuid
        };
      }
      if (!hexInfo) {
        const error = new Error("Publish project canceled");
        error.aborted = true;
        throw error;
      }

      const ovrSettings = await new Promise(resolve => {
        showDialog(OverSettingDialog, {
          title: "Publication Info",
          message: "Ovr Settings",
          cancelable: true,
          onCancel: () => resolve(false),
          onConfirm: resolve
        });
      });
      if (!ovrSettings) {
        const error = new Error("Publish project canceled");
        error.aborted = true;
        throw error;
      }

      // get setting from cookies overSetting
      let overSetting = Cookies.get("overSetting");
      // if (hexInfo.hexOccupied) {
      //   alert("Your tried to publish on occupied soil! Aborting...");
      //   const error = new Error("Publish project canceled");
      //   error.aborted = true;
      //   throw error;
      // }


      const performanceCheckResult = await new Promise(resolve => {
        showDialog(PerformanceCheckDialog, {
          scores,
          onCancel: () => resolve(false),
          onConfirm: () => resolve(true)
        });
      });

      if (!performanceCheckResult) {
        const error = new Error("Publish project canceled");
        error.aborted = true;
        throw error;
      }

      // =============

      // Serialize Spoke scene
      let serializedScene = editor.scene.serialize();
      serializedScene["metadata"]["overSetting"] = JSON.parse(overSetting);

      const sceneBlob = new Blob([JSON.stringify(serializedScene)], {
        type: "application/json"
      });

      const fileName = editor.scene.name.replace(/\s+/g, "_") + ".ovr";
      const projectFile = new File([sceneBlob], fileName);

      showDialog(ProgressDialog, {
        title: "Publishing Scene",
        message: `Publishing scene`,
        cancelable: true,
        onCancel: () => {
          abortController.abort();
        }
      });

      const size = glbBlob.size / 1024 / 1024;
      const maxSize = this.maxUploadSize;
      // if (size > maxSize) {
      //   throw new Error(
      //     `Scene is too large (${size.toFixed(
      //       2
      //     )}MB) to publish. Maximum size is ${maxSize}MB.`
      //   )
      // }

      showDialog(ProgressDialog, {
        title: "Publishing Scene",
        message: "Uploading thumbnail...",
        cancelable: true,
        onCancel: () => {
          abortController.abort();
        }
      });

      const sceneParams = {
        allow_remixing: publishParams.allowRemixing,
        allow_promotion: publishParams.allowPromotion,
        name: publishParams.name,
        attributions: {
          creator: publishParams.creatorAttribution,
          content: publishParams.contentAttributions
        },
        ovr_info: {
          target: publishParams.OVRTarget.value,
          hex_id: hexInfo.selectedHexId[0],
          land_uuid: hexInfo.selectedLandUuid[0],
          folder_uuid: hexInfo.selectedFolderUuid
        },
        environment_occlusion_ar: serializedScene["metadata"]["overSetting"]["overGeolocatedAndroidOcclusion"],
        human_occlusion_ar: serializedScene["metadata"]["overSetting"]["overGeolocatediOSHumanOcclusion"],
        mesh_occlusion_ar: serializedScene["metadata"]["overSetting"]["overGeolocatediOSLidarMeshOcclusion"],
        is_remote_ar_ever: serializedScene["metadata"]["overSetting"]["isRemoteArEver"],
        is_remote_in_walk_mode_default: serializedScene["metadata"]["overSetting"]["overRemoteWalkMode"],
        sdk_version: "0.3.0"
      };


      const json = JSON.stringify(sceneParams);
      const token = this.getToken();
      const provider = this.getProvider();
      const response = await publishProject(
        token,
        provider,
        project.project_id,
        hexInfo,
        json,
        projectFile,
        glbFile,
        publishParams.overrideImage?.file || thumbnailFile,
        publishParams.overrideVideoImage?.file || null,
        publishParams.nftPass,
        uploadProgress => {
          showDialog(
            ProgressDialog,
            {
              title: "Publishing Scene",
              message: `Uploading scene: ${Math.floor(uploadProgress * 100)}%`,
              onCancel: () => {
                abortController.abort();
              }
            },
            abortController.signal
          );
        },
        signal
      );
      // const response = await publishProject(
      //   token,
      //   provider,
      //   project.project_id,
      //   hexInfo.selectedLandUuid,
      //   json,
      //   projectFile,
      //   glbFile,
      //   thumbnailFile,
      //   (uploadProgress) => {
      //     showDialog(
      //       ProgressDialog,
      //       {
      //         title: 'Publishing Scene',
      //         message: `Uploading scene: ${Math.floor(uploadProgress * 100)}%`,
      //         onCancel: () => {
      //           abortController.abort()
      //         },
      //       },
      //       abortController.signal
      //     )
      //   },
      //   signal
      // )


      if (signal.aborted) {
        const error = new Error("Publish project aborted");
        error.aborted = true;
        throw error;
      }

      //const token = this.getToken();
      /*
      const headers = {
        "content-type": "application/json",
        authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({ scene: sceneParams });

      const resp = await this.fetch(
        `https://${RETICULUM_SERVER}/api/v1/projects/${project.project_id}/publish`,
        {
          method: "POST",
          headers,
          body,
        }
      );
      

      if (signal.aborted) {
        const error = new Error("Publish project aborted");
        error.aborted = true;
        throw error;
      }*/
      /*
      if (resp.status === 401) {
        return await new Promise((resolve, reject) => {
          showDialog(LoginDialog, {
            onSuccess: async () => {
              try {
                const result = await this.publish(
                  editor,
                  showDialog,
                  hideDialog
                );
                resolve(result);
              } catch (e) {
                reject(e);
              }
            },
          });
        });
      }

      if (resp.status !== 200) {
        throw new Error(`Scene creation failed. ${await resp.text()}`);
      }
      */

      //project = await resp.json();

      // Se la pubblicazione è andata a buon fine e non è assegnata una land, preparo il link alla preview web
      let previewUrl = null
      if (response.data.file.uuid) {
        const authTokenResponse = await generateLoginToken();
        const authToken = authTokenResponse?.data?.token;
        if (authToken) {
          const userUuid = localStorage.getItem("__userUuid");
          if (userUuid == '7094c01c-e623-11ea-bf5e-685b35a4c6c8' || userUuid == '75e89210-af44-11eb-a37b-00163e0053f7') {
            previewUrl = `https://experiences.ovr.ai?id=${response.data.file.uuid}&auth=${authToken}`
          }
        }
      }

      showDialog(PublishedSceneDialog, {
        sceneName: sceneParams.name,
        screenshotUrl: response.data.file.thumbnailUrl,
        sceneUrl: response.data.file.fileOvr.fileUrl,
        previewUrl: previewUrl,
        onConfirm: () => {
          this.emit("project-published");
          hideDialog();
        }
      });
    } finally {
      if (screenshotUrl) {
        URL.revokeObjectURL(screenshotUrl);
      }
    }

    return project;
  }

  async uploadProjectFile(file, thumbnail, onUploadProgress, signal, land_uuid, land_scan_uuid, folder_uuid) {
    const token = this.getToken();
    const provider = this.getProvider();
    return await uploadProject(
      file,
      thumbnail,
      token,
      provider,
      null,
      onUploadProgress,
      signal,
      land_uuid,
      land_scan_uuid,
      folder_uuid
    );
  }
  async uploadAssetFile(file, thumbnail, onUploadProgress, signal) {
    const token = this.getToken();
    const provider = this.getProvider();
    return await uploadFile(file, thumbnail, token, provider, null, onUploadProgress, signal);
  }

  async overwrite(file, thumbnail, project_id, onUploadProgress, signal) {
    const token = this.getToken();
    const provider = this.getProvider();
    return await updateProject(file, thumbnail, token, provider, project_id, onUploadProgress, signal);
  }

  async upload(blob, onUploadProgress, signal) {
    // Use direct upload API, see: https://github.com/mozilla/reticulum/pull/319
    const { phx_host: uploadHost } = await (await this.fetch(`https://${RETICULUM_SERVER}/api/v1/meta`)).json();
    const uploadPort = new URL(`https://${RETICULUM_SERVER}`).port;

    return await new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();

      const onAbort = () => {
        request.abort();
        const error = new Error("Upload aborted");
        error.name = "AbortError";
        error.aborted = true;
        reject(error);
      };

      if (signal) {
        signal.addEventListener("abort", onAbort);
      }

      request.open("post", `https://${uploadHost}:${uploadPort}/api/v1/media`, true);

      request.upload.addEventListener("progress", e => {
        if (onUploadProgress) {
          onUploadProgress(e.loaded / e.total);
        }
      });

      request.addEventListener("error", error => {
        if (signal) {
          signal.removeEventListener("abort", onAbort);
        }
        reject(new RethrownError("Upload failed", error));
      });

      request.addEventListener("load", () => {
        if (signal) {
          signal.removeEventListener("abort", onAbort);
        }

        if (request.status < 300) {
          const response = JSON.parse(request.responseText);
          resolve(response);
        } else {
          reject(new Error(`Upload failed ${request.statusText}`));
        }
      });

      const formData = new FormData();
      formData.set("media", blob);

      request.send(formData);
    });
  }

  uploadAssets(editor, files, onProgress, signal) {
    return this._uploadAssets(`https://${RETICULUM_SERVER}/api/v1/assets`, editor, files, onProgress, signal);
  }

  async _uploadAssets(endpoint, editor, files, onProgress, signal) {
    const assets = [];

    for (const file of Array.from(files)) {
      if (signal.aborted) {
        break;
      }

      const abortController = new AbortController();
      const onAbort = () => abortController.abort();
      signal.addEventListener("abort", onAbort);

      const asset = await this._uploadAsset(
        endpoint,
        editor,
        file,
        progress => onProgress(assets.length + 1, files.length, progress),
        abortController.signal
      );

      assets.push(asset);
      signal.removeEventListener("abort", onAbort);

      if (signal.aborted) {
        break;
      }
    }


    return assets;
  }

  uploadAsset(editor, file, onProgress, signal) {
    return this._uploadAsset(`https://${RETICULUM_SERVER}/api/v1/assets`, editor, file, onProgress, signal);
  }

  uploadProjectAsset(editor, projectId, file, onProgress, signal) {
    return this._uploadAsset(
      `https://${RETICULUM_SERVER}/api/v1/projects/${projectId}/assets`,
      editor,
      file,
      onProgress,
      signal
    );
  }

  lastUploadAssetRequest = 0;
  /*
  async _uploadAsset(endpoint, editor, file, onProgress, signal) {
    let thumbnail_file_id = null;
    let thumbnail_access_token = null;
    
    if (!matchesFileTypes(file, AudioFileTypes)) {
      const thumbnailBlob = await editor.generateFileThumbnail(file);

      const response = await this.uploadAssetFile(thumbnailBlob, undefined, signal);

      thumbnail_file_id = response.file_id;
      thumbnail_access_token = response.meta.access_token;
    }

    const response = await this.uploadAssetFile(file, onProgress, signal);

    const delta = Date.now() - this.lastUploadAssetRequest;

    if (delta < 1100) {
      await new Promise((resolve) => setTimeout(resolve, 1100 - delta));
    }

    const {
      file_id: asset_file_id,
      meta: { access_token: asset_access_token }
    } = response;

    

    const token = this.getToken();

    const headers = {
      "content-type": "application/json",
      authorization: `Bearer ${token}`
    };

    const body = JSON.stringify({
      asset: {
        name: file.name,
        file_id: asset_file_id,
        access_token: asset_access_token,
        thumbnail_file_id,
        thumbnail_access_token
      }
    });

    const resp = await this.fetch(endpoint, { method: "POST", headers, body, signal });

    const json = await resp.json();

    const asset = json.assets[0];

    this.lastUploadAssetRequest = Date.now();

    return {
      id: response.data.file.fileUuid,
      name: file.name,
      url: response.data.file.downloadUrl,
      type: response.data.file.fileExtension,
      attributions: {},
      /*images: {
        preview: { url: asset.thumbnail_url }
      }
    };
  }
  */

  async _uploadAsset(endpoint, editor, file, onProgress, signal) {
    let thumbnail_file_id = null;
    let thumbnail_access_token = null;
    let thumbnailBlob = null;

    //se non è un audio fa il thumbnail
    if (!matchesFileTypes(file, AudioFileTypes)) {
      thumbnailBlob = await editor.generateFileThumbnail(file);
    }

    const response = await this.uploadAssetFile(file, thumbnailBlob, onProgress, signal);

    const delta = Date.now() - this.lastUploadAssetRequest;

    if (delta < 1100) {
      await new Promise(resolve => setTimeout(resolve, 1100 - delta));
    }
    /*

    const {
      file_id: asset_file_id,
      meta: { access_token: asset_access_token }
    } = response;


    const body = JSON.stringify({
      asset: {
        name: file.name,
        file_id: asset_file_id,
        access_token: asset_access_token,
        thumbnail_file_id,
        thumbnail_access_token
      }
    });    
*/
    this.lastUploadAssetRequest = Date.now();

    return {
      id: response.data.file.fileUuid,
      name: file.name,
      url: response.data.file.downloadUrl,
      type: response.data.file.fileExtension,
      //thumbnail stuff
      attributions: {}
      /*images: {
        preview: { url: asset.thumbnail_url }
      }
      */
    };
  }

  async deleteAsset(assetId) {
    const token = this.getToken();
    const provider = this.getProvider();
    const resp = await deleteFile(token, provider, assetId);
    if (resp.result == false) {
      throw new Error(`Asset deletion failed. Reason: ${resp.error}`);
    }
    return true;
  }

  async deleteProjectAsset(projectId, assetId) {
    const token = this.getToken();

    const headers = {
      "content-type": "application/json",
      authorization: `Bearer ${token}`
    };

    const projectAssetEndpoint = `https://${RETICULUM_SERVER}/api/v1/projects/${projectId}/assets/${assetId}`;

    const resp = await this.fetch(projectAssetEndpoint, {
      method: "DELETE",
      headers
    });

    if (resp.status === 401) {
      throw new Error("Not authenticated");
    }

    if (resp.status !== 200) {
      throw new Error(`Project Asset deletion failed. ${await resp.text()}`);
    }

    return true;
  }

  setUserInfo(userInfo) {
    localStorage.setItem("spoke-user-info", JSON.stringify(userInfo));
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem("spoke-user-info"));
  }

  async fetch(url, options) {
    try {
      const res = await fetch(url, options);

      if (res.ok) {
        return res;
      }

      const err = new Error(
        `Network Error: ${res.status || "Unknown Status."} ${res.statusText || "Unknown Error. Possibly a CORS error."}`
      );
      err.response = res;
      throw err;
    } catch (error) {
      if (error.message === "Failed to fetch") {
        error.message += " (Possibly a CORS error)";
      }
      throw new RethrownError(`Failed to fetch "${url}"`, error);
    }
  }
}
