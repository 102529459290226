/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import { isEmpty, isNil, pathOr } from "ramda";

import Avatar from "@material-ui/core/Avatar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const sizes = {
  xsmall: 27,
  small: 33,
  medium: 40,
  mediumLarge: 60,
  large: 70,
  xlarge: 130
};

const NftClip = ({ ...other }) => {
  return (
    <svg {...other} viewBox="0 0 1080 1080">
      <path d="M0 0v1080h1080V0H0zm1009.7 748.3c0 34.6-24.5 77-54.4 94.3L594.4 1051c-29.9 17.3-78.9 17.3-108.9 0L124.7 842.6c-29.9-17.3-54.4-59.7-54.4-94.3V331.7c0-34.6 24.5-77 54.4-94.3L485.6 29c29.9-17.3 78.9-17.3 108.9 0l360.8 208.3c29.9 17.3 54.4 59.7 54.4 94.3v416.7z" />
    </svg>
  );
};

const UserImage = ({
  isNft = false,
  size = "small",
  user,
  style,
  customImageUrl = null,
  overrideNftClipBackground = null,
  children,
  ...other
}) => {
  if (isNft) {
    return (
      <NftClipper
        key="nft"
        className="nft"
        overrideNftClipBackground={overrideNftClipBackground}
        style={{ width: sizes[size], height: sizes[size], ...style }}
        {...other}
      >
        {children}
        <NftClip className={`clip`} />
        <div
          className="image"
          style={{
            backgroundImage: `url('${pathOr(customImageUrl, ["userPicUrl"], user)}')`
          }}
        />
      </NftClipper>
    );
  }

  if (isEmpty(user?.userPicUrl) || isNil(user?.userPicUrl)) {
    return (
      <Avatar
        alt="Profile Image"
        src={pathOr(customImageUrl, ["userPicUrl"], user)}
        sx={{ width: sizes[size], height: sizes[size] }}
        style={{ width: sizes[size], height: sizes[size], ...style }}
        {...other}
      >
        <AccountCircleIcon
          style={{
            width: size == "xlarge" ? sizes["large"] : sizes[size],
            height: size == "xlarge" ? sizes["large"] : sizes[size]
          }}
        />
        {children}
      </Avatar>
    );
  }

  return (
    <StyledImage style={{ width: sizes[size], height: sizes[size], ...style }} {...other}>
      <img src={pathOr(customImageUrl, ["userPicUrl"], user)} alt="User Image" {...other} />

      {children}
    </StyledImage>
  );
};

export default UserImage;

const NftClipper = styled.div`
  position: relative;
  overflow: hidden;

  .image {
    background-size: cover;
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
  }
  .clip {
    position: absolute;
    z-index: 3;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    path {
      fill: ${props => (!isNil(props.overrideNftClipBackground) ? props.overrideNftClipBackground : "#585858")};
    }
  }
`;

const StyledImage = styled.div`
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
