import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

const supportedLangs = ['en', 'zh']


import enTranslation from './public/assets/i18n/translations/en.json';
import zhTranslation from './public/assets/i18n/translations/zh.json';

const resources = {
  en: {
    translation: enTranslation,
  },
  zh: {
    translation: zhTranslation,
  }
};

console.log('enTranslation', enTranslation)

i18n
.use(Backend)
.use(initReactI18next)
.init({
  resources,
  lng: "en",
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false // react already safes from xss
  },
  fallbackLng: 'en',
  debug: true,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    wait: true,
    useSuspense: false,
  },
  // lng: localStorage.getItem('i18nextLng'),
  // backend: {
  //   /* translation file path */
  //   loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
  // },
  // fallbackLng: 'en',
  // debug: true,
  // /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
  // ns: ['translations'],
  // defaultNS: 'translations',
  // keySeparator: false,
  // interpolation: {
  //   escapeValue: false,
  //   formatSeparator: ',',
  // },
})


export function getCurrentLocale() {
  return i18n.language || window.localStorage.i18nextLng || ''
}

export default i18n
