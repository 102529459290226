import Cookies from "js-cookie";
import config from "./config";

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

//  Cookies.set(tokenName, tokenValue)
//  Cookies.set(tokenName, tokenValue, { domain: config.apis.cookieDomain })
export function saveToken(tokenName = "token", tokenValue) {
  Cookies.set(
    tokenName,
    tokenValue
    // { domain: config.apis.cookieDomain }
  );
}

export function saveSketchfabToken(tokenName = "sketchfabToken", tokenValue) {
  Cookies.set(tokenName, tokenValue, addDays(new Date(), 28));
}

export function savePOAPToken(tokenName = "POAPToken", tokenValue) {
  Cookies.set(tokenName, tokenValue, addDays(new Date(), 28));
}

export function getToken(tokenName = "token") {
  return Cookies.get(tokenName);
}

export function removeToken(tokenName = "token") {
  Cookies.remove(tokenName);
  Cookies.remove(tokenName, { domain: config.apis.cookieDomain });
}

export function checkToken(tokenName = "token") {
  return Cookies.get(tokenName) !== undefined;
}

export function saveUser(cookieName = "user", cookieValue) {
  Cookies.set(cookieName, cookieValue, { domain: config.apis.cookieDomain });
}

export function getUser(cookieName = "userToken") {
  return Cookies.get(cookieName);
}

export function getSketchFabToken(cookieName = "sketchfabToken") {
  return Cookies.get(cookieName);
}

export function removeUser(cookieName = "userToken") {
  Cookies.remove(cookieName);
  Cookies.remove(cookieName, { domain: config.apis.cookieDomain });
}

export function isLogged() {
  return getUser() !== undefined;
}

export function isScketchfabLogged() {
  return getSketchFabToken() !== undefined;
}
