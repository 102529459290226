import React, { Component, useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { withApi } from "../contexts/ApiContext";
import { UserContext, withUserContext } from '../contexts/UserContext'

const LogoutPage = (props) => {
  const { state: userState, actions: userActions } = useContext(UserContext);

  useEffect(() => {
    userActions.logoutUser()
  }, [])

  return <Redirect to="/" />;
  
}


export default withUserContext(withApi(LogoutPage))
