import React from "react";
import Flickity from "react-flickity-component";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import FirstImage from "../../assets/img_firstsection.png";
import SecondImage from "../../assets/img_firstsection_m2e.png";
import ThirdImage from "../../assets/img_firstsection_creator.png";
import FourthImage from "../../assets/img_banner_789x715.png";

export const ProjectsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
`;

const WelcomeContainer = styled(ProjectsContainer)`
  .head-content {
    display: flex;
    align-items: center;
    justify-content: center;
    &.second {
      img {
        transform: scale(0.85);
      }
    }
    &.third {
      img {
        transform: scale(0.85);
      }
    }
  }
  h1 {
    font-size: 15px !important;
  }
  h2 {
    line-height: 60px;
    font-size: 53px !important;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
    @media screen and (max-width: 500px) {
      font-size: 40px !important;
      line-height: 48px;
    }
  }
  h4 {
    font-size: 20px !important;
    line-height: 24px !important;
    @media screen and (max-width: 500px) {
      font-size: 17px !important;
      line-height: 22px !important;
    }
  }

  img {
    max-width: 500px;
    height: auto;
    @media screen and (max-width: 500px) {
      max-width: 250px;
    }
  }

  /*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

  .flickity-enabled {
    position: relative;
  }

  .flickity-enabled:focus {
    outline: none;
  }

  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  /* draggable */

  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  /* ---- flickity-button ---- */

  .flickity-button {
    position: absolute;
    background: hsla(0, 0%, 100%, 0.75);
    border: none;
    color: #333;
  }

  .flickity-button:hover {
    background: white;
    cursor: pointer;
  }

  .flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19f;
  }

  .flickity-button:active {
    opacity: 0.6;
  }

  .flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
  }

  .flickity-button-icon {
    fill: #333;
  }

  /* ---- previous/next buttons ---- */

  .flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    /* vertically center */
    transform: translateY(-50%);
  }

  .flickity-prev-next-button.previous {
    left: 10px;
  }
  .flickity-prev-next-button.next {
    right: 10px;
  }
  /* right to left */
  .flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
  }
  .flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
  }

  .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }

  /* ---- page dots ---- */

  .flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: 0px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
  }

  .flickity-rtl .flickity-page-dots {
    direction: rtl;
  }

  .flickity-page-dots .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: white;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
  }

  .flickity-page-dots .dot.is-selected {
    opacity: 1;
  }
`;

const flickityOptions = {
  prevNextButtons: false,
  wrapAround: true,
  autoPlay: 3000
};

const Slider = () => {
  return (
    <WelcomeContainer>
      <Flickity
        className={"carousel"} // default ''
        elementType={"div"} // default 'div'
        options={flickityOptions} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default false
      >
        <Grid container spacing={3} key={1}>
          <Grid item xs={12} sm={6} style={{ display: "flex", alignItems: "center" }}>
            <div className="head-content">
              <div>
                <h1>Map the world in 3D</h1>
                <h2>Add the creations to your OVRLand.</h2>
                <h4>Create a more amazing AR metaverse.</h4>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="head-content first">
              <img src={FourthImage} />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3} key={1}>
          <Grid item xs={12} sm={6} style={{ display: "flex", alignItems: "center" }}>
            <div className="head-content">
              <div>
                <h1>OVER Web Builder - Beta</h1>
                <h2>Take Your OVRLand to the Next Level</h2>
                <h4>
                  Building on your own digital land can be both fun and essential for creating a unique and personalized
                  virtual experience.
                </h4>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="head-content first">
              <img src={FirstImage} />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} style={{ display: "flex", alignItems: "center" }}>
            <div className="head-content">
              <div>
                <h1>OVER Chainlink Contest</h1>
                <h2>Join the contest with Map2Earn</h2>
                <h4>
                  Map, upload, share and try to climb the Chainlink leaderboards to win amazing prizes in our metaverse.
                </h4>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="head-content second">
              <img src={SecondImage} />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} style={{ display: "flex", alignItems: "center" }}>
            <div className="head-content">
              <div>
                <h1>OVER Creators Network</h1>
                <h2>Let your creations shine and inspire</h2>
                <h4>
                  If you’re searching for a place where ideas can be shared, buyed and solded you’ve found the right
                  network.
                </h4>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="head-content third">
              <img src={ThirdImage} />
            </div>
          </Grid>
        </Grid>
      </Flickity>
    </WelcomeContainer>
  );
};

export default Slider;
