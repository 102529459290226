import React, { createContext, Component } from 'react'
import {
  removeToken,
  saveToken,
  isLogged,
  getToken,
  removeUser,
  getPublicAddress,
} from '../../api/lib/auth'
import {
  successNotification,
  networkError,
  dangerNotification,
  warningNotification,
} from '../../api/lib/notifications'
import {
  userProfile,
  getUserNonce,
  signUpPublicAddress,
  signIn,
  sendPreAuctionStart,
  sendConfirmAuctionStart,
  sendPreAuctionBid,
  signUpLoginMetamask,
  getGasPrice,
  signUpMetamask,
  signInMetamask,
  updateLandMarketStatusIfHasBeenMinted,
} from '../../api/lib/api'
import config from '../../api/lib/config'
import { promisifyAll } from 'bluebird'
import { ethers, BigNumber, utils } from 'ethers'
import { UserContext } from './UserContext'
import { useHistory } from 'react-router-dom'

import ovrAbi from '../../contracts/ovrAbi'

export const Web3Context = createContext()

export class Web3Provider extends Component {
  static contextType = UserContext
  constructor(props) {
    super(props)
    this.state = {
      setupComplete: false,
      ibcoSetupComplete: false,
      ibcoLoadedHistory: false,
      provider: null,
      signer: null,
      address: null,
      ovrsOwned: 0,
      gasLandCost: 0,
      perEth: 0,
      perUsd: 0,
      ibcoCurrentOvrPrice: 0.06,
      lastTransaction: '0x0',
      ibcoOpenBuyOrders: [],
      ibcoOpenSellOrders: [],
      ibcoClaims: [],
      ibcoMyClaims: [],
    }
  }

  //
  //	Setup web3 and centralized login
  //

  componentDidMount() {
    // If logged setup Web3
    if (isLogged()) {
      this.setupWeb3((res) => {
        if (res == false && getToken('loginMethod') === 'decentralized') {
          this.context.actions.logoutUser()
        }
      }, false)
    }
  }

  keepUpdatedPublicAddress = () => {
    if (this.keepUpdatedPublicAddressInterval) return

    this.keepUpdatedPublicAddressInterval = setInterval(async () => {
      // TODO @GREG
      if (
        this.context.state.isLoggedIn &&
        !R.isNil(this.context.state.user?.publicAddress) &&
        !R.isEmpty(this.context.state.user?.publicAddress) &&
        !R.isNil(this.state.address)
      ) {
        let sign = await this.state.signer.getAddress()
        if (this.context.state.user.publicAddress !== sign.toLowerCase()) {
          removeCookie('userToken')
          window.location = '/'
        }
      }
    }, 1000)
  }

  setupWeb3 = async (callback, login = true, showNotification = true) => {
    if (typeof web3 !== 'undefined') {
      await window.ethereum.enable()
      let provider = new ethers.providers.Web3Provider(window.ethereum)
      let network = await provider.getNetwork()
      let chainId = network.chainId
      let signer = provider.getSigner(0)
      let address = await signer.getAddress()

      if (address == undefined) {
        // Metamask found, but not logged in
        callback(false)
      } else {
        // Metamask found, logged in, check chainId
        let web3NetworkVersion = config.web3network.includes(parseInt(chainId))
        if (web3NetworkVersion === false) {
          // Wrong Network
          if (showNotification === true) {
            warningNotification(
              this.props.t('Warning.metamask.wrong.network.title'),
              this.props.t('Warning.metamask.wrong.network.desc')
            )
          }
          callback(false)
          return false
        }

        // On change network
        // The "any" network will allow spontaneous network changes
        // AUTOREFRESH ON CHANGE NETWORK
        let providerListener = new ethers.providers.Web3Provider(
          window.ethereum,
          'any'
        )
        providerListener.on('network', (newNetwork, oldNetwork) => {
          if (oldNetwork) {
            window.location.reload()
          }
        })

        // Metamask found, logged in, chainId correct

        let block = await provider.getBlock()

        await this.setState({ ibcoBlock: BigNumber.from(block.number) })

        this.setState(
          {
            provider: provider,
            signer: signer,
            address: address,
            chainId: chainId,
            setupComplete: true,
            currentNetworkId: chainId,
          },
          async () => {
            if (login == true) {
              this.handleCentralizedLogin(address, callback)
            }
            this.keepUpdatedPublicAddress()

            if ([1, 56, 137].includes(chainId)) {
              let data = await this.initializeContracts()
              await this.setSigners(data)
            }
          }
        )

        // Centralized Login
        // if (login == true) {
        //   await this.handleCentralizedLogin(address, callback)
        // }
      }
    } else {
      // Metamask not detected
      warningNotification(
        this.props.t('Warning.metamask.not.detected.title'),
        this.props.t('Warning.metamask.not.detected.desc')
      )
      callback(false)
      return false
    }
  }

  setSigners = async (data) => {
    if (this.state.currentNetworkId == 1) {
      this.setState({
        ETHOvrSigner: data.ETHOvrSigner,
        ETHOvrViewer: data.ETHOvrViewer,
      })
    }

    if (this.state.currentNetworkId == 56) {
      this.setState({
        BSCOvrSigner: data.BSCOvrSigner,
        BSCOvrViewer: data.BSCOvrViewer,
      })
    }

    if (this.state.currentNetworkId == 137) {
      this.setState({
        PolygonOvrSigner: data.PolygonOvrSigner,
        PolygonOvrViewer: data.PolygonOvrViewer,
      })
    }
  }

  initializeContracts = () => {
    if (this.state.currentNetworkId == 1) {
      // Ethereum
      let ETHOvrSigner = new ethers.Contract(
        config.apis.OVREthereumToken,
        ovrAbi,
        this.state.signer
      )
      let ETHOvrViewer = new ethers.Contract(
        config.apis.OVREthereumToken,
        ovrAbi,
        this.state.provider
      )

      let data = {
        ETHOvrSigner,
        ETHOvrViewer,
      }

      return data
    }

    if (this.state.currentNetworkId == 56) {
      // BSC
      let BSCOvrSigner = new ethers.Contract(
        config.apis.OVRBSCToken,
        ovrAbi,
        this.state.signer
      )
      let BSCOvrViewer = new ethers.Contract(
        config.apis.OVRBSCToken,
        ovrAbi,
        this.state.provider
      )

      let data = {
        BSCOvrSigner,
        BSCOvrViewer,
      }

      return data
    }

    if (this.state.currentNetworkId == 137) {
      // Polygon
      let PolygonOvrSigner = new ethers.Contract(
        config.apis.OVRPolygonToken,
        ovrAbi,
        this.state.signer
      )
      let PolygonOvrViewer = new ethers.Contract(
        config.apis.OVRPolygonToken,
        ovrAbi,
        this.state.provider
      )

      let data = {
        PolygonOvrSigner,
        PolygonOvrViewer,
      }

      return data
    }
  }

  handleCentralizedLogin = (publicAddress, callback) => {
    // send signup request to get the nonce
    signUpMetamask(publicAddress).then((response) => {
      if (!response.data.result) return callback(response)

      console.log('signup response', response)

      // get signature from nonce
      this.state.signer.signMessage(response.data.nonce).then((signature) => {
        // send publci adress and signature to login user
        signInMetamask(publicAddress, signature).then((response) => {
          console.log('signin response', response)

          // manage response data or error
          if (response.data.result === true) {
            this.context.actions.loginUser(
              response.data.token,
              response.data.user,
              'centralized'
            )
          } else {
          }

          console.log('signin response', response)

          // manage response data or error
          if (response.data.result) {
            this.context.actions.loginUser(
              response.data.token,
              response.data.user,
              'centralized'
            )
          } else {
          }

          callback(response)
        })
      })
    })
  }

  handleUserSignMessage = async (publicAddress, nonce, callback) => {
    let signature = await this.state.signer.signMessage(
      `I am signing my one-time nonce: ${nonce}`
    )
    this.handleAuthenticate(publicAddress, signature, callback)
  }

  handleAuthenticate = (publicAddress, signature, callback) => {
    signIn(publicAddress, signature).then((response) => {
      if (response.data.result === true) {
        // Save data in store
        this.context.actions.loginUser(
          response.data.token,
          response.data.user,
          'decentralized'
        )

        // TODO Set token
        console.log('response.data', response.data)
        if (callback) {
          callback()
        }
      } else {
        dangerNotification(
          this.props.t('Danger.unable.login.title'),
          response.data.errors[0].message
        )
      }
    })
  }

  keepUpdatedPublicAddress = (async) => {
    setInterval(async () => {
      if (this.context.state.hasLoaded === true) {
        if (
          this.context.state.user.publicAddress !== undefined &&
          this.state.address !== null
        ) {
          let sign = await this.state.signer.getAddress()
          if (this.context.state.user.publicAddress !== sign.toLowerCase()) {
            removeUser()
            window.location = '/'
          }
        }
      }
    }, 1000)
  }

  authorizeOvrExpense = async (ovr = '10000000') => {
    window.ethereum.enable()

    console.debug('authorizeOvrExpenseTEST', {
      conf: config.apis.OVRContract,
      ovrAbi,
      signer: this.state.signer,
    })
    let contractAsAccount = new ethers.Contract(
      config.apis.OVRContract,
      ovrAbi,
      this.state.signer
    )
    const howMuchTokens = ethers.utils.parseUnits(ovr, 18)
    await contractAsAccount.approve(config.apis.walletApproved, howMuchTokens)
  }

  render() {
    return (
      <Web3Context.Provider
        value={{
          state: this.state,
          actions: {
            setupWeb3: this.setupWeb3,
            authorizeOvrExpense: this.authorizeOvrExpense,
            getUSDValueInOvr: this.getUSDValueInOvr,
            mintLightMintedLand: this.mintLightMintedLand,
            setRewardBalance: this.setRewardBalance,
            calculateCustomBuyPrice: this.calculateCustomBuyPrice,
            calculateCustomSellPrice: this.calculateCustomSellPrice,
            calculateCustomBuySlippage: this.calculateCustomBuySlippage,
            calculateCustomSellSlippage: this.calculateCustomSellSlippage,
          },
        }}
      >
        {this.props.children}
      </Web3Context.Provider>
    )
  }
}

export function withWeb3Context(Component) {
  class ComponentWithContext extends React.Component {
    render() {
      return (
        <Web3Context.Consumer>
          {(value) => <Component {...this.props} web3Provider={{ ...value }} />}
        </Web3Context.Consumer>
      )
    }
  }

  return ComponentWithContext
}
