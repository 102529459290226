/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ThreediumRequestGridItem from "./ThreediumRequestGridItem";
import { Link } from "react-router-dom";
import { Plus } from "styled-icons/fa-solid/Plus";

export function LoadingProjectGridItem() {
  return (
    <ProjectGridItemContainer>
      <h3>Loading...</h3>
    </ProjectGridItemContainer>
  );
}

const ProjectGridItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  border-radius: 6px;
  text-decoration: none;
  /* background-color: ${props => props.theme.toolbar}; */
  background-color: white;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
 color: #D0D4E0;
  &:hover {
   color: #D0D4E0;
    border-color: ${props => props.theme.selected};
  }

  svg {
    width: 3em;
    height: 3em;
    margin-bottom: 20px;
  }

  h3 {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
`;

const StyledProjectGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
`;
export function NewProjectGridItem({ path, label }) {
  return (
    <ProjectGridItemContainer as={Link} to={path}>
      <Plus />
      <h3>{label}</h3>
    </ProjectGridItemContainer>
  );
}

export function ThreediumRequestsGrid({
  newProjectPath,
  newProjectLabel,
  requests,
  contextMenuId,
  loading,
  callback // to refresh threedium request
}) {
  if (requests.length === 0) return null;

  return (
    <StyledProjectGrid>
      {newProjectPath && <NewProjectGridItem path={newProjectPath} label={newProjectLabel} />}

      {requests.map(request => (
        <ThreediumRequestGridItem
          key={request.uuid}
          request={request}
          contextMenuId={contextMenuId}
          callback={callback}
        />
      ))}
      {loading && <LoadingProjectGridItem />}
    </StyledProjectGrid>
  );
}

ThreediumRequestsGrid.propTypes = {
  contextMenuId: PropTypes.string,
  requests: PropTypes.arrayOf(PropTypes.object).isRequired,
  newProjectPath: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loadProjectPath: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  newProjectLabel: PropTypes.string,
  loadProjectLabel: PropTypes.string,
  loadAction: PropTypes.func,
  loading: PropTypes.bool
};
