import React, { Component } from "react";
import PropTypes from "prop-types";
import { withApi } from "../ui/contexts/ApiContext";
import { trackEvent } from "../telemetry";

import AuthEmailSentMessage from "./AuthEmailSentMessage";
import AuthForm from "./AuthForm";

import { signIn, getUserNonce, signUpPublicAddress } from "./lib/api";

class AuthContainer extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    onSuccess: PropTypes.func,
    onChange: PropTypes.func,
  };

  state = {
    error: null,
    //emailSent: false,
    //email: null,
    name: null,
    idSent: false,
    id: null,
    accessTokenSent: false,
    acessToken: null,
    abortController: null,
  };

  //email -> accessToken
  /*
  onSubmit = email => {
    if (email.trim().length === 0) {
      return;
    }

    const abortController = new AbortController();

    this.props.api
      .authenticate(email, abortController.signal)
      .then(this.onSuccess)
      .catch(this.onError);

    const nextState = { emailSent: true, email, abortController };

    if (this.props.onChange) {
      this.props.onChange(nextState);
    }

    trackEvent("Login Submitted");

    this.setState(nextState);
  };
  */

  getState = () => {
    return {
      error: this.state.error,
      name: this.state.name,
      idSent: this.state.idSent,
      id: this.state.id,
      accessTokenSent: this.state.accessTokenSent,
      acessToken: this.state.acessToken,
      abortController: this.state.abortController,
    };
  };

  onSubmit = (acessToken, provider, expiresIn) => {
    if (acessToken.trim().length === 0) {
      return;
    }

    if (provider.trim().length === 0) {
      return;
    }

    const abortController = new AbortController();

    // this.props.api
    //   .authenticate(id, name, acessToken, expiresIn, abortController.signal)
    //   .then(this.onSuccess)
    //   .catch(this.onError);

    this.props.api
      .authenticate(acessToken, provider)
      .then(this.onSuccess)
      .catch(this.onError);

    //const nextState = { emailSent: true, email, abortController };
    const nextState = {
      acessTokenSent: true,
      acessToken,
      abortController,
    };

    if (this.props.onChange) {
      this.props.onChange(nextState);
    }

    trackEvent("Login Submitted");

    this.setState(nextState);
  };

  onSuccess = (...args) => {
    trackEvent("Login Successful");
    if (this.props.onSuccess) {
      this.props.onSuccess(...args);
    }
  };

  onError = (err) => {
    const nextState = {
      acessTokenSent: false,
      acessToken: null,
      error: err.message || "Error signing in. Please try again.",
      abortController: null,
    };

    if (this.props.onChange) {
      this.props.onChange(nextState);
    }

    trackEvent("Login Error");

    this.setState(nextState);
  };

  onCancel = () => {
    const nextState = { emailSent: false, abortController: null };
    //const nextState = { acessTokenSent: false, abortController: null };

    if (this.state.abortController) {
      this.state.abortController.abort();
    }

    if (this.props.onChange) {
      this.props.onChange(nextState);
    }

    this.setState(nextState);

    trackEvent("Login Canceled");
  };

  render() {
    if (this.state.emailSent) {
      return (
        <AuthEmailSentMessage
          email={this.state.email}
          onCancel={this.onCancel}
        />
      );
    }

    if (this.state.accessTokenSent) {
      return (
        <AuthEmailSentMessage
          accessToken={this.state.accessToken}
          onCancel={this.onCancel}
        />
      );
    }

    return <AuthForm error={this.state.error} onSubmit={this.onSubmit} />;
  }
}

export default withApi(AuthContainer);
