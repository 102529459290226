import React, { useState } from 'react'
import * as R from 'ramda'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import AddIcon from '@material-ui/icons/Add'
import Divider from '@material-ui/core/Divider'
import { Button } from '@material-ui/core'
import DialogContentText from '@material-ui/core/DialogContentText'
import { successNotification } from '../../api/lib/notifications'

const PageContainer = styled.section`
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 20px;
  width: 65%;
`

const ThreediumHeader = styled.div`
  padding-top: 40px;
  margin-top: 45px;
  margin-bottom: 36px;
  margin: 0 auto;
  text-align: center;
  .logo {
    width: 200px;
    height: 'auto';
  }
  .title {
    font-family: 'Work Sans', sans-serif;
    font-size: 25px;
    width: 400px;
    margin: 0 auto;
    span {
      color: #327d94;
    }
  }
`

const ThreediumRequestChange = (props) => {
  const { match, location } = props
  const { state: linkState } = location
  const { requestInfo } = linkState
  const { params } = match
  const { threediumRequestId } = params
  console.debug('ThreediumRequestChange.props', {
    threediumRequestId,
    requestInfo,
    props,
  })

  const projectName = R.pathOr(null, ['formParams', 'question1'], requestInfo)

  const [state, setState] = useState({ description: '' })

  const [inspirationListCount, setInspirationListCount] = useState(1)
  const [inspirationList, setInspirationList] = useState([])

  const uploadInspiration = (id) => {
    console.debug('uploadInspiration', { id })

    let formData = new FormData()

    const selector = document.querySelector(`#requestChangeFile_${id}`)

    const file = selector.files[0]
    formData.append('file', file)

    setInspirationList((s) => R.append(file, s))

    console.debug('uploadFile', { formData, file })

    successNotification(
      'Success',
      "File ready to send. Press 'Send Change Request' once completed to fill out the form"
    )
  }

  const handleSubmit = () => {
    // PRENDI I FILE E LA DESCRIZIONE E SPARA TUTTO AL BACKEND
  }

  return (
    <>
      <div
        style={{
          marginTop: 20,
          marginLeft: 30,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Link to="/home">
          <IconButton aria-label="Back to Home">
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <span
            style={{
              color: 'white',
              display: 'inline-block',
              marginLeft: 20,

              textTransform: 'uppercase',
              fontWeight: 500,
              fontFamily: "'Work Sans',sans-serif",
            }}
          >
            Back to Home
          </span>
        </Link>
      </div>
      <PageContainer>
        <ThreediumHeader>
          <img
            className="logo"
            src="https://threedium.co.uk/assets/website/images/footerLogo.png"
          />
          <h3 className="title">
            Change Request <span>{projectName}</span>
          </h3>
        </ThreediumHeader>

        <div
          className="upload-section"
          style={{ marginBottom: 40, margin: '0 auto' }}
        >
          <Card style={{ marginTop: 40, marginBottom: 40 }}>
            <CardContent>
              <DialogContentText>
                Send us information about the changes to be made. You can enter
                a description or attach new files. The clearer the explanation,
                the more coherent the result
              </DialogContentText>
              <TextField
                fullWidth
                defaultValue={state.description}
                required
                id="standard-required"
                label="Description"
                size="medium"
                multiline
                rows={6}
                onChange={(e) => {
                  const value = e?.target?.value
                  setState((s) => ({ ...s, description: value }))
                }}
              />
              <Typography
                variant="h5"
                component="h2"
                style={{ marginBottom: 30, marginTop: 30 }}
              >
                Upload Files
              </Typography>
              {R.times(
                (number) => (
                  <FileUpload
                    id={number + 1}
                    uploadInspiration={uploadInspiration}
                  />
                ),
                inspirationListCount
              )}
            </CardContent>
            <CardActions>
              <Button
                startIcon={<AddIcon />}
                onClick={() => setInspirationListCount((s) => s + 1)}
              >
                Add More
              </Button>
            </CardActions>
          </Card>

          {/* SUBMIT BUTTON */}
          <Button
            OnClick={handleSubmit}
            fullWidth
            style={{
              backgroundColor: '#327d94',
              color: 'white',
              marginBottom: 50,
              padding: '20px 8px',
            }}
            color="primary"
          >
            Send Change Request
          </Button>
        </div>
      </PageContainer>
    </>
  )
}

const FileUpload = ({ uploadInspiration, id }) => {
  return (
    <>
      <div
        className="container-file-uploads"
        style={{ textAlign: 'left', width: '100%' }}
      >
        <input
          name={`requestChangeFile_${id}`}
          id={`requestChangeFile_${id}`}
          type="file"
          onChange={() => uploadInspiration(id)}
        />
      </div>
      <Divider style={{ marginTop: 30, marginBottom: 30 }} />
    </>
  )
}

export default ThreediumRequestChange
