import { Link } from "react-router-dom";
import styled from "styled-components";

const PrimaryLink = styled(Link)`
  font-size: 16px;
  color: ${props => props.theme.blue};
  text-decoration: none;
  font-weight: 500;

  vertical-align: middle;

  &.--white {
    color: #fff;
  }
`;

export default PrimaryLink;
