import React, { Component } from 'react'
import TextCounter from '../TextCounter/TextCounter'

import { Textfit } from 'react-textfit'

class LandName extends Component {
  render() {
    return (
      <div className="LandName">
        {this.props.value && (
          <TextCounter value={this.props.value} label="OVR"></TextCounter>
        )}
        <div className="LandName__name">
          <h2>  
            <Textfit mode="single" max={14}>
            {this.props.name.sentence}
          </Textfit></h2>
         
        </div>
        <div className="LandName__location"> 
        <Textfit mode="single" max={14}>
          {this.props.location}
        </Textfit>
 </div>
      </div>
    )
  }
}

export default LandName
