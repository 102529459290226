import React, { Component } from "react";
import styled from "styled-components";

import PropTypes from "prop-types";
import PreviewDialog from "../ui/dialogs/PreviewDialog";
import StringInput from "../ui/inputs/StringInput";
import BooleanInput from "../ui/inputs/BooleanInput";
import FormField from "../ui/inputs/FormField";

import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export default class PublishDialog extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    screenshotUrl: PropTypes.string,
    contentAttributions: PropTypes.array,
    onPublish: PropTypes.func,
    published: PropTypes.bool,
    sceneUrl: PropTypes.string,
    initialSceneParams: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      creatorAttribution: "",
      nftPass: "",
      allowRemixing: false,
      allowPromotion: false,
      OVRTarget: "ovr_live",
      overrideImage: {
        url: null,
        file: null
      },
      overrideVideoImage: {
        url: null,
        file: null
      },
      ...props.initialSceneParams
    };
  }

  onChangeName = name => this.setState({ name });

  onChangeCreatorAttribution = creatorAttribution => this.setState({ creatorAttribution });

  onChangeNftPass = nftPass => this.setState({ nftPass });

  onChangeAllowRemixing = allowRemixing => this.setState({ allowRemixing });

  onChangeOVRTarget = OVRTarget => this.setState({ OVRTarget });

  onChangeAllowPromotion = allowPromotion => this.setState({ allowPromotion });

  onConfirm = () => {
    const publishState = { ...this.state, contentAttributions: this.props.contentAttributions };
    publishState.name = publishState.name.trim();
    publishState.creatorAttribution = publishState.creatorAttribution.trim();
    publishState.overrideImage = this.state.overrideImage;
    publishState.overrideVideoImage = this.state.overrideVideoImage;
    if (publishState.OVRTarget == null || publishState.OVRTarget == "") {
      alert("You must define a OVR Experience!");
    } else {
      this.props.onPublish(publishState);
    }
  };

  handleUploadOverrideImage = event => {
    const file = event.target.files[0];
    // only if the file is an image
      this.setState({ overrideImage: { url: URL.createObjectURL(file), file } });
  };

  handleUploadOverrideVideo = event => {
    const file = event.target.files[0];
    // only if the file is an image
    this.setState({ overrideVideoImage: { url: URL.createObjectURL(file), file } });
  }

  handleRemoveOverrideImage = () => {
    this.setState({ overrideImage: { url: null, file: null } });
  };

  render() {
    const { onCancel, screenshotUrl, contentAttributions } = this.props;
    const { creatorAttribution, name, nftPass, allowRemixing, allowPromotion, overrideImage } = this.state;

    const handleUploadOverrideImage = this.handleUploadOverrideImage;
    const handleRemoveOverrideImage = this.handleRemoveOverrideImage;
    const handleUploadOverrideVideo = this.handleUploadOverrideVideo;

    return (
      <PreviewDialog
        imageSrc={screenshotUrl}
        title={"Publish Scene"}
        onConfirm={this.onConfirm} //TODO this.onConfirm
        onCancel={onCancel}
        confirmLabel="Save and Publish"
        overrideImage={
          <>
            {overrideImage?.url ? (
              <div
                style={{
                  position: "relative",
                  overflow: "hidden",
                  borderRadius: 6
                }}
              >
                <IconButton onClick={handleRemoveOverrideImage} style={{ position: "absolute", right: 5, top: 5 }}>
                  <CloseIcon />
                </IconButton>
                <img
                  src={overrideImage?.url}
                  alt="preview"
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "block"
                  }}
                />
              </div>
            ) : (
              <img src={screenshotUrl} />
            )}
            <label htmlFor="contained-button-file" style={{ textAlign: "left", marginTop: "2px", display: "block", width: "100%" }}>
              Image preview
              <Input
                placeholder="Upload Custom Image"
                id="contained-button-file"
                type="file"
                name="input-file"
                onChange={e => handleUploadOverrideImage(e)}
              />

              {/* <Button>Upload Custom Image</Button> */}
            </label>

            <label htmlFor="contained-button-file" style={{ textAlign: "left", marginTop: "2px", display: "block", width: "100%" }}>
              Video preview
              <Input
                placeholder="Upload Custom Image"
                id="contained-button-file"
                type="file"
                name="input-file"
                onChange={e => handleUploadOverrideVideo(e)}
              />

              {/* <Button>Upload Custom Image</Button> */}
            </label>
          </>
        }
      >
        Now you will find this project in your Projects list.
        <FormField>
          <label htmlFor="sceneName">Scene Name</label>
          <StringInput
            id="sceneName"
            required
            pattern={"[A-Za-z0-9-':\"!@#$%^&*(),.?~ ]{4,64}"}
            title="Name must be between 4 and 64 characters and cannot contain underscores"
            value={name}
            onChange={this.onChangeName}
          />
        </FormField>
        <FormField>
          <label htmlFor="creatorAttribution">Your Attribution (optional):</label>
          <StringInput id="creatorAttribution" value={creatorAttribution} onChange={this.onChangeCreatorAttribution} />
        </FormField>
        <FormField inline>
          {/* <label htmlFor="allowRemixing">
            Allow{" "}
            <a
              href="https://github.com/mozilla/Spoke/blob/master/REMIXING.md"
              target="_blank"
              rel="noopener noreferrer"
            >
              Remixing
            </a>
            &nbsp;with
            <br />
            Creative Commons&nbsp;
            <a
              href="https://creativecommons.org/licenses/by/3.0/"
              target="_blank"
              rel="noopener noreferrer"
            >
              CC-BY 3.0
            </a>
          </label>
          <BooleanInput
            id="allowRemixing"
            value={allowRemixing}
            onChange={this.onChangeAllowRemixing}
          /> */}
        </FormField>
        <FormField>
          <label htmlFor="nftPass">
            This experience requires a NFT pass to enter. NFT Contract Address (optional):
          </label>
          <StringInput id="nftPass" value={nftPass} onChange={this.onChangeNftPass} />
        </FormField>
        <FormField inline>
          <label htmlFor="allowPromotion">
            Allow OVR to
            <a
              href="https://github.com/OVR-Platform/docs/blob/master/PROMOTION.md"
              target="_blank"
              rel="noopener noreferrer"
              className="promote-link"
            >
              promote
            </a>
            my scene
          </label>
          <BooleanInput id="allowPromotion" value={allowPromotion} onChange={this.onChangeAllowPromotion} />
        </FormField>
        {/* <FormField inline>
          <label htmlFor="experienceSelection">
            Select the OVR Experience you want to publish
          </label>
          <SimpleSelect
            defaultValue={{
              label: "Remote Experience",
              value: "ovr_remote",
            }}
            options={[
              {
                label: "Remote Experience",
                value: "ovr_remote",
              },
              {
                label: "GEO Experience",
                value: "ovr_geo",
              },
            ]}
            onValueChange={(value) => {
              this.onChangeOVRTarget(value);
            }}
          />
        </FormField> */}
        {/* {contentAttributions && (
          <FormField>
            <label>Model Attribution:</label>
            <p>
              {contentAttributions.map((a) => `${a.name} by ${a.author}\n`)}
            </p>
          </FormField>
        )}  */}
      </PreviewDialog>
    );
  }
}

const Input = styled("input")({
  // display: "none"
});
