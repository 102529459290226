import React from 'react'
import LandName from '../LandName/LandName'
import { Link } from 'react-router-dom'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const LandCard = (props) => {
  const {
    background_image,
    name,
    location,
    hexId,
    intId,
    landUuid,
    className,
    customOnClick,
    landLatitude
  } = props
  const { sentence, hex } = name

  const handleClick = () => {
    if (customOnClick != undefined) {
      customOnClick(hexId, landUuid, intId, landLatitude)
    }
  }

  return (
    <div className={`LandCard ${className}`} onClick={() => handleClick()}>
      <div className="selected-overlay">
        <CheckCircleIcon></CheckCircleIcon>
      </div>
      <div>
        <div
          className="LandCard__header"
          style={{ backgroundImage: background_image }}
        >
          <div className="LandCard__ping_container">
            <div className="c-ping-layer c-ping-layer-1"> </div>
          </div>
        </div>
      </div>
      <div className="LandCard__body">
        <h2 className="--nft-id">NFT ID: {intId}</h2>

        <LandName
          name={{ sentence: sentence, hex: hex }}
          location={location}
        ></LandName>
      </div>
    </div>
  )
}

export default LandCard
