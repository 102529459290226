import React, { useState, useContext } from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

export const LandFolder = ({
  folder,
  customOnClick,
  className
}) => {
  const { name, landsCount, uuid } = folder

  const handleClick = () => {
    if (customOnClick != undefined) {
      customOnClick(uuid)
    }
  }

  return (
    <>
      <div className={`folder ${className}`} onClick={() => handleClick()}>
        <div className="selected-overlay">
          <CheckCircleIcon></CheckCircleIcon>
        </div>
        <div className="content">
          <p className="folder-name">
            Folder<b>( {landsCount} ) </b>
            {/* {forSale && (
              <div
                className="land-status-badge  --on-sale inline"
                style={{ marginLeft: 15 }}
              >
                FOR SALE
              </div>
            )} */}
            <span>{name}</span>
          </p>

          <div className="actions">

          </div>
        </div>
      </div>
    </>
  )
}


export default LandFolder
