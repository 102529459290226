import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import ScrollToTop from "../router/ScrollToTop";
import NavBar from "../navigation/NavBar";
import {
  ProjectGrid,
  ProjectGridContainer,
  ProjectGridHeader,
  // ProjectGridHeaderRow,
  // Filter,
  // Separator,
  // SearchInput,
  ProjectGridContent,
  ErrorMessage
} from "./ProjectGrid";
import Footer from "../navigation/Footer";
import PrimaryLink from "../inputs/PrimaryLink";
// import { Button } from "../inputs/Button";
import { ProjectsSection, ProjectsContainer, ProjectsHeader } from "./HomePage";
// import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
// import usePaginatedSearch from "./usePaginatedSearch";
import templates from "./templates";

export default function CreateProjectPage({ history, location }) {
  const queryParams = new URLSearchParams(location.search);

  const [params, setParams] = useState({
    source: "scene_listings",
    filter: queryParams.get("filter") || "featured-remixable",
    q: queryParams.get("q") || ""
  });

  const updateParams = useCallback(
    nextParams => {
      const search = new URLSearchParams();

      for (const name in nextParams) {
        if (name === "source" || !nextParams[name]) {
          continue;
        }

        search.set(name, nextParams[name]);
      }

      history.push(`/projects/create?${search}`);

      setParams(nextParams);
    },
    [history]
  );

  const onChangeQuery = useCallback(
    value => {
      updateParams({
        source: "scene_listings",
        filter: "remixable",
        q: value
      });
    },
    [updateParams]
  );

  const onSetFeaturedRemixable = useCallback(() => {
    updateParams({
      ...params,
      filter: "featured-remixable",
      q: ""
    });
  }, [updateParams, params]);

  const onSetAll = useCallback(() => {
    updateParams({
      ...params,
      filter: "remixable",
      q: ""
    });
  }, [updateParams, params]);

  const onSelectScene = useCallback(
    scene => {
      const search = new URLSearchParams();
      search.set("sceneId", scene.id);
      history.push(`/projects/new?${search}`);
    },
    [history]
  );

  // const { loading, error, entries, hasMore, loadMore } = usePaginatedSearch(
  //   `${api.apiURL}/api/v1/media/search`,
  //   params
  // );

  const loading = false;
  const error = null;
  const hasMore = false;

  const loadMore = useCallback(() => {
    console.log("Nothing to see here");
  });

  // const filteredEntries = entries.map(result => ({
  //   ...result,
  //   url: `/projects/new?sceneId=${result.id}`,
  //   thumbnail_url: result && result.images && result.images.preview && result.images.preview.url
  // }));

  const filteredEntries = templates.map(result => ({
    ...result,
    url: `/projects/new?templateId=${result.project_id}`,
    project_thumbnail: result && result.project_thumbnail,
    project_name: result && result.name,
    project_url: result && result.project_url
  }));

  return (
    <>
      <NavBar />
      <main>
        <ProjectsSection>
          <ProjectsContainer>
            <ProjectsHeader>
              <h1>New Project</h1>
              <PrimaryLink className="--white" to="/projects">
                Back to projects
              </PrimaryLink>
            </ProjectsHeader>
            <ProjectGridContainer>
              <ProjectGridHeader>
                {/* <ProjectGridHeaderRow>
                  <Filter onClick={onSetFeaturedRemixable} active={params.filter === "featured-remixable"}>
                    Featured
                  </Filter>
                  <Filter onClick={onSetAll} active={params.filter === "remixable"}>
                    All
                  </Filter>
                  <Separator />
                  <SearchInput placeholder="Search scenes..." value={params.q} onChange={onChangeQuery} />
                </ProjectGridHeaderRow>
                <ProjectGridHeaderRow>
                  <Button as={Link} to="/scenes/new">
                    Import From Blender
                  </Button>
                  <Button as={Link} to="/projects/new">
                    New Empty Project
                  </Button>
                </ProjectGridHeaderRow> */}
              </ProjectGridHeader>
              <ProjectGridContent>
                <ScrollToTop />
                {error && <ErrorMessage>{error.message}</ErrorMessage>}
                {!error && (
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    threshold={100}
                    useWindow={true}
                  >
                    <ProjectGrid
                      projects={filteredEntries}
                      newProjectPath="/projects/new"
                      newProjectLabel="New Empty Project"
                      onSelectProject={onSelectScene}
                      loading={loading}
                    />
                  </InfiniteScroll>
                )}
              </ProjectGridContent>
            </ProjectGridContainer>
          </ProjectsContainer>
        </ProjectsSection>
      </main>
      <Footer />
    </>
  );
}

CreateProjectPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};
